import { Typography } from "antd";
import { Fragment } from "react";
import { isArrayAndHasValue } from "utils/functions";

const PlanVsActualTable = (props) => {
  // Props
  const { summaryData } = props;
  // Antd
  const { Title } = Typography;

  const formatNumber = (data) => {
    let number = parseFloat(data);
    if (!isNaN(number)) {
      return number.toFixed(2);
    } else {
      return "";
    }
  };

  return (
    <>
      <Title level={5}>Plan VS Actual</Title>
      <div className="custome-table">
        <table border="1" style={{ marginBottom: "30px" }}>
          <thead>
            <tr>
              <th key="header_blank" colSpan={3}>
                {" "}
              </th>
              <th
                key="header_production"
                colSpan={3}
                style={{ backgroundColor: "#385724" }}
              >
                <span style={{ color: "#FFFFFF" }}>Production (pc)</span>
              </th>
              <th
                key="header_efficiency"
                colSpan={3}
                style={{ backgroundColor: "#843C17" }}
              >
                <span style={{ color: "#FFFFFF" }}>Efficiency</span>
              </th>
              <th key="header_reject"> </th>
            </tr>
            <tr>
              <th key="header_buyer" style={{ backgroundColor: "#D6DCE5" }}>
                Buyer
              </th>
              <th key="header_style" style={{ backgroundColor: "#D6DCE5" }}>
                Style
              </th>
              <th key="header_style" style={{ backgroundColor: "#D6DCE5" }}>
                Sub Style
              </th>
              <th key="header_sam" style={{ backgroundColor: "#D6DCE5" }}>
                SAM
              </th>
              <th key="header_plan" style={{ backgroundColor: "#E2F0D9" }}>
                Plan
              </th>
              <th key="header_actual" style={{ backgroundColor: "#C5E0B4" }}>
                Actual
              </th>
              <th key="header_achiev.%" style={{ backgroundColor: "#A9D18E" }}>
                Achiev.%
              </th>
              <th key="header_plan_eff" style={{ backgroundColor: "#FBE5D7" }}>
                Plan
              </th>
              <th
                key="header_actual_eff"
                style={{ backgroundColor: "#F8CBAD" }}
              >
                Actual
              </th>
              <th
                key="header_achiev.%_eff"
                style={{ backgroundColor: "#F4B183" }}
              >
                Achiev.%
              </th>
              <th key="header_reject" style={{ backgroundColor: "ED7D31" }}>
                Reject
              </th>
            </tr>
          </thead>
          <tbody>
            {isArrayAndHasValue(summaryData?.plan_vs_actual) &&
              summaryData?.plan_vs_actual?.map((item, index) => {
                return (
                  <Fragment>
                    <tr key={index}>
                      <td className="t-body">{item?.buyer?.name}</td>
                      <td className="t-body">{item?.style?.style_no}</td>
                      <td className="t-body">
                        {item?.sub_style?.color_info?.name}{" "}
                        {item?.sub_style?.sub_style_no
                          ? `(${item?.sub_style?.sub_style_no})`
                          : ""}
                      </td>
                      <td className="t-body">{item?.sam}</td>
                      <td className="t-body">
                        {formatNumber(item?.production_plan)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.production_actual)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.production_achive)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.effeciency_plan)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.effeciency_actual)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.effeciency_achive)}
                      </td>
                      <td className="t-body">
                        {formatNumber(item?.rejected_production)}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PlanVsActualTable;
