import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { DIGITALIZATION_DEFECTS } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  DIGITALIZATION_DEFECT_VIEW_PERMISSION,
  FABRIC_REPORT_GENERATE_PERMISSION,
  NO_PERMISSION_REQUIRED
} from "../../../routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const ProductionDefect = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDefects, setTotalDefects] = useState(0);
  // Antd
  const { Title } = Typography;

  console.log('showFilter', showFilter);
  

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllDefectsList = useCallback(async () => {
    setLoading(true);
    const query = `${DIGITALIZATION_DEFECTS}?page=${currentPage}`;

    const response = await getData(query);
    if (response?.status === 200) {
      setLoading(false);
      setMetaData({
        defaultPageSize: response?.data?.data?.per_page,
        current: response?.data?.data?.current_page,
        defaultCurrent: response?.data?.data?.current_page,
        total: response?.data?.data?.total,
      });
      console.log('tabledata res', response?.data?.data?.data);
      
      setTableData(response?.data?.data?.data || []);
      setTotalDefects(response?.data?.data?.total || 0);
    } else {
      setLoading(false);
      message.error("Something went wrong! Please try again.");
    }
  }, [currentPage]);

  const exportProductionHour = async (filterValues) => {
    setLoading(true);
    const query = `${DIGITALIZATION_DEFECTS}`;

    const response = await getData(query, false, filterValues);
    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data?.data || []);
      setTotalDefects(response?.data?.data?.total || 0);
    } else {
      setLoading(false);
      message.error("Something went wrong! Please try again.");
    }
  };

  // Effects
  useEffect(() => {
    getAllDefectsList();
  }, [refresh, getAllDefectsList]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };
  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/digitalization/production-defect/view/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([DIGITALIZATION_DEFECT_VIEW_PERMISSION]),
        },
      ]}
    />
  );

  const tableColumns = [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "sl",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Entry Date",
      dataIndex: ["date"],
      key: "date",
      width: 150,
      render: (value, record) => {
        return (
          <span>{value ? moment(value).format("YYYY-MM-DD") : "N/A"}</span>
        );
      },
    },
    {
      title: "Entry Time",
      dataIndex: ["time"],
      key: "time",
      render: (value, record) => {
        return (
          <span>{value ? moment(value, "HH:mm").format("HH:mm") : "N/A"}</span>
        );
      },
    },
    {
      title: "Supervisor Name",
      dataIndex: ["user", "name"],
      key: "name",
      width: 150,
    },
    {
      title: "Line",
      dataIndex: ["line_info", "name"],
      key: "line_id_name",
      width: 150,
    },
    {
      title: "Operation",
      dataIndex: ["operation", "operation_info", "sam_data_bank", "name"],
      key: "line_id_name",
      width: 150,
    },
    {
      title: "Operator",
      dataIndex: ["operator", "name"],
      key: "operator_name",
      width: 150,
    },
    {
      title: "Category",
      dataIndex: ["category", "name"],
      key: "category",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
    },
    {
      title: "Reason",
      dataIndex: ["details"],
      key: "quantity",
      width: 150,
      render: (value, record) => {
        return (
          <div>
            {Array.isArray(record?.details) && record?.details?.length > 0
              ? record?.details?.map((item) => item?.reason?.reason).join(", ")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Recommendation",
      dataIndex: ["recommendation"],
      key: "quantity",
      width: 150,
      render: (value, record) => {
        return (
          <div>
            {Array.isArray(record?.recommendations) && record?.recommendations?.length > 0
              ? record?.recommendations?.map((item) => item?.recommendation?.recommendation).join(", ")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportProductionHour}
                hideAddButton={true}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            }
            title={<Title level={5}>Production Defect</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportProductionHour}
                filterName={"production_defect"}
                // searchButtonText={"Search"}
                // defaultOpen
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
              <div style={{ paddingTop: 10, marginBottom: 10, marginInlineStart: 5 }}>
                <Title level={5} style={{ fontWeight: "normal" }}>Total Defect: {totalDefects}</Title>
              </div>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ProductionDefect;
