import { Col, Form, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  SEWING_LINES,
  SUPERVISOR_LIST,
  DEFECT_CATEGORY_LISTS
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const ProductionDefectFilterField = ({ initialTableData }) => {
  // States
  const [sewingList, setSewingList] = useState([]);
  const [supervisorUserList, setSupervisorUserList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  console.log('sewingList', sewingList);
  console.log('supervisorUserList', supervisorUserList);
  console.log('categoryList', categoryList);
  
  
  

  // Antd
  const { Option } = Select;

  // Effects
  useEffect(() => {
    getSewingList();
    getSupervisorUserList();
    getDefectCategoryList();
  }, []);

  const getSewingList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SEWING_LINES, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingList(response?.data?.data?.data || []);
    }
  };

  const getSupervisorUserList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SUPERVISOR_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSupervisorUserList(response?.data?.data?.data || []);
    }
  };

  const getDefectCategoryList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 100,
      ...filterValues,
    };
    const response = await getData(DEFECT_CATEGORY_LISTS, false, bodyData);

    if (response && response?.data?.code === 200) {
      setCategoryList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={3}>
        <Form.Item label="Date" name="date">
          <DatePicker placeholder="Date" size="small" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      
      <Col span={3}>
        <Form.Item label="Line" name="line_id">
          <Select
            showSearch
            placeholder="Select a Line"
            onSearch={(value) => {
              getSewingList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {isArrayAndHasValue(sewingList) &&
              sewingList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item label="Operation" name="operation">
          <Input placeholder="Write Operation" size="small" />
        </Form.Item>
      </Col>

      <Col span={3}>
        <Form.Item label="Category" name="category_id">
          <Select
            showSearch
            placeholder="Select a Category"
            onSearch={(value) => {
              getDefectCategoryList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {categoryList?.length &&
              categoryList.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.name_en}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Supervisor User" name="user_id">
          <Select
            showSearch
            placeholder="Select a Supervisor User"
            onSearch={(value) => {
              getSupervisorUserList({ name: value });
            }}
            optionFilterProp="children"
            size="small"
          >
            {supervisorUserList?.length &&
              supervisorUserList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Per Page" name="per_page">
          <Select
            showSearch
            placeholder="Select an Option"
            optionFilterProp="children"
            size="small"
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
            <Option value={500}>500</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ProductionDefectFilterField;
