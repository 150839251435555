import { Bar } from "@ant-design/plots";
import { Alert, Card, Empty, Typography } from "antd";
import { postData } from "apiServices/common";
import { useEffect, useState } from "react";

const ResponsibleForDefects = ({ line_id = 14 }) => {
  const [responsibleForDefectsData, setResponsibleForDefectsData] = useState(
    {},
  );

  const initialGetData = async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/responsible-defects",
      {
        line_id: line_id,
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setResponsibleForDefectsData(data);
    }
  };

  useEffect(() => {
    initialGetData();
  }, []);

  const config = {
    data: responsibleForDefectsData?.chart_data,
    xField: "name",
    yField: "value",
    label: {
      text: "name",
      style: {
        textAlign: (d) => (+d.frequency > 0.008 ? "right" : "start"),
        fill: (d) => (+d.frequency > 0.008 ? "#fff" : "#000"),
        dx: (d) => (+d.frequency > 0.008 ? -5 : 5),
      },
    },
    xAxis: {
      tickInterval: 10,
      label: {
        formatter: (val) => {
          const num = Number(val);
          if (num % 10 === 0) {
            return num;
          }
          return "";
        },
      },
    },
  };
  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      {responsibleForDefectsData?.chart_data &&
      responsibleForDefectsData?.chart_data?.length > 0 ? (
        <Card
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
        >
          <Typography.Title level={3} style={{ textAlign: "center" }}>
            Responsible For Defects
          </Typography.Title>
          <Bar {...config} />
        </Card>
      ) : (
        <Empty
          style={{ width: "33%", margin: "auto", marginTop: "10%" }}
          description={
            <Alert
              message="No Data Found with selected line-id in Responsible For Defects"
              type="warning"
            />
          }
        />
      )}
    </div>
  );
};

export default ResponsibleForDefects;
