import { Col, DatePicker, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GET_INVENTORY_DETAILS } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const InventoryDetailFilterFields = ({ initialTableData }) => {
  // States
  const [suppliersList, setSuppliersList] = useState(null);

  // Antd
  const { Option } = Select;

  const getAllInventoryDetail = useCallback(async () => {
    const query = `${GET_INVENTORY_DETAILS}`;

    const response = await getData(query);

    if (response?.status === 200) {
      setSuppliersList(response?.data?.data?.suppliers);
    }
  }, []);

  useEffect(() => {
    getAllInventoryDetail();
  }, []);

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Start Date"
          name="start_date"
          required
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label="End Date"
          name="end_date"
          required
          rules={[
            {
              required: true,
              message: "End Date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} size="small" />
        </Form.Item>
      </Col>
      {/* <Col span={4}>
        <Form.Item label="Suppliers" name="supplier_id">
          <Select
            showSearch
            placeholder="Select an supplier"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children[0].toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {isArrayAndHasValue(suppliersList) &&
              suppliersList.map((supplier) => (
                <Option value={supplier.id} key={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col> */}
    </>
  );
};

export default InventoryDetailFilterFields;
