import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Drawer,
  Dropdown,
  Form,
  Menu,
  Modal,
  message,
} from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  GET_ALL_TNA_TASK_DATA,
  GET_ALL_UNITS,
  GET_SUPPLIER_ENDPOINT,
  GET_USERS_ENDPOINT,
} from "../../../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../../../apiServices/common";
import HandleNotification from "../../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../../utils/functions";
import BulkItemsManage from "../../BulkItemsManage";
import ItemEdit from "../ItemEdit";

const ItemDetails = (props) => {
  // Props
  const { view, itemDetailsList, setItemDetailsList, TNAStyles, TNAPos } =
    props;

  // States
  const [itemTypeList, setItemTypeList] = useState([]);
  const [TNATaskList, setTNATaskList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerType, setDrawerType] = useState(null);
  const [activeBulkKey, setActiveBulkKey] = useState(null);
  const [foundItems, setFoundItems] = useState(null);
  const [userList, setUserList] = useState(null);

  // Antd
  const { confirm } = Modal;
  const [searchForm] = Form.useForm();
  const { Panel } = Collapse;

  // Others
  const searchRef = useRef();
  const tableBottomRef = useRef();

  const getTNATaskList = async (value) => {
    let payload = {
      name: value,
      per_page: 10,
    };
    const response = await getData(GET_ALL_TNA_TASK_DATA, undefined, payload);

    if (response) {
      setTNATaskList(response?.data?.data?.data);
    }
  };

  const getItemList = async (value) => {
    const foundItemCategory = itemTypeList.find(
      (category) => category.id === Number(value),
    );

    if (foundItemCategory) {
      setItemList(foundItemCategory?.items);
    }
  };

  const getItemInfo = async (value, index) => {
    let res = await getData(`/api/items/${value}/edit/api`);
    let attributeInfos = res?.data?.data?.attribute_value_info;
    let imagePath = res?.data?.data?.image_path;

    onChangeHandel(attributeInfos, index, "attribute_list");
    onChangeHandel(imagePath, index, "image_path");
  };

  const getUnitList = async () => {
    const bodyData = {
      per_page: 10000,
    };

    let res = await getData(GET_ALL_UNITS, false, bodyData);

    if (res) {
      setUnitList(res?.data?.data?.data);
    }
  };

  const getSupplierList = async () => {
    const bodyData = {
      per_page: 10000,
    };

    let res = await getData(GET_SUPPLIER_ENDPOINT, false, bodyData);

    if (res) {
      setSupplierList(res?.data?.data?.data);
    }
  };

  const onChangeHandel = (val, index, context) => {
    const itemDetailsListCopy = [...itemDetailsList];
    itemDetailsListCopy[index][context] = val;
    setItemDetailsList([...itemDetailsListCopy]);
  };

  const getUserList = async (value) => {
    let payload = {
      name: value,
      per_page: 1000,
    };
    const response = await getData(GET_USERS_ENDPOINT, undefined, payload);

    if (response) {
      setUserList(response?.data?.data?.data);
    }
  };

  // Effects
  useEffect(() => {
    getTNATaskList();
    getUnitList();
    getSupplierList();
    getUserList();
  }, []);

  useEffect(() => {
    if (!drawerType) {
      // Uncheck all the items if checked before
      const itemDetailsListCopy = structuredClone(itemDetailsList);
      const uncheckedList = itemDetailsListCopy?.map((item) => {
        return {
          ...item,
          is_checked: false,
        };
      });
      setItemDetailsList(uncheckedList);
    }
    // This executes only when drawerType changes
    // All other dependency warning should be ignored
  }, [drawerType]);

  const onEditOpen = (index) => {
    if (view) return;

    const itemSelected = itemDetailsList[index] || null;

    // Set selected item with index
    const withIndex = {
      ...itemSelected,
      index: index,
    };
    setDrawerType(null);
    setSelectedItem(withIndex);
    setIsDrawerOpen(true);
  };

  const onAddOpen = () => {
    setDrawerType(null);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedItem(null);
    setIsDrawerOpen(false);
    setActiveBulkKey(null);
    setDrawerType(null);

    // Reset found items, it will be calculated again
    setFoundItems(null);
  };

  const onBulkAddOpen = () => {
    setDrawerType("bulk_add");
    setActiveBulkKey(1);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const onBulkEditOpen = () => {
    setDrawerType("bulk_edit");
    setActiveBulkKey(null);
    setSelectedItem(null);
    setIsDrawerOpen(true);
  };

  const onAddNewItem = (data) => {
    console.log("data onadd", data);
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    itemDetailsListCopy.push({
      ...data,
      is_add: true,
    });
    setItemDetailsList([...itemDetailsListCopy]);
    scrollToBottom();
    HandleNotification("success", "bottomRight", "Item Added", null);
  };

  // console.log(selectedItem);

  const onUpdateItem = (index, updateData) => {
    console.log({ index, updateData });
    const itemDetailsListCopy = structuredClone(itemDetailsList);

    // Change the values of selected item
    itemDetailsListCopy[index]["days"] = updateData?.days;
    itemDetailsListCopy[index]["po_ids"] = updateData?.po_ids;
    itemDetailsListCopy[index]["po_names"] = updateData?.po_names;
    itemDetailsListCopy[index]["is_pcd"] = updateData?.is_pcd;
    itemDetailsListCopy[index]["is_ship"] = updateData?.is_ship;
    itemDetailsListCopy[index]["po_wise"] = updateData?.po_wise;
    itemDetailsListCopy[index]["responsible_person_id"] =
      updateData?.responsible_person_id;
    itemDetailsListCopy[index]["responsible_person_name"] =
      updateData?.responsible_person_name;
    itemDetailsListCopy[index]["style_wise"] = updateData?.style_wise;
    itemDetailsListCopy[index]["task_dependencies"] =
      updateData?.task_dependencies;
    itemDetailsListCopy[index]["task_dependencies_names"] =
      updateData?.task_dependencies_names;
    itemDetailsListCopy[index]["tna_department_name"] =
      updateData?.tna_department_name;
    itemDetailsListCopy[index]["tna_process_id"] = updateData?.tna_process_id;
    itemDetailsListCopy[index]["tna_process_title"] =
      updateData?.tna_process_title;
    itemDetailsListCopy[index]["tna_task_id"] = updateData?.tna_task_id;
    itemDetailsListCopy[index]["tna_task_name"] = updateData?.tna_task_name;
    itemDetailsListCopy[index]["style_ids"] = updateData?.style_ids;
    itemDetailsListCopy[index]["style_names"] = updateData?.style_names;
    console.log({ index, updateData: itemDetailsListCopy[index] });
    // Update the state with new data
    setItemDetailsList([...itemDetailsListCopy]);
    closeDrawer();

    HandleNotification("success", "bottomRight", "Item Updated", null);
  };

  const onCopyItem = (product, index) => {
    // Find and set item list dropdown data
    const foundCategory = itemTypeList.find(
      (category) => category.id === Number(product?.item_category_id),
    );
    setItemList(foundCategory?.items);

    // Push new item to item details list
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    itemDetailsListCopy.push({
      ...product,
      is_add: true,
    });
    setItemDetailsList([...itemDetailsListCopy]);

    // Add the item to selected item
    setSelectedItem({
      ...product,
      index: itemDetailsListCopy.length - 1,
      is_add: true,
    });
    setIsDrawerOpen(true);

    message.success(`Item #${index} Copied Successfully!`);
  };

  const scrollToBottom = () => {
    tableBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeSelectedProduct = (id, index) => {
    confirm({
      title: "Are you sure delete this item?",
      onOk() {
        const itemDetailsListCopy = structuredClone(itemDetailsList);
        itemDetailsListCopy.splice(index, 1);
        setItemDetailsList([...itemDetailsListCopy]);
        HandleNotification("success", "bottomRight", "Task Deleted", null);
      },
    });
  };

  const menu = (product, index) => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => {
                  onEditOpen(index);
                }}
              >
                <EditOutlined />
                <span className="ml-2">Edit</span>
              </span>
            ),
            key: "0",
            // disabled: !hasPermission([BOM_EDIT_PERMISSION]),
          },
          {
            label: (
              <span onClick={() => onCopyItem(product, index)}>
                <CopyOutlined />
                <span className="ml-2">Copy</span>
              </span>
            ),
            key: "1",
            // disabled: !hasPermission([BOM_VIEW_PERMISSION]),
          },
          {
            label: (
              <span onClick={onAddOpen}>
                <PlusSquareOutlined />
                <span className="ml-2">Add</span>
              </span>
            ),
            key: "2",
            // disabled: !hasPermission([BOM_VIEW_PERMISSION]),
          },
          {
            label: (
              <span
                onClick={() => {
                  removeSelectedProduct(product.id, index);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
                <span className="ml-2" style={{ color: "red" }}>
                  Delete
                </span>
              </span>
            ),
            key: "3",
            // disabled: !hasPermission([BOM_EXPORT_PERMISSION]),
          },
        ]}
      />
    );
  };

  const onSearchItem = (values) => {
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    const formCategoryValue = searchForm.getFieldsValue().category;
    const formItemValue = searchForm.getFieldsValue().item;

    const filteredList = itemDetailsListCopy.filter((item) => {
      const category_name = item?.item_category_info?.name?.toLowerCase();
      const item_name = item?.item_info?.name?.toLowerCase();

      if (
        category_name.includes(formCategoryValue?.toLowerCase()) ||
        item_name.includes(formItemValue?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });

    isArrayAndHasValue(filteredList) && setItemDetailsList(filteredList);
  };

  const onSearchReset = () => {
    searchForm.resetFields();
    window.location.reload();
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      onSearchItem();
    }
  };

  const renderDrawerTitle = () => {
    switch (drawerType) {
      case "bulk_add":
        return `Bulk Add`;
      case "bulk_edit":
        return `Bulk Edit`;
      default:
        return selectedItem
          ? `Task #${selectedItem?.index + 1} Edit`
          : `Task Add`;
    }
  };

  const addBulkItems = () => {
    const customFoundItems = foundItems?.map((item) => ({ ...item, id: null }));
    const totalData = [...itemDetailsList, ...customFoundItems];
    setItemDetailsList(totalData);
    closeDrawer();
  };

  const editBulkItems = () => {
    const itemDetailsListCopy = structuredClone(itemDetailsList);
    foundItems?.forEach((item) => {
      const foundItemIndex = item?.bulk_edit_id;
      // Replace updated data to old data
      itemDetailsListCopy[foundItemIndex] = item;
      // Delete bulk edit id
      delete itemDetailsListCopy[foundItemIndex]["bulk_edit_id"];
    });
    setItemDetailsList(itemDetailsListCopy);
    closeDrawer();
  };

  const checkAll = (event) => {
    if (event.target.checked) {
      const itemsChecked = itemDetailsList?.map((item) => ({
        ...item,
        is_checked: true,
      }));
      setItemDetailsList(itemsChecked);
      setFoundItems(itemsChecked);
    } else {
      const itemsUnChecked = itemDetailsList?.map((item) => ({
        ...item,
        is_checked: false,
      }));
      setItemDetailsList(itemsUnChecked);
      setFoundItems([]);
    }
  };

  const checkSingle = (event, index, itemId) => {
    const foundItemsCopy = isArrayAndHasValue(foundItems)
      ? [...foundItems]
      : [];
    const itemDetailsListCopy = isArrayAndHasValue(itemDetailsList)
      ? [...itemDetailsList]
      : [];

    // Check/Uncheck item status
    const foundItem = itemDetailsListCopy[index];
    foundItem["is_checked"] = foundItem?.is_checked ? false : true;
    setItemDetailsList(itemDetailsListCopy);

    if (event.target.checked) {
      // Add bulk edit id to match later
      foundItem["bulk_edit_id"] = index;
      // Push item to found items
      foundItemsCopy.push(foundItem);
      setFoundItems(foundItemsCopy);
    } else {
      const foundItemIndex = foundItemsCopy?.findIndex(
        (item) => item?.id === itemId,
      );
      // Remove selected item from item list
      (foundItemIndex || foundItemIndex === 0) &&
        foundItemsCopy.splice(foundItemIndex, 1);
      setFoundItems(foundItemsCopy);
    }
  };

  return (
    <Card
      className="custome-table scrollable-table-container"
      title={
        <span
          style={{
            fontSize: "14px",
            display: isArrayAndHasValue(itemDetailsList) ? "flex" : "none",
          }}
        >
          Task List
        </span>
      }
    >
      {/* <Collapse style={{ marginBottom: 10 }}>
        <Panel header={<b>Filter</b>} key="1">
          <Form form={searchForm} ref={searchRef} onKeyUp={handleKeyUp}>
            <Row gutter={6} style={{ marginBottom: "16px" }}>
              <Col span={4}>
                <Form.Item name="category" label="Category">
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="item" label="Item">
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <Button
                    onClick={onSearchItem}
                    type="primary"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <Button
                    onClick={onSearchReset}
                    size="small"
                    style={{ width: "100%" }}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse> */}

      <div style={{ display: "flex", marginBottom: 30 }}>
        <div className="custome-table">
          <table>
            {isArrayAndHasValue(itemDetailsList) && (
              <thead>
                <tr>
                  <th className="t-header bc-java ">
                    <Checkbox
                      onChange={checkAll}
                      checked={itemDetailsList?.every(
                        (item) => item?.is_checked,
                      )}
                    />
                  </th>
                  <th className="t-header bc-java ">SL</th>
                  <th className="t-header bc-java ">Task</th>
                  <th className="t-header bc-java ">Process</th>
                  <th className="t-header bc-java ">Department</th>
                  <th className="t-header bc-java ">Style Wise</th>
                  <th className="t-header bc-java ">PO Wise</th>
                  <th className="t-header bc-java ">Style(s)</th>
                  <th className="t-header bc-java ">PO(s)</th>
                  <th className="t-header bc-java ">Days</th>
                  <th className="t-header bc-java ">Due Date</th>
                  <th className="t-header bc-java ">Responsible</th>
                  <th className="t-header bc-java ">Dependency</th>
                  {!view && (
                    <>
                      <th className="t-header bc-java ">Action</th>
                    </>
                  )}
                </tr>
              </thead>
            )}
            {console.log({ itemDetailsList })}
            <tbody>
              {isArrayAndHasValue(itemDetailsList) ? (
                <>
                  {itemDetailsList.map((product, index) => {
                    return (
                      <Fragment>
                        <tr
                          key={index || product?.id || product?.item_id}
                          onDoubleClick={() => onEditOpen(index)}
                          className={product?.is_add ? "is-added-item" : ""}
                        >
                          {/* Checkbox */}
                          <td className="t-body">
                            <Checkbox
                              onChange={(event) =>
                                checkSingle(event, index, product?.id)
                              }
                              checked={product?.is_checked}
                            />
                          </td>
                          {/* SL */}
                          <td className="t-body">{index + 1}</td>
                          {/* Task */}
                          <td className="t-body">{product?.tna_task_name}</td>
                          {/* PROCESS */}
                          <td className="t-body">
                            {product?.tna_process_title}
                          </td>
                          {/* DEPARTMENT */}
                          <td className="t-body">
                            {product?.tna_department_name}
                          </td>
                          {/* STYLE WISE */}
                          <td className="t-body">{product?.style_wise}</td>
                          {/* PO WISE */}
                          <td className="t-body">{product?.po_wise}</td>
                          {/* STYLES */}
                          <td className="t-body">
                            {isArrayAndHasValue(product?.style_names)
                              ? `${product?.style_names
                                  ?.slice(0, 2)
                                  ?.join(", ")}${
                                  product?.style_names?.length > 1 ? "..." : ""
                                }`
                              : "N/A"}
                          </td>
                          {/* POS */}
                          <td className="t-body">
                            {/* {isArrayAndHasValue(product?.po_names)
                              ? product?.po_names?.join(", ")
                              : "N/A"} */}
                            {isArrayAndHasValue(product?.po_ids)
                              ? `${product?.po_ids

                                  ?.map((item) => {
                                    const foundTask = TNAPos?.find(
                                      (itm) => itm?.id === item,
                                    );
                                    return foundTask?.original_po;
                                  })
                                  ?.slice(0, 2)
                                  ?.join(", ")}${
                                  product?.po_ids?.length > 1 ? "..." : ""
                                }`
                              : "N/A"}
                          </td>
                          {/* DAYS */}
                          <td className="t-body">{product?.days}</td>
                          {/* DUE DATE */}
                          <td className="t-body">
                            {product?.due_date || "N/A"}
                          </td>
                          {/* RESPONSIBLE */}
                          <td className="t-body">
                            {product?.responsible_person_name}
                          </td>
                          {/* DEPENDENCY */}
                          <td className="t-body">
                            {isArrayAndHasValue(product?.task_dependencies)
                              ? `${product?.task_dependencies
                                  ?.map((item) => {
                                    const foundTask = itemDetailsList.find(
                                      (itm) => itm?.tna_task_id === item,
                                    );
                                    return foundTask?.tna_task_name;
                                  })
                                  ?.slice(0, 2)
                                  ?.join(", ")}${
                                  product?.task_dependencies?.length > 1
                                    ? "..."
                                    : ""
                                }`
                              : "N/A"}
                          </td>

                          {!view && (
                            <td className="t-body">
                              <div className="cursore-pointer">
                                <Dropdown overlay={menu(product, index)}>
                                  <MoreOutlined style={{ fontSize: "20px" }} />
                                </Dropdown>
                              </div>
                            </td>
                          )}
                        </tr>
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div ref={tableBottomRef} />

      <Button
        style={{ width: "200px", position: "fixed", bottom: "3vh" }}
        type="primary"
        className="mt-2"
        icon={<PlusOutlined />}
        size="small"
        // disabled={view || isDrawerOpen || !isArrayAndHasValue(subStyleList)}
        hidden={view || isDrawerOpen}
        onClick={onAddOpen}
      >
        Add Task
      </Button>
      {/* <Button
        style={{
          width: "200px",
          position: "fixed",
          bottom: "3vh",
          left: "420px",
        }}
        type="primary"
        className="mt-2"
        icon={<PlusSquareOutlined />}
        size="small"
        disabled={view || isDrawerOpen || !isArrayAndHasValue(subStyleList)}
        hidden={view || isDrawerOpen}
        onClick={onBulkAddOpen}
      >
        Bulk Add
      </Button> */}
      {/* <Button
        style={{
          width: "200px",
          position: "fixed",
          bottom: "3vh",
          left: "625px",
        }}
        type="primary"
        className="mt-2"
        icon={<EditOutlined />}
        size="small"
        disabled={!itemDetailsList?.some((item) => item?.is_checked)}
        hidden={view || isDrawerOpen}
        onClick={onBulkEditOpen}
      >
        Bulk Edit
      </Button> */}

      <Drawer
        title={renderDrawerTitle()}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
        width={
          drawerType === "bulk_add" || drawerType === "bulk_edit" ? 1000 : 550
        }
        maskStyle={{
          backdropFilter: "blur(0px)",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        {drawerType === "bulk_add" || drawerType === "bulk_edit" ? (
          <BulkItemsManage
            foundItems={foundItems}
            setFoundItems={setFoundItems}
            activeBulkKey={activeBulkKey}
            setActiveBulkKey={setActiveBulkKey}
            unitList={unitList}
            supplierList={supplierList}
            addBulkItems={addBulkItems}
            drawerType={drawerType}
            editBulkItems={editBulkItems}
          />
        ) : (
          <ItemEdit
            selectedItem={selectedItem}
            getTNATaskList={getTNATaskList}
            TNATaskList={TNATaskList}
            getItemList={getItemList}
            getItemInfo={getItemInfo}
            itemList={itemList}
            unitList={unitList}
            supplierList={supplierList}
            closeDrawer={closeDrawer}
            onUpdateItem={onUpdateItem}
            onAddNewItem={onAddNewItem}
            isDrawerOpen={isDrawerOpen}
            userList={userList}
            itemDetailsList={itemDetailsList}
            TNAStyles={TNAStyles}
            TNAPos={TNAPos}
            getUserList={getUserList}
          />
        )}
      </Drawer>
    </Card>
  );
};

export default ItemDetails;
