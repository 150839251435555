import { Affix, Button, Card } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../../apiServices/common";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";
import AddPurchaseOrderForm from "./AddPurchaseOrderForm";

export default function UpdatePurchaseOrder(props) {
  // Props
  const { width, isAddPrice = false } = props;

  console.log("props", props);

  // States
  const [permitInfo, setPermitInfo] = useState();
  const [poDetailsData, setPoDetailsData] = useState(null);

  // Others
  const { styleId } = useParams();
  const propertyFormRef = useRef(null);
  const edit_url = `/api/purchase_orders/${styleId}/edit`;

  const addProduct = () => {
    propertyFormRef.current.submit();
  };

  const getPOInfo = useCallback(async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data;
      setPermitInfo(masterData);
      const po_data = masterData ? masterData.purchase_order_details : null;

      const po_details = po_data.map((item) => {
        const sizes = {};

        item?.sizes.forEach((size) => {
          sizes[size?.name + "_" + size?.id] = isAddPrice
            ? size?.order_price || 0
            : size?.sales_order_quantity;
        });
        return {
          ...item,
          style_id: item.style.id,
          color_id: item.color.id,
          ...sizes,
        };
      });

      setPoDetailsData(po_details);
    }
  }, [edit_url]);

  useEffect(() => {
    // Only run on first render
    // DO NOT add any depenedency here
    getPOInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={
              isAddPrice
                ? "Add Price For Purchase Order"
                : "Update Purchase Order"
            }
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button
                size="small"
                key={2}
                type="primary"
                onClick={() => addProduct()}
              >
                {isAddPrice
                  ? "Add Price For Purchase Order"
                  : "Update Purchase Order"}
              </Button>,
            ]}
          />
        </Card>
      </Affix>

      <AddPurchaseOrderForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        poDetailsData={poDetailsData}
        setPoDetailsData={setPoDetailsData}
        mode="update"
        isAddPrice={isAddPrice}
      />
    </>
  );
}
