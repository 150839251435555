// import Cookies from "js-cookie";
import { notification } from "antd";
import moment from "moment";

export const checkRes = (param) => {
  if (param === 200 || param === 201 || param === 212) {
    return true;
  } else if (param === 401) {
    // Cookies.remove("OTLToken");
    window.location = "/auth/login";
  } else if (param === 403) {
    // Cookies.remove("OTLToken");
    window.location = "/auth/login";
  } else {
    return false;
  }
};

export const alertPop = (type, data, title = null) => {
  notification[type]({
    placement: "bottomRight",
    message: title || `${type[0].toUpperCase()}${type.slice(1)}`,
    description: data,
  });
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const dateFormat = (date) => {
  if (date) {
    return moment(date).format("YYYY/MM/DD - h:mm a");
  } else return null;
};

export const checkUserPermission = (allow, permissions) => {
  if (allow && permissions?.length) {
    let array = permissions.map((per) => per.name);
    return array.includes(allow);
  } else return false;
};

export const buildSearchQuery = (object = {}) => {
  let query = "";

  for (const [key, value] of Object.entries(object)) {
    if (value) query = query + `&${key}=${value}`;
  }

  return query;
};

export const logout = () => {
  // Cookies.remove("OTLToken");
  window.location = "/auth/login";
};

export const queryBuilder = (data) => {
  if (data) {
    let query = "";

    for (const property in data) {
      if (data[property]) {
        query = query + `&${property}=${data[property]}`;
      }
    }

    return query;
  }
};

export const canAccess = (permissions, context) => {
  if (permissions?.length) {
    return permissions.includes(context);
  }
};

export const priceNumber = (val) => {
  let result = val;

  if (val) {
    let numStr = val.toString();

    if (numStr.includes(".")) {
      let arr = numStr.split("."),
        first = arr[0],
        sec = arr[1];

      if (first?.length >= 11) {
        result = first.slice(0, 11) + "." + sec.slice(0, 3);
      } else {
        result = first + "." + sec.slice(0, 3);
      }
    }
  }

  return result * 1;
};

export const unitNumber = (val) => {
  let result = val;

  if (val) {
    result = parseFloat(val);
  }

  return result;
};

export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const findValueByPrefix = (object, prefix) => {
  let resultArray = [];

  for (var property in object) {
    if (
      object.hasOwnProperty(property) &&
      property.toString().startsWith(prefix)
    ) {
      if(prefix === "bundle_number") {
        resultArray.push(object[property] ? object[property] : "");
      }
      else {
        resultArray.push(object[property] ? object[property] : 0);
      }
    }
  }

  if (Array.isArray(resultArray) && resultArray.length > 0) {
    return resultArray;
  } else {
    return null;
  }
};

export const outgoingMovementTypes = [
  {
    id: "outgoing_1",
    label: "Issue To Cutting",
    value: "issue_to_cutting",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_2",
    label: "Issue to Sewing",
    value: "issue_to_sewing",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_3",
    label: "Issue to Folding",
    value: "issue_to_folding",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_4",
    label: "Issue to Packing",
    value: "issue_to_packing",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_5",
    label: "Issue to Sample",
    value: "issue_to_sample",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_6",
    label: "Mark as Free",
    value: "mark_as_free",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_7",
    label: "Issue to Lab",
    value: "issue_to_lab",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_8",
    label: "Issue To Cutting (Final)",
    value: "issue_to_cutting_final",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_9",
    label: "Sell Stock",
    value: "stock_sale",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_10",
    label: "Send to Buying Office",
    value: "send_to_buying_office",
    movement_type: "outgoing",  
  },
  {
    id: "outgoing_11",
    label: "Return to Supplier",
    value: "return_to_supplier",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_12",
    label: "Disposal",
    value: "disposal",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_13",
    label: "Issue To Others",
    value: "issue_to_others",
    movement_type: "outgoing",
  },
  {
    id: "outgoing_14",
    label: "Return from Sub-Con Factory",
    value: "return_to_sub_con_factory",
    movement_type: "outgoing",
  },
];

export const incomingMovementTypes = [
  {
    id: "incoming_1",
    label: "Receive From Supplier",
    value: "receive_from_supplier",
    movement_type: "incoming",
  },
  {
    id: "incoming_2",
    label: "Assign from Free Balance",
    value: "assign_from_free_balance",
    movement_type: "incoming",
  },
  {
    id: "incoming_3",
    label: "Return From Cutting",
    value: "return_from_cutting",
    movement_type: "incoming",
  },
  {
    id: "incoming_4",
    label: "Return From Sewing",
    value: "return_from_sewing",
    movement_type: "incoming",
  },
  {
    id: "incoming_5",
    label: "Return From Folding",
    value: "return_from_folding",
    movement_type: "incoming",
  },
  {
    id: "incoming_6",
    label: "Return From Packing",
    value: "return_from_packing",
    movement_type: "incoming",
  },
  {
    id: "incoming_7",
    label: "Return From Cutting (final)",
    value: "return_from_cutting_final",
    movement_type: "incoming",
  },
  {
    id: "incoming_8",
    label: "Receive from Sub-Con Factory",
    value: "receive_from_sub_con_factory",
    movement_type: "incoming",
  },
];

export const getMovementType = (movement) => {
  const outgoingType = outgoingMovementTypes.find((item) => item?.value === movement);
  const incomingType = incomingMovementTypes.find((item) => item?.value === movement);

  return outgoingType ? "outgoing" : incomingType ? "incoming" : null;
};

export const getMovementTypeLabel = (movement) => {
  const outgoingType = outgoingMovementTypes.find((item) => item?.value === movement);
  const incomingType = incomingMovementTypes.find((item) => item?.value === movement);

  if(outgoingType) {
    return outgoingType?.label || null;
  }
  else {
    return incomingType?.label || null;
  }
};

