import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Card,
  Form,
  Select,
  Modal,
  Popover,
  InputNumber,
  Input,
  DatePicker,
  Divider,
  Space,
  Button,
  message,
  Upload,
} from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  ADD_B2BLC_REMARKS,
  ADD_LC_REASON,
  B2BLC_DOCUMENT_UPLOAD,
  B2BLC_REMARKS_LIST,
  EXPORT_LC_REASONS,
  SALES_CONTRACT_COMMERCIAL_DOC,
  IMPORT_COVER_NOT_ADD,
  IMPORT_COVER_NOT
} from "../../../../apiServices/API_ENDPOINTS";
import { useParams } from "react-router";
import { getData, postData } from "../../../../apiServices/common";
import HandleNotification from "../../../../common/Notification";
import { isArrayAndHasValue } from "../../../../utils/functions";
import { BASE_URL } from "../../../../apiServices";

const token = localStorage.getItem("token");

const B2bAmendmentTable = (props) => {
  // Props
  const { lcdetailsList, setLcdetailsList, view, SupplierType } = props;

  // States
  const [addedReason, setAddedReason] = useState(null);
  const [reasons, setReasons] = useState(null);

  // Antd
  const [lcdetailsForm] = Form.useForm();

  // Others
  const { importId } = useParams();
  const initialLCDetails = {
    unique_id: 1,
    import_b2blc_id: importId ? Number(importId) : null,
    amendment_number: null,
    amendment_date: null,
    amendment_value: null,
    amendment_quantity: null,
    remarks: [],
    insurance_cover_note: null,
    lc_amendment_file: null,
  };

  const getReasonsList = async (filterValues) => {
    const query = `${B2BLC_REMARKS_LIST}`;
    const bodyData = {
      ...filterValues,
      per_page: filterValues?.per_page || 1000,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setReasons(response?.data?.data);
    }
  };

  console.log('SupplierType', SupplierType);

  useEffect(() => {
    getReasonsList();
  }, []);

  const onReasonChange = (event) => {
    setAddedReason(event.target.value);
  };

  const addNewReason = async (event) => {
    event.preventDefault();
    const query = `${ADD_B2BLC_REMARKS}`;
    const bodyData = {
      remarks: addedReason,
      import_b2blc_id: importId ? Number(importId) : null,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      setAddedReason(null);
      HandleNotification("success", "Reason added successfully");
      getReasonsList();
    }
  };

  const addRow = (unique_id) => {
    const lcDetailsListCopy = isArrayAndHasValue(lcdetailsList)
      ? [...lcdetailsList]
      : [];
    const newData = {
      ...initialLCDetails,
      unique_id: isArrayAndHasValue(lcDetailsListCopy)
        ? lcDetailsListCopy[lcDetailsListCopy.length - 1].unique_id + 1
        : 0,
      amendment_number: `AMENDMENT ${lcDetailsListCopy.length}`,
    };
    lcDetailsListCopy.push(newData);
    setLcdetailsList(lcDetailsListCopy);
  };

  const removeRow = (unique_id) => {
    const lcDetailsListCopy = [...lcdetailsList];
    const newData = lcDetailsListCopy.filter(
      (item) => item?.unique_id !== unique_id,
    );
    setLcdetailsList(newData);
  }

  const onChangeLCValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const lcDetailsDataCopy = [...lcdetailsList];
    const foundLCItem = lcDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundLCItem[keyName] = value;

    setLcdetailsList(lcDetailsDataCopy);
  };

  const onUploadFile = (info, key) => {
    const fileResponse =
      info?.file?.response?.code === 200 ? info?.file?.response?.data : null;
    if (fileResponse) {
      onChangeLCValue(fileResponse, key, "lc_amendment_file");
    }
  };

  return (
    <Form form={lcdetailsForm}>
      <div>
        <Card
          title={<span style={{ fontSize: "14px" }}>Amendment Details</span>}
        >
          <div className="custome-table">
            <table>
              <thead>
                <tr>
                  <th className="t-header" colSpan="2"> </th>
                  <th className="t-header bc-java " colSpan="2">
                    Change
                  </th>
                </tr>
                <tr>
                  <th key="header_initial_amendment" className="bc-java ">
                    Initial/Amendment{" "}
                  </th>
                  <th key="header_amendment_date" className="bc-java ">
                    LC/Amendment Date{" "}
                  </th>
                  <th key="header_lc_qty" className="bc-java ">
                    LC Quantity{" "}
                  </th>
                  <th key="header_lc_value" className="bc-java ">
                    LC Value{" "}
                  </th>
                  <th key="header_reason" className="bc-java ">
                    Reason of Amendment{" "}
                  </th>
                  {SupplierType === "Foreign" && (
                      <th key="header_reason" className="bc-java ">
                        Insurance Cover Note{" "}
                      </th>
                  )}
                  <th key="header_document" className="bc-java ">
                    Document{" "}
                  </th>
                  <th key="header_upload" className="bc-java ">
                    Upload{" "}
                  </th>
                  {/* <th key="header_add" className="bc-java ">
                    Add{" "}
                  </th> */}
                  <th key="header_remove" className="bc-java ">
                    Rmv{" "}
                  </th>
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(lcdetailsList) &&
                  lcdetailsList?.map((item, index) => {
                    return (
                      <tr key={`table_parent_key_${index}`}>
                        {/* INITIAL/AMENDMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`amendment_number_${index}`}
                              value={item?.amendment_number || ""}
                              onChange={(event) => {
                                let context = "amendment_number";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="Initial/Amendment"
                              disabled={true}
                            />
                          </Form.Item>
                        </td>
                        {/* LC/Amendment Date */}
                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="LC/Amendment Date"
                              size="small"
                              value={item?.amendment_date}
                              onChange={(date, dateString) => {
                                let context = "amendment_date";
                                onChangeLCValue(date, item?.unique_id, context);
                              }}
                              disabled={item?.amendment_number === "Initial" || view}
                            />
                          </Form.Item>
                        </td>
                        {/* LC QUANTITY */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <Input
                              name={`export_lc_qty_${index}`}
                              value={item?.amendment_quantity || ""}
                              onChange={(event) => {
                                let context = "amendment_quantity";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="LC Quantity"
                              disabled={item?.amendment_number === "Initial" || view}
                            />
                          </Form.Item>
                        </td>
                        {/* LC VALUE */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                          >
                            <Input
                              name={`amendment_value_${index}`}
                              value={item?.amendment_value || ""}
                              onChange={(event) => {
                                let context = "amendment_value";
                                onChangeLCValue(
                                  event.target.value,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              size="small"
                              placeholder="LC Value"
                              disabled={item?.amendment_number === "Initial" || view}
                            />
                          </Form.Item>
                        </td>

                        {/* REASON OF AMENDMENT */}
                        <td className="t-body">
                          <Form.Item style={{ marginBottom: "unset" }}>
                            <Select
                              style={{
                                width: "100%",
                              }}
                              size="small"
                              placeholder="Reasons"
                              mode="multiple"
                              onSelect={(value) => {
                                const context = "remarks";
                                const reasons_data = item?.remarks;
                                reasons_data.push(value);
                                onChangeLCValue(
                                  reasons_data,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              onDeselect={(value) => {
                                const context = "remarks";
                                const reasons_data = item?.remarks;
                                const filteredReasons = reasons_data?.filter(
                                  (item) => item !== value,
                                );
                                onChangeLCValue(
                                  filteredReasons,
                                  item?.unique_id,
                                  context,
                                );
                              }}
                              value={item?.remarks}
                              disabled={item?.amendment_number === "Initial" || view}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: "8px 0",
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: "0 8px 4px",
                                    }}
                                  >
                                    <Input
                                      placeholder="Enter new reason"
                                      size="small"
                                      value={addedReason}
                                      onChange={onReasonChange}
                                      // ref={inputRef}
                                    />
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      size="small"
                                      onClick={addNewReason}
                                    >
                                      Add
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={reasons?.map((item) => ({
                                label: item?.remarks || "",
                                value: item?.id,
                              }))}
                            />
                          </Form.Item>
                        </td>

                        {/* Insurance Cover Note */}
                        {SupplierType === "Foreign" && (
                          <td className="t-body">
                            <Form.Item
                              style={{ marginBottom: "unset" }}
                            >
                              <Input
                                name={`insurance_cover_note_${index}`}
                                value={item?.insurance_cover_note || ""}
                                onChange={(event) => {
                                  let context = "insurance_cover_note";
                                  onChangeLCValue(
                                    event.target.value,
                                    item?.unique_id,
                                    context,
                                  );
                                }}
                                size="small"
                                placeholder="Insurance Cover Note"
                                // disabled={item?.amendment_number === "Initial" || view}
                                disabled={view}
                              />
                            </Form.Item>
                          </td>
                        )}
                        {/* DOCUMENT */}
                        <td className="t-body">
                          <Form.Item
                            style={{ marginBottom: "unset" }}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please Insert Extra Cutting!",
                            //   },
                            // ]}
                          >
                            <span>
                              <a
                                href={`${BASE_URL}/${item?.lc_amendment_file?.document}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.lc_amendment_file?.document_name
                                  ? `${item?.lc_amendment_file?.document_name?.slice(
                                      0,
                                      10,
                                    )}...`
                                  : ""}
                              </a>
                            </span>
                          </Form.Item>
                        </td>
                        {/* UPLOAD BUTTON */}
                        <td className="t-body">
                          <Upload
                            // action={`${BASE_URL}${B2BLC_DOCUMENT_UPLOAD}`}
                            action={
                              importId
                                ? `${BASE_URL}${B2BLC_DOCUMENT_UPLOAD}?b2b_lc_id=${importId}&item_unique_id=${item?.unique_id}`
                                : `${BASE_URL}${B2BLC_DOCUMENT_UPLOAD}`
                            }
                            accept={"image/*,.pdf, .doc, .xlsx"}
                            headers={{
                              authorization: `Bearer ${token}`,
                            }}
                            showUploadList={false}
                            onChange={(info) =>
                              onUploadFile(info, item?.unique_id)
                            }
                          >
                            <Button
                              type="primary"
                              icon={<DownloadOutlined />}
                              size="small"
                              disabled={view}
                            >
                              Upload
                            </Button>
                          </Upload>
                        </td>
                        {/* ADD BUTTON */}
                        {/* <td
                          className="t-body"
                          onClick={() => {
                            addRow(item?.unique_id);
                          }}
                        >
                          <PlusCircleOutlined
                            className="cursore-pointer"
                            style={{ fontSize: "16px", color: "Green" }}
                          />
                        </td> */}
                        <td
                          className="t-body"
                          onClick={() => {
                            // Disable the remove icon for the first item with "Initial/Amendment" set to "Initial"
                            // Enable it for the last item
                            if (index === 0 && item?.amendment_number === "Initial") {
                              return;
                            }
                            if (index === lcdetailsList.length - 1) {
                              removeRow(item?.unique_id);
                            }
                          }}
                        >
                          <MinusCircleOutlined
                            className={`cursore-pointer ${index === 0 && item?.amendment_number === "Initial" ? "disabled-icon" : ""}`}
                            style={{ 
                              fontSize: "16px", 
                              // color: "Red" 
                              color: (index === 0 && item?.amendment_number === "Initial") || index != lcdetailsList.length - 1 ? "grey" : "red", // Muted color
                              cursor: (index === 0 && item?.amendment_number === "Initial") || index != lcdetailsList.length - 1 ? "not-allowed" : "pointer", // Disabled cursor
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {importId && (
              <Button
                type="primary"
                size="small"
                style={{ marginTop: 10 }}
                onClick={addRow}
                disabled={view}
              >
                Add New
              </Button>
            )}
          </div>
        </Card>
      </div>
    </Form>
  );
};

export default B2bAmendmentTable;
