import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Layout, Modal, Typography } from "antd";
import {
  PACKING_COLOR_LIST,
  PACKING_STYLE_LIST,
  PO_MAP_ENDPOINT,
  PO_WISE_PACKING_ADD,
  PO_WISE_PACKING_UPDATE,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PACKING_CREATE_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import AppPageHeader from "../../../../common/PageHeader";
import InventoryLayOutCommon from "../../../InventoryFabric/LayOut/InventoryLayOutCommon";
import PackingDetails from "../PackingDetails";
import PackingReqForm from "./PackingReqForm";

const CreatePackingReq = (props) => {
  // Props
  const { isEdit, isView, selectedPoEditData, poMapId, editId } = props;

  // States
  const [poList, setPoList] = useState(null);
  const [styleList, setStyleList] = useState(null);
  const [poDetailsData, setPoDetailsData] = useState([{ unique_id: 0 }]);
  const [selectedPoColorList, setSelectedPoColorList] = useState(null);
  const [selectedPOUniqueID, setSelectedPOUniqueID] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFactory, setIsFactory] = useState(false);

  // console.log("1. selectedPoEditData render", selectedPoEditData);
  // console.log("2. poMapId", poMapId);

  // Router
  const navigate = useNavigate();

  // Antd
  const { Content } = Layout;
  const { Panel } = Collapse;
  const [PackReqForm] = Form.useForm();
  const { confirm } = Modal;
  const { Title } = Typography;

  const extra = (
    <div className="extra-button-container">
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => PackReqForm.submit()}
        disabled={!hasPermission([PACKING_CREATE_PERMISSION]) || isSubmitting}
        loading={isSubmitting}
      >
        {hasPermission([PACKING_CREATE_PERMISSION])
          ? "Submit"
          : "No Permission"}
      </Button>
      <Button
        size="small"
        htmlType="submit"
        onClick={() => {
          // reset all field
          PackReqForm.resetFields();
          // navigate.push("/merchandising/packing-requirements");
        }}
      >
        {" "}
        Reset
      </Button>
    </div>
  );

  const getPOList = async (filterValues) => {
    let query = `${PO_MAP_ENDPOINT}`;
    const bodyData = {
      per_page: filterValues?.per_page || 5,
      ...filterValues,
    };
    const response = await getData(query, false, bodyData);

    if (response) {
      setPoList(response?.data?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const getDataFromPOMapId = useCallback(
    (po_id) => {
      const selectedPO = poList?.find((po) => po?.id === po_id);
      getStyleList(selectedPO?.internal_po);
      selectedPO &&
        PackReqForm.setFieldsValue({
          original_po: selectedPO?.original_po,
          internal_po: selectedPO?.internal_po,
          buying_office_name: selectedPO?.buying_office_name,
          buying_office_id: selectedPO?.buying_office_id,
          buyer_name: selectedPO?.buyer_name,
          buyer_id: selectedPO?.buyer_id,
          sales_contract_id: selectedPO?.sales_contract_id,
          reference_no: selectedPO?.reference_no,
          po_map_id: selectedPO?.id,
          total_order_quantity: selectedPO?.total_order_quantity
            ? Number(selectedPO?.total_order_quantity)
            : 0,
        });
    },
    [PackReqForm, poList],
  );

  useEffect(() => {
    // Get PO List on first render

    if (isEdit) {
      getPOList({ po_map_id: poMapId });
    } else {
      getPOList();
    }
  }, [isEdit, poMapId]);

  useEffect(() => {
    // When in edit or view mode, set podetailsdata for table
    if (isArrayAndHasValue(selectedPoEditData)) {
      setPoDetailsData(selectedPoEditData);

      // Set value of PO Packing Req Qty field value in form
      const totalPackReqQty = selectedPoEditData?.reduce((result, item) => {
        return result + Number(item?.carton_pack_req_qty);
      }, 0);
      PackReqForm.setFieldsValue({
        total_packing_requirements: totalPackReqQty || 0,
      });
    }
  }, [isEdit, isView, selectedPoEditData, PackReqForm]);

  useEffect(() => {
    if (poMapId || poMapId === 0) {
      getDataFromPOMapId(poMapId);
    }
  }, [poMapId, getDataFromPOMapId]);

  const cartonQtyCalculation = (cartonData) => {
    // Flatten the structure into a single array of objects
    let flatData = [];

    isArrayAndHasValue(cartonData) &&
      cartonData?.forEach((style) => {
        style?.colors?.forEach((color) => {
          color?.sizes?.forEach((size) => {
            flatData.push({
              style_id: style.style_id,
              color_id: color.color_id,
              size_id: size.size_id,
              distributed_qty: size.quantity,
            });
          });
        });
      });

    // Aggregate the quantities
    const result = Object.values(
      flatData.reduce(
        (acc, { style_id, color_id, size_id, distributed_qty }) => {
          const key = `${style_id}-${color_id}-${size_id}`;
          if (!acc[key]) {
            acc[key] = { style_id, color_id, size_id, distributed_qty };
          } else {
            acc[key].distributed_qty += distributed_qty;
          }
          return acc;
        },
        {},
      ),
    );

    return result;
  };

  const onFinishPacking = async (values) => {
    setIsSubmitting(true);
    // Cartondata contains all data except unique_id
    const cartonData = poDetailsData?.map((item) => {
      const { unique_id, ...rest } = item;
      return rest;
    });

    const styleWiseTotals = cartonQtyCalculation(cartonData);

    const bodyData = {
      buying_office_id: values?.buying_office_id,
      sales_contract_id: values?.sales_contract_id,
      sub_contract_factory_id: values?.sub_contract_factory_id,
      buyer_id: values?.buyer_id,
      po_map_id: values?.po_map_id,
      status: values?.status ? 1 : 0,
      cartons: cartonData,
      style_wise_totals: styleWiseTotals,
    };
    const query = isEdit
      ? `${PO_WISE_PACKING_UPDATE}/${editId}`
      : `${PO_WISE_PACKING_ADD}`;

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      alertPop(
        "success",
        response?.message?.[0] ||
          `Packing Requirement ${isEdit ? "Updated" : "Inserted"} Successfully`,
      );
      setIsSubmitting(false);
      navigate(`/merchandising/packing-requirements`);
    } else {
      alertPop("error", "Something went wrong");
      setIsSubmitting(false);
    }
  };

  const getStyleList = async (internal_po) => {
    const query = `${PACKING_STYLE_LIST}`;
    const bodyData = {
      internal_po: internal_po,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const onChangeInternalPO = (value) => {
    const selectedPO = poList?.find((po) => po?.internal_po === value);
    selectedPO &&
      PackReqForm.setFieldsValue({
        original_po: selectedPO?.original_po,
        buying_office_name: selectedPO?.buying_office_name,
        buying_office_id: selectedPO?.buying_office_id,
        buyer_name: selectedPO?.buyer_name,
        buyer_id: selectedPO?.buyer_id,
        sales_contract_id: selectedPO?.sales_contract_id,
        reference_no: selectedPO?.reference_no,
        po_map_id: selectedPO?.id,
        total_order_quantity: selectedPO?.total_order_quantity,
        sub_contract_factory_id: selectedPO?.sub_contract_factory_id,
        sub_contract_factory_name: selectedPO?.sub_contract_factory_name || "",
      });
    getStyleList(value);
    setIsFactory(selectedPO?.sub_contract_factory_id ? true : false);
  };

  const onChangeOriginalPO = (value) => {
    const selectedPO = poList?.find((po) => po?.original_po === value);
    selectedPO &&
      PackReqForm.setFieldsValue({
        internal_po: selectedPO?.internal_po,
        buying_office_name: selectedPO?.buying_office_name,
        buying_office_id: selectedPO?.buying_office_id,
        buyer_name: selectedPO?.buyer_name,
        buyer_id: selectedPO?.buyer_id,
        sales_contract_id: selectedPO?.sales_contract_id,
        reference_no: selectedPO?.reference_no,
        po_map_id: selectedPO?.id,
        total_order_quantity: selectedPO?.total_order_quantity,
        sub_contract_factory_id: selectedPO?.sub_contract_factory_id,
        sub_contract_factory_name: selectedPO?.sub_contract_factory_name || "",
      });
    getStyleList(selectedPO?.internal_po);
    setIsFactory(selectedPO?.sub_contract_factory_id ? true : false);
  };

  const copyRow = (row_id) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === row_id,
    );
    const clonedFoundPoItem = structuredClone(foundPoItem);
    const unique_id = Date.now();

    const new_start_range = clonedFoundPoItem?.end_range + 1;
    const new_end_range = new_start_range + (clonedFoundPoItem?.carton_qty - 1);

    clonedFoundPoItem?.sizes?.forEach((sizeItem, index) => {
      sizeItem.po_detail_id = 0;
    });

    const updatePoDetails = [
      ...poDetailsData,
      {
        ...clonedFoundPoItem,
        unique_id: unique_id,
        start_range: new_start_range,
        end_range: new_end_range,
      },
    ];
    setPoDetailsData(updatePoDetails);
  };

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const foundPoItem = poDetailsData?.find(
          (item) => item?.unique_id === row_index,
        );
        const updatedPoDetailsData = poDetailsData.filter(
          (item) => item?.unique_id !== row_index,
        );
        setPoDetailsData(updatedPoDetailsData);
      },
    });
  };

  const onChangePoDetailsValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const poDetailsDataCopy = [...poDetailsData];
    // If keyName is start_range or end_range, then calculate carton_qty

    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    foundPoItem[keyName] = value;
    if (
      ["start_range", "end_range"].includes(keyName) &&
      poDetailsDataCopy?.length > 1
    ) {
      foundPoItem["carton_pack_req_qty"] =
        Math.abs(foundPoItem?.carton_qty) * foundPoItem?.total_quantity;
    }
    setPoDetailsData(poDetailsDataCopy);
  };

  const calculateQty = (uniqueId) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const qty = foundPoItem?.end_range - foundPoItem?.start_range;
    if (foundPoItem?.end_range && foundPoItem?.start_range) {
      foundPoItem["carton_qty"] =
        foundPoItem?.end_range - foundPoItem?.start_range + 1;
    } else if (qty && qty > 0) {
      foundPoItem["carton_qty"] = qty + 1;
    } else {
      foundPoItem["carton_qty"] = 0;
    }
  };

  const calculateCBM = (uniqueId) => {
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const cbm =
      (foundPoItem?.length * foundPoItem?.width * foundPoItem?.height) /
      1000000;
    const totalCbm = cbm * foundPoItem?.carton_qty;

    if (cbm && cbm > 0) {
      foundPoItem["cbm"] = cbm;
    } else {
      foundPoItem["cbm"] = 0;
    }
    if (totalCbm && totalCbm > 0) {
      foundPoItem["total_cbm"] = totalCbm;
    } else {
      foundPoItem["total_cbm"] = 0;
    }
  };

  const calculateTotalGrossWeight = (uniqueId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const totalGrossWeight = foundPoItem?.gross * foundPoItem?.carton_qty;

    if (totalGrossWeight && totalGrossWeight > 0) {
      foundPoItem["total_gross"] = totalGrossWeight;
    } else {
      foundPoItem["total_gross"] = 0;
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const calculateTotalNetWeight = (uniqueId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === uniqueId,
    );
    const totalNetWeight = foundPoItem?.net * foundPoItem?.carton_qty;

    if (totalNetWeight && totalNetWeight > 0) {
      foundPoItem["total_net"] = totalNetWeight;
    } else {
      foundPoItem["total_net"] = 0;
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const getPackingColorList = async (uniqueID) => {
    // Set selected PO id to state
    setSelectedPOUniqueID(uniqueID);

    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === uniqueID,
    );
    const internal_po = PackReqForm.getFieldValue("internal_po");
    const query = `${PACKING_COLOR_LIST}`;
    const bodyData = {
      internal_po: internal_po,
      style_id: foundPoItem?.style_id,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSelectedPoColorList(response?.data?.data);
    } else {
      alertPop("error", "Something went wrong");
    }
  };

  const insertSizeTableData = (tableData, sizeTrackForm) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );
    const sizeRatioTotal = sizeTrackForm.getFieldValue("size_ratio_total")
      ? Number(sizeTrackForm.getFieldValue("size_ratio_total"))
      : 0;
    const cartonPackReqQtyTotal = tableData?.reduce((result, item) => {
      return result + Number(item?.req_color_total);
    }, 0);

    if (isArrayAndHasValue(tableData)) {
      // Removing unique_id from tableData
      const updatedData = tableData.map((item) => {
        return item;
      });
      foundPoItem["colors"] = updatedData;
      foundPoItem["total_quantity"] = sizeRatioTotal;
      foundPoItem["carton_pack_req_qty"] = cartonPackReqQtyTotal;
    }

    // Calculate and set total packing req qty
    // Set value to PackReqForm form

    const total_carton_pack_req_qty = poDetailsDataCopy?.reduce(
      (result, item) => {
        return result + Number(item?.carton_qty) * Number(item?.total_quantity);
      },
      0,
    );
    PackReqForm.setFieldsValue({
      total_packing_requirements: total_carton_pack_req_qty,
    });

    alertPop("success", "Data Inserted");

    setPoDetailsData(poDetailsDataCopy);
  };

  // console.log({ 3: poDetailsData });

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader
          title={<Title level={5}>Packing Requirements Entry</Title>}
          extra={extra}
        />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Requirements Details</b>} key="1">
              <Form
                layout="vertical"
                onFinish={onFinishPacking}
                form={PackReqForm}
              >
                <PackingReqForm
                  poList={poList}
                  getPOList={getPOList}
                  onChangeInternalPO={onChangeInternalPO}
                  onChangeOriginalPO={onChangeOriginalPO}
                  isFactory={isFactory}
                />
              </Form>
            </Panel>
          </Collapse>
          <PackingDetails
            styleList={styleList}
            poDetailsData={poDetailsData}
            copyRow={copyRow}
            removeRow={removeRow}
            onChangePoDetailsValue={onChangePoDetailsValue}
            calculateQty={calculateQty}
            calculateCBM={calculateCBM}
            calculateTotalGrossWeight={calculateTotalGrossWeight}
            calculateTotalNetWeight={calculateTotalNetWeight}
            getPackingColorList={getPackingColorList}
            selectedPoColorList={selectedPoColorList}
            setSelectedPoColorList={setSelectedPoColorList}
            PackReqForm={PackReqForm}
            selectedPOUniqueID={selectedPOUniqueID}
            setSelectedPOUniqueID={setSelectedPOUniqueID}
            insertSizeTableData={insertSizeTableData}
            isEdit={isEdit}
            setPoDetailsData={setPoDetailsData}
          />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default CreatePackingReq;
