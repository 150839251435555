import { Alert, Empty, Typography } from "antd";
import { postData } from "apiServices/common";
import { useEffect, useState } from "react";
import LineData from "./LineData";

const ProductionTracker = ({ line_id = 14 }) => {
  const [productionTrackerData, setProductionTrackerData] = useState({});

  function transformChartData(chart_data) {
    const result = [];

    chart_data.forEach((item) => {
      // Plan Quantity
      result.push({
        hour: item.hour,
        value: item.plan_qty,
        type: "Plan Quantity",
      });

      // Actual Quantity
      result.push({
        hour: item.hour,
        value: item.actual_qty,
        type: "Actual Quantity",
      });
    });

    // console.log(result);

    return result;
  }

  const initialGetData = async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/production-tracker",
      {
        line_id: line_id,
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setProductionTrackerData(data);
    }
  };

  useEffect(() => {
    initialGetData();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Typography.Title
        level={3}
        style={{
          textAlign: "center",
        }}
      >
        Production Tracker
      </Typography.Title>
      {productionTrackerData && productionTrackerData?.length > 0 ? (
        productionTrackerData?.map((p) => (
          <LineData key={p?.id} data={transformChartData(p?.chart_data)} />
        ))
      ) : (
        <Empty
          style={{ width: "33%", margin: "auto", marginTop: "10%" }}
          description={
            <Alert
              message="No Data Found with selected line-id in Production Tracker"
              type="warning"
            />
          }
        />
      )}
    </div>
  );
};

export default ProductionTracker;
