import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Affix,
  Button,
  Card,
  Col,
  InputNumber,
  Row,
  Table,
  Typography,
  Modal,
  Popconfirm,
  Form,
} from "antd";
import { EditOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";
import Header from "components/Common/Header";
import { ProductionPlanningContext } from "context/ProductionPlanningContext";
import ProductionPlanningForm from "./ProductionPlanningForm";
import moment from "moment";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";

export default function ProductionPlanning(props) {
  // Props
  const { editMode, viewMode } = props;

  // Params
  const { id } = useParams();

  // Context
  const {
    form,
    planningDetails,
    existingPlan,
    overlapPlans,
    onFinish,
    onDeletePlan,
    onEditPlan,
    onChangePlanValue,
    onRegenerate,
    getPlanDetails,
    selectedPlanDetails,
    onInquiryAndStyleChange,
    setPlanningDetails,
    setExistingPlan,
    options,
    setOptions,
    onSelectBuyerInquiry,
  } = useContext(ProductionPlanningContext);

  //  Router
  const navigate = useNavigate();

  // Antd
  const { confirm } = Modal;
  const line_watch = Form.useWatch("line", form);
  const sales_contract_po_id_watch = Form.useWatch("sales_contract_po_id", form);
  const use_both_style_po_watch = Form.useWatch("use_both_style_po", form);
  const shipment_date_watch = Form.useWatch("shipment_date", form);
  const start_date_watch = Form.useWatch("start_date", form);

  console.log('use_both_style_po_watch', use_both_style_po_watch);
  

  useEffect(() => {
    if(id && editMode) {
      getPlanDetails(id);
    }
  }, [id, editMode, getPlanDetails]);

  useEffect(() => {
    if(editMode && selectedPlanDetails) {
      const optionsCopy = options;

      const inquiry_list = isArrayAndHasValue(optionsCopy?.inquiryList) ? optionsCopy?.inquiryList : [];
      const style_list = isArrayAndHasValue(optionsCopy?.styleList) ? optionsCopy?.styleList : [];

      const isExistsInquiry = inquiry_list?.find((item) => item?.id === selectedPlanDetails?.inquiry_id);
      const isExistsStyle = style_list?.find((item) => item?.id === selectedPlanDetails?.style_id);

      if(!isExistsInquiry) {
        inquiry_list.push({
          id: selectedPlanDetails?.inquiry_id,
          inquiry_no: selectedPlanDetails?.inquiry_no,
        });
        optionsCopy["inquiryList"] = inquiry_list;
        setOptions(optionsCopy);
      }
      
      if(!isExistsStyle) {
        style_list.push({
          id: selectedPlanDetails?.style_id,
          style_no: selectedPlanDetails?.style_no,
        });
        optionsCopy["styleList"] = style_list;
        setOptions(optionsCopy);
      }
    }
  }, [editMode, selectedPlanDetails, options, setOptions]);

  useEffect(() => {
    if(editMode && selectedPlanDetails) {
      onSelectBuyerInquiry(selectedPlanDetails?.inquiry_id);
      onInquiryAndStyleChange({
        inquiry_id_prop: selectedPlanDetails?.inquiry_id,
        style_id_prop: selectedPlanDetails?.style_id,
      }, "edit_mode");

      form.setFieldsValue({
        line: selectedPlanDetails?.line_id,
        inquiry_id: selectedPlanDetails?.inquiry_id,
        sales_contract_id: selectedPlanDetails?.sales_contract_id,
        sales_contract_po_id: selectedPlanDetails?.po_map_id,
        shipment_date: selectedPlanDetails?.shipment_date ? moment(selectedPlanDetails?.shipment_date) : null,
        start_date: selectedPlanDetails?.start_date ? moment(selectedPlanDetails?.start_date) : null,
        end_date: selectedPlanDetails?.end_date ? moment(selectedPlanDetails?.end_date) : null,
        is_apply_learning_curve: selectedPlanDetails?.is_apply_learning_curve,
        planned_mh: selectedPlanDetails?.planned_mh,
        planned_qty: selectedPlanDetails?.planned_qty,
        style_id: selectedPlanDetails?.style_id,
      });

      setPlanningDetails(selectedPlanDetails?.pp_initiate_details);
      setExistingPlan(selectedPlanDetails?.existing_plan);
    }
  }, [selectedPlanDetails, editMode, form, setPlanningDetails, setExistingPlan]);

  // useEffect(() => {
  //   if (editMode && line_watch) {
  //     onInquiryAndStyleChange(`${selectedPlanDetails?.inquiry_id}-${selectedPlanDetails?.style_id}`);
  //   }
  // }, [line_watch, editMode, onInquiryAndStyleChange, selectedPlanDetails]);

  const discartForm = () => {
    navigate("/production-planning");
  };

  const onPlanDelete = (day) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onDeletePlan(day);
      },
    });
  };

  const planningDetailsColumns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Learning Curve",
      children: [
        {
          title: "WH",
          dataIndex: "working_hours",
          key: "lc_working_hours",
        },
        {
          title: "Efficiency",
          dataIndex: "efficiency",
          key: "lc_efficiency",
        },
        {
          title: "Qty",
          dataIndex: "dayQty",
          key: "lc_dayQty",
        },
      ],
    },
    {
      title: "Plan",
      children: [
        {
          title: "WH",
          dataIndex: "plan_curve_mh",
          key: "plan_curve_mh",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value}
                disabled={!record?.isEnableField || moment(record?.date).isSameOrBefore(moment(), 'day')}
                onChange={(value) =>
                  onChangePlanValue(record?.day, value, "plan_curve_mh")
                }
              />
            );
          },
        },
        {
          title: "Efficiency",
          dataIndex: "plan_curve_efficiency",
          key: "plan_curve_efficiency",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value}
                disabled={!record?.isEnableField}
                onChange={(value) =>
                  onChangePlanValue(record?.day, value, "plan_curve_efficiency")
                }
              />
            );
          },
        },
        {
          title: "Qty",
          dataIndex: "plan_curve_qty",
          key: "plan_curve_qty",
          render: (value, record) => {
            return (
              <InputNumber
                size="small"
                value={value}
                disabled={!record?.isEnableField}
                onChange={(value) =>
                  onChangePlanValue(record?.day, value, "plan_curve_qty")
                }
              />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      children: [
        {
          title: "Delete",
          key: "delete_action",
          render: (record) => (
            <Button
              type="default"
              danger
              size="small"
              onClick={() => onPlanDelete(record?.day)}
              icon={<MinusOutlined />}
            />
          ),
        },
        {
          title: "Edit",
          align: "center",
          key: "edit_action",
          render: (record) => (
            <Button
              type="primary"
              size="small"
              onClick={() => onEditPlan(record?.day)}
              icon={<EditOutlined />}
            />
          ),
        },
        {
          title: "Regenerate",
          key: "regenerate_action",
          render: (record) => (
            <Button
              type="primary"
              size="small"
              disabled={!record?.edited}
              onClick={() => onRegenerate(record)}
            >
              Regenerate
            </Button>
          ),
        },
      ],
    },
  ];

  const existingPlanColumns = [
    {
      title: "Line",
      dataIndex: "line_id",
      key: "line_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "existing_date",
    },
    {
      title: "Plan Qty",
      dataIndex: "plan_qty",
      key: "plan_qty",
    },
    {
      title: "Actual Qty",
      dataIndex: "actual_qty",
      key: "actual_qty",
    },
  ];

  const overlapPlansColumns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "overlap_sl",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "overlap_date",
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      key: "end_date",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "overlap_buyer",
    },
    {
      title: "Buying Office",
      dataIndex: "buying_office",
      key: "overlap_buyingOffice",
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "overlap_orderStatus",
    },
    {
      title: "Inquiry & Style",
      dataIndex: "inquiry_style_id",
      key: "overlap_inquiryAndStyle",
    },
    {
      title: "PCD",
      dataIndex: "pcd",
      key: "overlap_pcd",
    },
    {
      title: "Ship. Date",
      dataIndex: "shipment_date",
      key: "overlap_shipDate",
    },
    {
      title: "New Date",
      dataIndex: "new_date",
      key: "overlap_newDate",
    },
  ];

  const TableSummary = ({data}) => {
    const whTotal = isArrayAndHasValue(data) && data.reduce((acc, item) => {
      return acc + Number(item?.working_hours);
    }, 0);

    const dayQtyTotal = isArrayAndHasValue(data) && data.reduce((acc, item) => {
      return acc + Number(item?.dayQty);
    }, 0);

    const planWHTotal = isArrayAndHasValue(data) && data.reduce((acc, item) => {
      return acc + Number(item?.plan_curve_mh);
    }, 0);

    const planQtyTotal = isArrayAndHasValue(data) && data.reduce((acc, item) => {
      return acc + Number(item?.plan_curve_qty);
    }, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row style={{ backgroundColor: "#F5F5F5" }}>
          <Table.Summary.Cell index={0}>
            <strong>{`Total`}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <strong>
              {whTotal ? commaSeparateNumber(Number(whTotal)) : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}></Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            <strong>
              {dayQtyTotal ? commaSeparateNumber(Number(dayQtyTotal).toFixed(2)) : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            <strong>
              {planWHTotal ? commaSeparateNumber(Number(planWHTotal).toFixed(2)) : 0}
            </strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}></Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            <strong>
              {planQtyTotal ? commaSeparateNumber(Number(planQtyTotal).toFixed(2)) : 0}
            </strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const checkDisabled = () => {
    if(line_watch && shipment_date_watch && start_date_watch) {
      return false;
    }
    else {
      return true;
    }
  }

  return (
    <>
      <Affix offsetTop={0}>
        <Card className="p-fixed">
          <Header
            title={`Plan Entry`}
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Popconfirm
                  title="Confim Save"
                  description="Are you sure to save this plan?"
                  onConfirm={() => onFinish(editMode, id)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <Button
                    size="small"
                    key={2}
                    type="primary"
                    disabled={checkDisabled()}
                  >
                    Save
                  </Button>
                </Popconfirm>
              </Affix>,
            ]}
          />
        </Card>
      </Affix>
      <ProductionPlanningForm editMode={editMode} viewMode={viewMode} />
      <Card>
        <Row gutter={24}>
          <Col span={24} hidden={!isArrayAndHasValue(planningDetails)}>
            <Typography.Title level={5}>Planning Details</Typography.Title>
            <Table
              dataSource={planningDetails}
              columns={planningDetailsColumns}
              bordered
              pagination={true}
              summary={(pageData) => {
                return <TableSummary data={planningDetails} />;
              }}
            />
          </Col>
          <Col span={24} hidden={!isArrayAndHasValue(existingPlan)}>
            <Typography.Title level={5}>Existing Plan</Typography.Title>
            <Table
              dataSource={existingPlan}
              columns={existingPlanColumns}
              bordered
              pagination={false}
            />
          </Col>
          <Col span={24} hidden={!isArrayAndHasValue(overlapPlans)}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 20,
              }}
            >
              Planning Overlapping{" "}
            </Typography.Title>
            <Table
              dataSource={overlapPlans}
              columns={overlapPlansColumns}
              bordered
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
