import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { message, Table, Button } from "antd";
import { hasPermission } from "utils/functions";
import { ExportOutlined } from "@ant-design/icons";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { getData } from "apiServices/common";
import { FOLDING_LIST_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { FOLDING_CREATE_PERMISSION } from "routes/permissions";
import moment from "moment";
import style from "../production.module.css"

const Folding = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Others
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllFolding = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${FOLDING_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting folding data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllFolding();
  }, [refresh, getAllFolding]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const gotoAddRoute = () => {
    navigate("/production/folding/entry");
  };

  const gotoUpdateRoute = (record) => {
    const sales_contract_id = record?.sales_contract_id;
    const style_id = record?.styles?.id;
    const buying_office_id = record?.buying_office_id;
    const folding_date = record?.folding_date
      ? moment(record?.folding_date).format("YYYY-MM-DD")
      : null;
    const transaction_no_per_day = record?.transaction_no_per_day;
    const factory_id = record?.sub_contract_factory_id;

    const updateLink = `/production/folding/update`;

    // Create an object with all the potential query parameters
    const allQueryParams = {
      sales_contract_id,
      style_id,
      buying_office_id,
      folding_date,
      transaction_no_per_day,
      factory_id,
    };

    // Filter out null or undefined values
    const queryParams = Object.fromEntries(
        Object.entries(allQueryParams).filter(
            ([_, value]) => value !== null && value !== undefined
        )
    );

    navigate({
      pathname: updateLink,
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  const tableColumns = [
    {
      title: "Buyer",
      dataIndex: ["buyer_name"],
      key: "buyer_name",
    },
    {
      title: "Style",
      dataIndex: ["styles", "style_no"],
      key: "style_no",
    },
    {
      title: "Folding Date",
      dataIndex: ["folding_date"],
      key: "folding_date",
    },
    {
      title: "Transaction Time",
      dataIndex: ["transaction_no_per_day"],
      key: "transaction_no_per_day",
    },
    {
      title: "Folding Day",
      dataIndex: ["folding_day"],
      key: "folding_day",
    },
    {
      title: "Folding Qty",
      dataIndex: ["folding_qty"],
      key: "folding_qty",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            size="small"
            onClick={() => gotoUpdateRoute(record)}
            icon={<ExportOutlined />}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const foldingQty = (tableData) && tableData.reduce((acc, curr) => acc + curr?.folding_qty, 0)

  const tableTitleContent = (<div className={style?.titleHeader}>
    <div className={style?.totalContainer}>
      <div className={style.totalItem}><span className={style.totalLabel}>Total Folding: </span> {foldingQty}</div>
    </div>
  </div>)

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={gotoAddRoute}
                disabled={!hasPermission([FOLDING_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([FOLDING_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title="Folding"
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllFolding}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="folding_filter"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                title={()=>tableTitleContent}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
                sticky={true}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Folding;
