import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, Row, Col, InputNumber } from "antd";
import { commaSeparateNumber } from "../../../../utils/functions";

const WarehouseForm = (props) => {
  // Props
  const {
    onWarehouseSelect,
    warehouseList,
    onRackSelect,
    getRackList,
    getBinList,
    selectedRecord,
    onRemoveItemFromList,
    isItemUpdate,
    calculateTotal,
  } = props;

  // Antd Constants
  const { Option } = Select;

  return (
    <Row>
      <Col span={24}>
        <Form.List name="tracks">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <Row gutter={6}>
                    <Col span={0}>
                      <Form.Item label="" name={[index, "id"]} hidden />
                    </Col>
                    <Col span={!isItemUpdate ? 4 : 5}>
                      <Form.Item
                        label="Warehouse"
                        name={[index, "warehouse_id"]}
                        required
                      >
                        <Select
                          placeholder="Please choose the Name"
                          onSelect={(warehouseId) =>
                            onWarehouseSelect(warehouseId, field.key)
                          }
                          size="small"
                        >
                          {warehouseList &&
                            warehouseList?.map((item, index) => {
                              return (
                                <Option value={item?.id}>{item?.name}</Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isItemUpdate ? 3 : 4}>
                      <Form.Item
                        label="Rack"
                        name={[index, "rack_id"]}
                        required
                      >
                        <Select
                          placeholder="Please choose the Name"
                          onSelect={(selectedRackId) =>
                            onRackSelect(selectedRackId, field.key)
                          }
                          size="small"
                        >
                          {getRackList(field.key)?.map((item, index) => {
                            return (
                              <Option value={item?.id}>{item?.name}</Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isItemUpdate ? 3 : 4}>
                      <Form.Item label="Bin" name={[index, "bin_id"]} required>
                        <Select
                          placeholder="Please choose the Name"
                          size="small"
                        >
                          {getBinList(field.key)?.map((item, index) => {
                            return (
                              <Option value={item?.id}>{item?.name}</Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isItemUpdate ? 3 : 4}>
                      <Form.Item
                        label="Balance"
                        name={[index, "total_quantity"]}
                      >
                        <InputNumber
                          disabled={!isItemUpdate ? true : false}
                          placeholder="Value"
                          style={{ width: "100%" }}
                          formatter={(value) => commaSeparateNumber(value)}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isItemUpdate ? 3 : 4}>
                      <Form.Item
                        label="Balance Roll"
                        name={[index, "shipment_qty"]}
                      >
                        <InputNumber
                          disabled={!isItemUpdate ? true : false}
                          placeholder="Value"
                          style={{ width: "100%" }}
                          formatter={(value) => commaSeparateNumber(value)}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    {!isItemUpdate && (
                      <>
                        <Col span={!isItemUpdate ? 3 : 4}>
                          <Form.Item
                            label="Roll"
                            name={[index, "assign_shipment_qty"]}
                            required
                          >
                            <InputNumber
                              placeholder="Value"
                              onChange={calculateTotal}
                              style={{ width: "100%" }}
                              formatter={(value) => commaSeparateNumber(value)}
                              precision={2}
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isItemUpdate ? 3 : 4}>
                          <Form.Item
                            label={`Qty in ${selectedRecord?.unit_info?.name}`}
                            name={[index, "assign_qty"]}
                            required
                          >
                            <InputNumber
                              placeholder="Value"
                              onChange={calculateTotal}
                              style={{ width: "100%" }}
                              formatter={(value) => commaSeparateNumber(value)}
                              precision={2}
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col span={!isItemUpdate ? 2 : 3}>
                      <Form.Item label=" ">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <MinusCircleOutlined
                            onClick={() => onRemoveItemFromList(field, remove)}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                {!isItemUpdate && (
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    size="small"
                  >
                    Add field
                  </Button>
                )}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default WarehouseForm;
