import { Table, Typography, message } from "antd";
import moment from "moment";
import { useCallback, useState } from "react";
import {
  DELETE_INVENTORY_MRR_REPORT,
  EXPORT_INVENTORY_MRR_REPORT,
  GET_INVENTORY_MRR_REPORT,
  VIEW_INVENTORY_MRR_REPORT,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const InventoryMRR = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // Antd
  const { Title } = Typography;

  const cutPath = (path) => {
    const updatedPath = path ? path.split("public/inventory_mrr/")[1] : "";
    return updatedPath;
  };

  const getAllMRR = useCallback(async () => {
    setLoading(true);
    const query = `${GET_INVENTORY_MRR_REPORT}`;
    const response = await getData(query);

    if (response?.status === 200) {
      setLoading(false);
      setTableData(response?.data?.data?.files);
    } else {
      setLoading(false);
      message.error("Error getting MRR data");
    }
  }, []);

  const exportMRR = async (filterValues) => {
    if (!Object.values(filterValues).some((val) => val === undefined)) {
      setLoading(true);
      const query = `${EXPORT_INVENTORY_MRR_REPORT}`;
      const { start_date, end_date, supplier_id } = filterValues || {};
      const bodyData = {
        start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
        end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
        supplier_id,
      };
      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "blob" },
        "arraybuffer",
      );

      if (response && response?.data) {
        // Download the file
        const outputFilename = `${String(start_date)
          .split(" ")
          .join("-")}-${String(end_date).split(" ").join("-")}.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
        message.success("Inventry MRR Report Exported Successfully");
        refetch();
      } else {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const previewFunction = async (filterValues) => {
    setLoading(true);
    const { start_date, end_date, supplier_id } = filterValues || {};
    const bodyData = {
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
      supplier_id,
    };

    const query = `${VIEW_INVENTORY_MRR_REPORT}`;
    const response = await postData(query, bodyData);

    if (response?.code === 200) {
      setLoading(false);
      setTableData(response?.data);
    } else {
      setLoading(false);
      message.error("Error getting MRR data");
    }
  };

  // Effects
  // useEffect(() => {
  //   getAllMRR();
  // }, [refresh, getAllMRR]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedMRRReport = async (name) => {
    setLoading(true);
    const updatedName = cutPath(name);
    const query = `${DELETE_INVENTORY_MRR_REPORT}`;
    const bodyData = {
      name: updatedName,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setLoading(false);
      message.success("Inventry MRR Report Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openFile = (name) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/storage/inventory_mrr/${name}`;
    window.open(URL, "_blank", "noreferrer");
  };

  const tableColumns = [
    {
      title: "Inventory MRR Information",
      children: [
        {
          title: "Serial No.",
          dataIndex: "serial",
          key: "serial",
          width: 150,
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: 150,
        },
        {
          title: "Buying Office",
          dataIndex: "buying_office",
          key: "buying_office",
          width: 150,
        },
        {
          title: "Style",
          dataIndex: "style",
          key: "style",
          width: 150,
        },
        {
          title: "Color",
          dataIndex: "color",
          key: "color",
          width: 150,
        },
        {
          title: "Item Category",
          dataIndex: "item_type",
          key: "item_type",
          width: 150,
        },
        {
          title: "Item",
          dataIndex: "item",
          key: "item",
          width: 150,
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          width: 150,
        },
        {
          title: "Challan/Invoice No.",
          dataIndex: "challan_no",
          key: "challan_no",
          width: 150,
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          width: 150,
        },
        {
          title: "Roll/PKG/Bag Qty",
          dataIndex: "shipment_qty",
          key: "shipment_qty",
          width: 150,
        },
      ],
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={exportMRR}
                hideAddButton={true}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Inventory MRR</Title>}
          />
        }
        content={
          <>
            <div className="table" style={{ marginTop: 10 }}>
              <FilterComponent
                tableData={tableData}
                filterAPI={exportMRR}
                filterName={"inventory_mrr"}
                searchButtonText={"Export"}
                defaultOpen
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                isPreview={true}
                previewFunction={previewFunction}
              />
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination
                scroll={{ y: "max-content" }}
                bordered
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default InventoryMRR;
