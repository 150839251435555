import { Alert, Col, Empty, Form, Input, Row, Table } from "antd";
import { postData } from "apiServices/common";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";
import styles from "../appDashboard.module.css";

// Define columns for the table
const columns = [
  { title: "Section", dataIndex: "name", key: "section_name" },
  {
    title: "Operator",
    dataIndex: ["operator_info"],
    key: "operator",
    render: (row, record) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          {record?.operator_user_id} : {record?.operator_name}
        </span>
        {/* <span>
          {record?.position === 1 ||
          record?.position === 2 ||
          record?.position === 3 ? (
            <ArrowUpOutlined style={{ color: "#52c41a" }} />
          ) : (
            <ArrowDownOutlined style={{ color: "#eb2f96" }} />
          )}
        </span> */}
      </div>
    ),
  },
  {
    title: "Production",
    dataIndex: "production_qty",
    key: "production_qty",
  },
  {
    title: "Defect",
    dataIndex: "defect_qty",
    key: "defect_qty",
  },
];

const responseData = {
  code: 200,
  status: "success",
  message: "Data retrieved Successfully",
  data: {
    id: 10,
    name: "Line-11",
    styles: [
      {
        style_no: "469325A",
        id: 736,
        seaction_table: [
          {
            id: 5,
            name: "MAKE SECTION",
            table_datas: [
              {
                daily_line_plan_section_id: 6844,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 273,
                actual: 300,
                vaiance: -27,
                acihive: -9.89010989010989,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6845,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 300,
                actual: 300,
                vaiance: 0,
                acihive: 0,
                position: 2,
              },
              {
                daily_line_plan_section_id: 6846,
                operator_emp_id: "4017859",
                operator_info: {
                  id: 2016,
                  name: "Md. Ibarahim Sarder Easan",
                  phone: "01912-435413",
                  email: "01912435413@cgl.com",
                },
                target: 214,
                actual: 300,
                vaiance: -86,
                acihive: -40.18691588785047,
                position: 3,
              },
              {
                daily_line_plan_section_id: 6848,
                operator_emp_id: "3905095",
                operator_info: {
                  id: 1889,
                  name: "Samsunnahar",
                  phone: "01945-820761",
                  email: "01945820761@cgl.com",
                },
                target: 167,
                actual: 250,
                vaiance: 83,
                acihive: 49.700598802395206,
                position: 18,
              },
              {
                daily_line_plan_section_id: 6859,
                operator_emp_id: "4011296",
                operator_info: {
                  id: 1975,
                  name: "Mst. Nargis Khatun ",
                  phone: "01730-631066",
                  email: "01730631066@cgl.com",
                },
                target: 162,
                actual: 260,
                vaiance: 98,
                acihive: 60.49382716049383,
                position: 17,
              },
              {
                daily_line_plan_section_id: 6861,
                operator_emp_id: "4019157",
                operator_info: {
                  id: 6001,
                  name: "Kakoli",
                  phone: "01607-647108",
                  email: "01607647108@cgl.com",
                },
                target: 194,
                actual: 100,
                vaiance: -94,
                acihive: -48.45360824742268,
                position: 16,
              },
            ],
          },
          {
            id: 1,
            name: "BODY SECTION",
            table_datas: [
              {
                daily_line_plan_section_id: 6844,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 273,
                actual: 300,
                vaiance: -27,
                acihive: -9.89010989010989,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6845,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 300,
                actual: 300,
                vaiance: 0,
                acihive: 0,
                position: 2,
              },
              {
                daily_line_plan_section_id: 6846,
                operator_emp_id: "4017859",
                operator_info: {
                  id: 2016,
                  name: "Md. Ibarahim Sarder Easan",
                  phone: "01912-435413",
                  email: "01912435413@cgl.com",
                },
                target: 214,
                actual: 300,
                vaiance: -86,
                acihive: -40.18691588785047,
                position: 3,
              },
              {
                daily_line_plan_section_id: 6848,
                operator_emp_id: "3905095",
                operator_info: {
                  id: 1889,
                  name: "Samsunnahar",
                  phone: "01945-820761",
                  email: "01945820761@cgl.com",
                },
                target: 167,
                actual: 250,
                vaiance: 83,
                acihive: 49.700598802395206,
                position: 18,
              },
              {
                daily_line_plan_section_id: 6859,
                operator_emp_id: "4011296",
                operator_info: {
                  id: 1975,
                  name: "Mst. Nargis Khatun ",
                  phone: "01730-631066",
                  email: "01730631066@cgl.com",
                },
                target: 162,
                actual: 260,
                vaiance: 98,
                acihive: 60.49382716049383,
                position: 17,
              },
              {
                daily_line_plan_section_id: 6861,
                operator_emp_id: "4019157",
                operator_info: {
                  id: 6001,
                  name: "Kakoli",
                  phone: "01607-647108",
                  email: "01607647108@cgl.com",
                },
                target: 194,
                actual: 100,
                vaiance: -94,
                acihive: -48.45360824742268,
                position: 16,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: 63,
                acihive: 33.51063829787234,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: -63,
                acihive: -33.51063829787234,
                position: 18,
              },
            ],
          },
          {
            id: 3,
            name: "JOIN SECTION",
            table_datas: [
              {
                daily_line_plan_section_id: 6844,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 273,
                actual: 300,
                vaiance: -27,
                acihive: -9.89010989010989,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6845,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 300,
                actual: 300,
                vaiance: 0,
                acihive: 0,
                position: 2,
              },
              {
                daily_line_plan_section_id: 6846,
                operator_emp_id: "4017859",
                operator_info: {
                  id: 2016,
                  name: "Md. Ibarahim Sarder Easan",
                  phone: "01912-435413",
                  email: "01912435413@cgl.com",
                },
                target: 214,
                actual: 300,
                vaiance: -86,
                acihive: -40.18691588785047,
                position: 3,
              },
              {
                daily_line_plan_section_id: 6848,
                operator_emp_id: "3905095",
                operator_info: {
                  id: 1889,
                  name: "Samsunnahar",
                  phone: "01945-820761",
                  email: "01945820761@cgl.com",
                },
                target: 167,
                actual: 250,
                vaiance: 83,
                acihive: 49.700598802395206,
                position: 18,
              },
              {
                daily_line_plan_section_id: 6859,
                operator_emp_id: "4011296",
                operator_info: {
                  id: 1975,
                  name: "Mst. Nargis Khatun ",
                  phone: "01730-631066",
                  email: "01730631066@cgl.com",
                },
                target: 162,
                actual: 260,
                vaiance: 98,
                acihive: 60.49382716049383,
                position: 17,
              },
              {
                daily_line_plan_section_id: 6861,
                operator_emp_id: "4019157",
                operator_info: {
                  id: 6001,
                  name: "Kakoli",
                  phone: "01607-647108",
                  email: "01607647108@cgl.com",
                },
                target: 194,
                actual: 100,
                vaiance: -94,
                acihive: -48.45360824742268,
                position: 16,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: 63,
                acihive: 33.51063829787234,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: -63,
                acihive: -33.51063829787234,
                position: 18,
              },
            ],
          },
          {
            id: 4,
            name: "LADIES",
            table_datas: [
              {
                daily_line_plan_section_id: 6844,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 273,
                actual: 300,
                vaiance: -27,
                acihive: -9.89010989010989,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6845,
                operator_emp_id: "4014558",
                operator_info: {
                  id: 1991,
                  name: "Md. Rofiqul Islam ",
                  phone: "01770-160582",
                  email: "01770160582@cgl.com",
                },
                target: 300,
                actual: 300,
                vaiance: 0,
                acihive: 0,
                position: 2,
              },
              {
                daily_line_plan_section_id: 6846,
                operator_emp_id: "4017859",
                operator_info: {
                  id: 2016,
                  name: "Md. Ibarahim Sarder Easan",
                  phone: "01912-435413",
                  email: "01912435413@cgl.com",
                },
                target: 214,
                actual: 300,
                vaiance: -86,
                acihive: -40.18691588785047,
                position: 3,
              },
              {
                daily_line_plan_section_id: 6848,
                operator_emp_id: "3905095",
                operator_info: {
                  id: 1889,
                  name: "Samsunnahar",
                  phone: "01945-820761",
                  email: "01945820761@cgl.com",
                },
                target: 167,
                actual: 250,
                vaiance: 83,
                acihive: 49.700598802395206,
                position: 18,
              },
              {
                daily_line_plan_section_id: 6859,
                operator_emp_id: "4011296",
                operator_info: {
                  id: 1975,
                  name: "Mst. Nargis Khatun ",
                  phone: "01730-631066",
                  email: "01730631066@cgl.com",
                },
                target: 162,
                actual: 260,
                vaiance: 98,
                acihive: 60.49382716049383,
                position: 17,
              },
              {
                daily_line_plan_section_id: 6861,
                operator_emp_id: "4019157",
                operator_info: {
                  id: 6001,
                  name: "Kakoli",
                  phone: "01607-647108",
                  email: "01607647108@cgl.com",
                },
                target: 194,
                actual: 100,
                vaiance: -94,
                acihive: -48.45360824742268,
                position: 16,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: 63,
                acihive: 33.51063829787234,
                position: 1,
              },
              {
                daily_line_plan_section_id: 6862,
                operator_emp_id: "4011207",
                operator_info: {
                  id: 1103,
                  name: "Mohammad Rezaul",
                  phone: "01706-938457",
                  email: "01706938457@cgl.com",
                },
                target: 188,
                actual: 125,
                vaiance: -63,
                acihive: -33.51063829787234,
                position: 18,
              },
            ],
          },
        ],
        buyer_info: null,
      },
    ],
  },
};

export default function OperatorWiseDefect({ line_id = 14 }) {
  const formattedTime = moment().format("h:mm A");
  const formattedDate = moment().format("DD-MMM-YYYY");

  const [records, setRecords] = useState([]);
  const [recordsLine, setRecordsLine] = useState("");

  const fetchRecords = useCallback(async (filterValues) => {
    const query = `/api/digitalization/bigscreen-dashboard/operator-defects`;

    const response = await postData(query, { line_id: line_id });

    if (response && response?.code === 200) {
      const data = response?.data;
      let resData = isArrayAndHasValue(data) ? [...data] : [];
      setRecordsLine(response?.data?.[0]?.line?.name);
      setRecords(resData);
    }
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <div className={styles.appProductionDashboard} style={{ padding: "20px" }}>
      {records.length > 0 ? (
        records.map((item, index) => (
          <div style={{ marginTop: "16px" }} key={index}>
            {index === 0 && (
              <Form layout="vertical" size="small">
                <Row gutter={[8, 2]} justify="end">
                  <Col xs={24} sm={24} md={24} lg={13} xl={18}>
                    <h2>Operator Wise Defect</h2>
                  </Col>
                  <Col xs={24} sm={10} lg={4} xl={4} xxl={2}>
                    <Form.Item label="Date">
                      <Input width={80} value={formattedDate} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={3} xl={4} xxl={2}>
                    <Form.Item label="Time">
                      <Input width={80} value={formattedTime} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} lg={4} xl={4} xxl={2}>
                    <Form.Item label="Line">
                      <Input width={80} value={recordsLine} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}

            <Table
              dataSource={item?.section_data?.flatMap((section) =>
                section?.operator_data.map((data) => ({
                  ...data,
                  name: section.name,
                })),
              )}
              columns={columns}
              pagination={false}
              bordered
              rowKey="id"
              size="small"
            />
          </div>
        ))
      ) : (
        <Empty
          style={{ width: "33%", margin: "auto" }}
          description={
            <Alert
              message="No Data Found with selected line-id in Operator Wise Defect"
              type="warning"
            />
          }
        />
      )}
    </div>
  );
}
