import { Button, Collapse, Form, Layout, Space } from "antd";
import {
  EDIT_CUTTING_PRODUCT_ENDPOINT,
  GET_CUTTING_PRODUCT_ENDPOINT,
} from "apiServices/API_ENDPOINTS";
import { postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import AppPageHeader from "common/PageHeader";
import InventoryLayOutCommon from "components/InventoryFabric/LayOut/InventoryLayOutCommon";
import { CuttingContext } from "context/CuttingContext/CuttingContextProvider";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { CUTTING_EDIT_PERMISSION } from "routes/permissions";
import { hasPermission, isArrayAndHasValue } from "utils/functions";
import CuttingForm from "../CreateForm/CuttingForm";
import CuttingDetails from "../CuttingDetails/CuttingDetails";

const { Content } = Layout;
const { Panel } = Collapse;

const UpdateCutting = (props) => {
  // Contexts
  const { cuttingTableData, getStyleList, setCuttingTableData, setShowFactoryInputs, subContractStatus, setSubContractStatus } =
    useContext(CuttingContext);
  const [isSubmit, setIsSubmit] = useState(false);

  // Antd
  const [form] = Form.useForm();

  // Router
  const navigate = useNavigate();
  const location = useLocation();

  const getCuttingProductInfo = useCallback(
    async ({
      sales_contract_id,
      style_id,
      buying_office_id,
      cutting_date,
      transaction_no_per_day,
      factory_id
    }) => {
      if(buying_office_id) {
        setShowFactoryInputs(false);
      } else if(factory_id) {
        setShowFactoryInputs(true);
      }
      const bodyData = {
        sales_contract_id: sales_contract_id ? Number(sales_contract_id) : null,
        style_id: style_id ? Number(style_id) : null,
        buying_office_id: buying_office_id ? Number(buying_office_id) : null,
        factory_id: factory_id ? Number(factory_id) : null,
        cutting_date: cutting_date
          ? moment(cutting_date).format("YYYY-MM-DD")
          : null,
        transaction_no_per_day: transaction_no_per_day
          ? Number(transaction_no_per_day)
          : null,
      };

      const query = `${GET_CUTTING_PRODUCT_ENDPOINT}`;

      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        setSubContractStatus(response?.data?.form_data?.subcontract_status)
        // Get searched style list for dropdown
        getStyleList({ style_no: response?.data?.form_data?.style_no });

        // Set form data
        form.setFieldsValue({
          style_id:
            response?.data?.form_data?.active_sales_contract_map_info?.style_id,
          cutting_date: cutting_date ? moment(cutting_date) : moment(),
          buying_office_id: response?.data?.form_data?.buying_office_info?.id,
          buying_office_name:
            response?.data?.form_data?.buying_office_info?.name,
          buyer_id: response?.data?.form_data?.buyer_info?.id,
          buyer_name: response?.data?.form_data?.buyer_info?.name,
          factory_id: response?.data?.form_data?.factory_info?.id,
          factory_name: response?.data?.form_data?.factory_info?.name,
          address: response?.data?.form_data?.factory_info?.address,
          sales_contract_id:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.id,
          sales_contract_name:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.reference_no,
          order_quantity: response?.data?.form_data
            ?.active_sales_contract_map_info?.order_quantity
            ? Number(
                response?.data?.form_data?.active_sales_contract_map_info
                  ?.order_quantity,
              )
            : null,
          use_both_style_po:
            response?.data?.form_data?.active_sales_contract_map_info
              ?.active_sales_contract_info?.use_both_style_po,
        });

        // Set cutting details table data
        setCuttingTableData(
          isArrayAndHasValue(response?.data?.table_data)
            ? response?.data?.table_data
            : null,
        );
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    },
    // Ignored dependencies to stop loop
    [form, setCuttingTableData, getStyleList],
  );

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const queryParamsObj = {
      sales_contract_id: searchParams.get("sales_contract_id"),
      style_id: searchParams.get("style_id"),
      buying_office_id: searchParams.get("buying_office_id"),
      cutting_date: searchParams.get("cutting_date"),
      transaction_no_per_day: searchParams.get("transaction_no_per_day"),
      factory_id: searchParams.get("factory_id"),
    };

    if (queryParamsObj) {
      getCuttingProductInfo(queryParamsObj);
    }
  }, [location.search, getCuttingProductInfo]);

  const goBackToCuttingRoute = () => {
    navigate("/production/cutting");
  };

  const extra = (
    <Space>
      <Button size="small" onClick={goBackToCuttingRoute}>
        Back
      </Button>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        style={{ marginRight: 5 }}
        onClick={() => form.submit()}
        disabled={!hasPermission([CUTTING_EDIT_PERMISSION]) || isSubmit}
      >
        {hasPermission([CUTTING_EDIT_PERMISSION]) ? "Update" : "No Permission"}
      </Button>
    </Space>
  );

  const onFinishUpdate = async (values) => {
    setIsSubmit(true);
    const cuttingProducts =
      isArrayAndHasValue(cuttingTableData) &&
      cuttingTableData?.map((item) => {
        return {
          cutting_product_id: item?.cutting_products?.cutting_product_id,
          history_id: item?.cutting_products?.history_id,
          internal_po: item?.internal_po,
          original_po: item?.original_po,
          po_map_id: item?.po_map_id,
          color_id: item?.color?.id,
          sub_style_id: item?.sub_style_info?.id,
          size_id: item?.size?.id,
          cutting_piece: item?.cutting_products?.cutting_piece,
          remain_piece: item?.cutting_products?.remain_piece,
          cutting_day: item?.cutting_products?.cutting_day,
          transaction_no_per_day:
            item?.cutting_products?.transaction_no_per_day,
        };
      });

    const query = `${EDIT_CUTTING_PRODUCT_ENDPOINT}`;
    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      buying_office_id: values?.buying_office_id || null,
      style_id: values?.style_id,
      cutting_date: values?.cutting_date,
      use_both_style_po: values?.use_both_style_po,
      buyer_id: values?.buyer_id || null,
      cutting_products: cuttingProducts,
      subcontract_status: subContractStatus,
      sub_contract_factory_id: values?.factory_id || null,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Cutting updated successfully",
        null,
      );
      // setIsSubmit(false);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
      setIsSubmit(false);
    }
  };

  const formSubmitting = () => {
    setIsSubmit(false);
  };

  return (
    <>
      <InventoryLayOutCommon>
        <AppPageHeader title={"Cutting Update"} extra={extra} />
        <Content className="item-details">
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 10 }}>
            <Panel header={<b>Order Details</b>} key="1">
              <Form layout="vertical" onFinish={onFinishUpdate} form={form}>
                <CuttingForm
                  form={form}
                  isUpdate={true}
                  formSubmitting={formSubmitting}
                />
              </Form>
            </Panel>
          </Collapse>
          {/* Cutting Details Table */}
          <CuttingDetails />
        </Content>
      </InventoryLayOutCommon>
    </>
  );
};

export default UpdateCutting;
