import { Form } from "antd";
import {
  GET_INQUIRY_STYLE_DETAILS,
  GET_LINE_LIST,
  GENERATE_PLANNING_DETAILS,
  REGENERATE_PLAN,
  PLAN_INITIATE,
  BUYER_INQUIRY_LIST_ENDPOINT,
  GET_STYLE_LIST_ENDPOINT,
  SUBSTYLE_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import HandleNotification from "common/Notification";
import moment from "moment";
import { createContext, useCallback, useEffect, useState } from "react";
import { isArrayAndHasValue } from "utils/functions";

export const ProductionPlanningContext = createContext();

const ProductionPlanningContextProvider = ({ children }) => {
  // States
  const [planningDetails, setPlanningDetails] = useState(null);
  const [existingPlan, setExistingPlan] = useState(null);
  const [overlapPlans, setOverlapPlans] = useState(null);
  const [planningOverlapping, setPlanningOverlapping] = useState([{}]);
  const [inquiryStyleDetails, setInquiryStyleDetails] = useState({});
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [options, setOptions] = useState({
    lines: [],
    inquiryList: [],
    styleList: [],
    subStyleList: [],
    po: [],
  });
  // order_status
  const order_status = {
    0: "Inquiry",
    1: "Contracted",
    2: "Closed",
  };

  // Antd
  const [form] = Form.useForm();
  const inquiry_id_watch = Form.useWatch("inquiry_id", form);
  const style_id_watch = Form.useWatch("style_id", form);

  // get GET_LINE_LIST
  const getLineList = useCallback(async (filterValues) => {
    try {
      const payload = {
        ...filterValues,
      }
      const response = await getData(GET_LINE_LIST, false, payload);
      if (response.status === 200) {
        const lines = response.data?.data?.data?.map((line) => ({
          value: line.id,
          label: line.name,
        }));
        setOptions((prev) => ({ ...prev, lines }));
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Error getting line data', null)
    }
  }, []);

  // Get inquiry list data
  const getInquiryList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
      }
      const response = await getData(BUYER_INQUIRY_LIST_ENDPOINT, false, bodyData);
      
      if (response && response?.data?.code === 200) {
        const inquiryList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, inquiryList }));
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Error getting inquiry data', null)
    }
  }, []);

  // Get style list data
  const getStyleList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
        active_sales_contract: true,
      }
      const response = await getData(GET_STYLE_LIST_ENDPOINT, false, bodyData);
      
      if (response && response?.data?.code === 200) {
        const styleList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, styleList }));
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Error getting style data', null)
    }
  }, []);

  // Get style list data
  const getSubStyleList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
      }
      const response = await getData(SUBSTYLE_LIST, false, bodyData);
      
      if (response && response?.data?.code === 200) {
        const subStyleList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, subStyleList }));
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Error getting sub style data', null)
    }
  }, []);

  // First Time render set the options
  useEffect(() => {
    form.setFieldsValue({
      apply_learning_curve: true,
    });
    getLineList({per_page: 10000});
    getInquiryList();
    getStyleList();
    getSubStyleList();
  }, [getLineList, getInquiryList, getStyleList, getSubStyleList, form]);

  // on change Inquiry & Style
  const onInquiryAndStyleChange = useCallback(
    async (props, from) => {
      const {
        inquiry_id_prop,
        style_id_prop,
        sub_style_id_prop,
      } = props;
      try {
        const line_id = form.getFieldValue("line");
        const style_id = form.getFieldValue("style_id");
        const inquiry_id = form.getFieldValue("inquiry_id");
        const sub_style_id = form.getFieldValue("sub_style_id");

        let payload = {
          line_id: line_id,
        }

        if(inquiry_id_prop) {
          payload = {
            ...payload,
            inquiry_id: inquiry_id_prop,
          }
        }
        if(style_id_prop || sub_style_id_prop) {
          payload = {
            ...payload,
            style_id: style_id_prop || style_id,
            sub_style_id: sub_style_id_prop || sub_style_id,
          }
        }

        const API_ENDPOINTS = `${GET_INQUIRY_STYLE_DETAILS}`;
        const response = await getData(API_ENDPOINTS, false, payload);

        if (response.status === 200) {
          const data = response.data?.data;
          const obData = data?.operation_bulletin_info

          if(obData?.message) {
            HandleNotification('error', 'bottomRight', obData?.message, null);
          }
          if(!obData) {
            HandleNotification('error', 'bottomRight', "OB data not found", null);
          }

          // set po options
          setOptions((prev) => ({
            ...prev,
            po: data?.sales_contract_po?.map((po) => ({
              ...po,
              value: po?.id,
              label: po?.original_po,
            })),
          }));
          const dataMake = {
            ...data,
          };

          if(from !== "edit_mode") {
            if(data?.inquiry_details && data?.inquiry_details?.id) {
              // If inquiry status is Contracted (1)
              if(data?.inquiry_details?.inquiry_status === 1) {
                // If any PO is selected
                if(isArrayAndHasValue(data?.sales_contract_po)) {
                  dataMake.shipment_date = data?.sales_contract_po[0]?.shipment_date ? moment(data?.sales_contract_po[0]?.shipment_date) : null;
                }
                else {
                  dataMake.shipment_date = data?.inquiry_details?.expected_delivery_date ? moment(data?.inquiry_details?.expected_delivery_date) : null;
                }
              }
              // If inquiry status is Inquiry (0)
              if(data?.inquiry_details?.inquiry_status === 0) {
                dataMake.shipment_date = data?.inquiry_details?.expected_delivery_date ? moment(data?.inquiry_details?.expected_delivery_date) : null;
              }
            }
            else {
              // When inquiry not found
              // Active sales contract found and has selected PO
              if(data?.sales_contract?.id && isArrayAndHasValue(data?.sales_contract_po)) {
                dataMake.shipment_date = data?.sales_contract_po[0]?.shipment_date ? moment(data?.sales_contract_po[0]?.shipment_date) : null;
              }
              else {
                dataMake.shipment_date = null;
              }
            }
          }

          console.log('dataMake', dataMake);
          

          // set form data
          form.setFieldsValue({
            ...dataMake,
            order_status_name: order_status[parseInt(data.order_status)],
            ob_id: obData?.id,
            use_both_style_po: data?.sales_contract?.use_both_style_po,
            inquiry_id: dataMake?.is_inquiry_id ? dataMake?.inquiry_details?.id : null,
            inquiry_id_when_not_selected: !dataMake?.is_inquiry_id ? dataMake?.inquiry_details?.id : null,
          });
          // set inquiry style details
          setInquiryStyleDetails((prev) => ({ ...prev, ...dataMake }));
        }
      } catch (error) {
        HandleNotification('error', 'bottomRight', 'Error getting data', null)
      }
    },
    [form],
  );

  // on change PO set Shipment Date
  const updateShipmentDate = useCallback(
    (value) => {
      if (value) {
        if (inquiryStyleDetails && inquiryStyleDetails?.inquiry_id) {
          if (inquiryStyleDetails?.order_status === "1") {
            const val = value?.delivery_date
              ? moment(value?.delivery_date)
              : "";
            form.setFieldsValue({
              shipment_date: val,
            });
            setInquiryStyleDetails((prev) => ({
              ...prev,
              shipment_date: val,
            }));
          }
        } else if (
          inquiryStyleDetails &&
          inquiryStyleDetails?.inquiry_id === null
        ) {
          const val = value?.delivery_date ? moment(value?.delivery_date) : "";
          form.setFieldsValue({
            shipment_date: val,
          });
          setInquiryStyleDetails((prev) => ({
            ...prev,
            shipment_date: val,
          }));
        } else {
          form.setFieldsValue({
            shipment_date: "",
          });
          setInquiryStyleDetails((prev) => ({
            ...prev,
            shipment_date: "",
          }));
        }
      } else {
        if (inquiryStyleDetails && inquiryStyleDetails?.inquiry_id) {
          if (
            inquiryStyleDetails?.order_status === "0" ||
            inquiryStyleDetails?.order_status === "1"
          ) {
            const val = inquiryStyleDetails?.inquiry_details
              ?.expected_delivery_date
              ? moment(
                  inquiryStyleDetails?.inquiry_details?.expected_delivery_date,
                )
              : "";

            form.setFieldsValue({
              shipment_date: val,
            });
            setInquiryStyleDetails((prev) => ({
              ...prev,
              shipment_date: val,
            }));
          } else {
            form.setFieldsValue({
              shipment_date: "",
            });
            setInquiryStyleDetails((prev) => ({
              ...prev,
              shipment_date: "",
            }));
          }
        } else {
          form.setFieldsValue({
            shipment_date: "",
          });
          setInquiryStyleDetails((prev) => ({ ...prev, shipment_date: "" }));
        }
      }
    },
    [form, inquiryStyleDetails],
  );

  const onChangeInquiryStyleDetails = useCallback(
    ({ name, value }) => {
      // on change PO set Shipment Date
      if (name === "sales_contract_po_id") {
        updateShipmentDate(value);
      }
      setInquiryStyleDetails((prev) => ({ ...prev, [name]: value }));
    },
    [inquiryStyleDetails],
  );

  const onGenerate = useCallback(async (values) => {
    const payload = {
      line_id: values.line || null,
      inquiry_id: values?.inquiry_id || values?.inquiry_id_when_not_selected || null,
      style_id: values?.style_id || null,
      ob_id: values.ob_id || null,
      start_date: values.start_date ? moment(values.start_date).format("YYYY-MM-DD") : null,
      sales_contact_id: values.sales_contract.id || null,
      order_qty: values?.order_qty,
    };

    const query = `${GENERATE_PLANNING_DETAILS}`;
    const response = await getData(query, false, payload);

    if(response && response?.data?.code === 200) {
      const planningData = response?.data?.data?.planning_details?.days;
      const customPlanningData = isArrayAndHasValue(planningData) && planningData?.map((item) => {
        return {
          ...item,
          plan_curve_mh: item?.working_hours,
          plan_curve_efficiency: item?.efficiency,
          plan_curve_qty: item?.dayQty,
        };
      });

      if(!isArrayAndHasValue(planningData)) {
        HandleNotification('error', 'bottomRight', 'Planning data not found', null);
        return;
      }

      setPlanningDetails(customPlanningData);
      setExistingPlan(response?.data?.data?.existing_plan);
      setOverlapPlans(response?.data?.data?.overlap_plans);
    }
    else {
      HandleNotification('error', 'bottomRight', 'Error generating data', null) 
    }
  }, []);

  const calculateRemainingQty = (data) => {
    const { order_qty, plan_qty, actual_qty, start_date } = data;
    const planQty = plan_qty || 0;
    const actualQty = actual_qty || 0;
    const current_date = moment();
    const isPlanGreaterCurrentDate = moment(start_date).isAfter(current_date);
    const isCurrentGreaterEqualPlanDate = moment(current_date).isSameOrAfter(start_date);

    const remainingQty = order_qty - (isPlanGreaterCurrentDate && planQty) - (isCurrentGreaterEqualPlanDate && actualQty);

    return remainingQty || 0;
  }

  const onFinish = useCallback(async (editMode, id) => {
    // Form values
    const formValues = form.getFieldsValue();
    const order_qty_form_value = form.getFieldValue("order_qty");

    const totalPlanCurveQty = planningDetails?.reduce((acc, current) => {
      return acc + current.plan_curve_qty;
    }, 0);

    // Show error if totalPlanCurveQty is greater than order_qty_form_value
    if(totalPlanCurveQty > order_qty_form_value) {
      HandleNotification('error', 'bottomRight', 'Total Plan Qty is greater than Order Qty. Reduce the input value.', null);
      return;
    }

    const query = editMode ? `${PLAN_INITIATE}/${id}` : `${PLAN_INITIATE}`;
    const payload = {
      "line_id": formValues?.line,
      "inquiry_id": formValues?.inquiry_id || formValues?.inquiry_id_when_not_selected || null,
      "style_id": formValues?.style_id || null,
      "sub_style_id": formValues?.sub_style_id || null,
      "buyer": formValues?.buyer?.id,
      "buying_office": formValues?.buying_office?.id,
      "sales_contract_id": formValues?.sales_contract?.id || null,
      "po_map_id": formValues?.sales_contract_po_id || null,
      "order_qty" : formValues?.order_qty,
      "pcd" : formValues?.pcd,
      "shipment_date": formValues?.shipment_date ? moment(formValues?.shipment_date).format("YYYY-MM-DD") : null,
      "sam": formValues?.smv,
      "mp": formValues?.no_of_workers,
      "hourly_production": formValues?.per_hour_target,
      "order_status": formValues?.order_status_name || null,
      "start_date": formValues?.start_date ? moment(formValues?.start_date).format("YYYY-MM-DD") : null,
      "end_date": isArrayAndHasValue(planningDetails) ? moment(planningDetails[planningDetails.length - 1].date).format("YYYY-MM-DD") : null,
      "is_apply_learning_curve": formValues?.apply_learning_curve ? 1 : 0,
      "planned_qty": formValues?.plan_qty,
      "actual_qty": formValues?.actual_qty,
      "remaining_qty": calculateRemainingQty(formValues),
      "planned_mh": formValues?.planned_mh || null,
      "actual_mh": formValues?.actual_mh || null,
      "factory_id": formValues?.sub_contract_factory_id || null,
      "details": isArrayAndHasValue(planningDetails) && planningDetails?.map((item, index) => {
        return {
          "day_id": item?.day,
          "details_date": item?.date,
          "learning_curve_mh": item?.working_hours || item?.learning_curve_mh || 0,
          "learning_curve_efficiency":  item?.efficiency || item?.learning_curve_efficiency || 0,
          "learning_curve_qty": item?.dayQty || item?.learning_curve_qty || 0,
          "plan_curve_mh": item?.plan_curve_mh,
          "plan_curve_efficiency": item?.plan_curve_efficiency,
          "plan_curve_qty": item?.plan_curve_qty,
        };
      })
    };

    const response = editMode ? await putData(query, payload) : await postData(query, payload);

    if(response && response?.code === 200) {
      HandleNotification('success', 'bottomRight', `Plan ${editMode ? "updated" : "initiated"} successfully`, null)
    }
    else {
      HandleNotification('error', 'bottomRight', 'Error initiating plan', null)
    }
  }, [form, planningDetails]);

  const onDeletePlan = (day) => {
    const newPlanningDetails = isArrayAndHasValue(planningDetails) && planningDetails?.filter((item) => item.day !== day);
    setPlanningDetails(newPlanningDetails);
  };

  const onEditPlan = (day) => {
    const newPlanningDetails = isArrayAndHasValue(planningDetails) && planningDetails?.map((item) => {
      if (item.day === day) {
        return {
          ...item,
          isEnableField: item?.isEnableField ? false : true,
        };
      }
      return item;
    });
    setPlanningDetails(newPlanningDetails);
  };

  const onChangePlanValue = (day, value, context) => {
    const newPlanningDetails = isArrayAndHasValue(planningDetails) && planningDetails?.map((item) => {
      if (item?.day === day) {
        let plan_curve_mh_value = value || item?.plan_curve_mh;
        if(context === "plan_curve_efficiency") {
          plan_curve_mh_value = item?.plan_curve_qty/value;
        }
        if(context === "plan_curve_qty") {
          plan_curve_mh_value = value/item?.plan_curve_efficiency;
        }

        let plan_curve_efficiency_value = item?.plan_curve_efficiency;
        if(context === "plan_curve_mh") {
          plan_curve_efficiency_value = item?.plan_curve_qty/value;
        }
        if(context === "plan_curve_efficiency") {
          plan_curve_efficiency_value = value;
        }

        return {
          ...item,
          [context]: value,
          edited: true,
          plan_curve_efficiency: plan_curve_efficiency_value,
          plan_curve_mh: plan_curve_mh_value,
          plan_curve_qty: context === "plan_curve_qty" ? value : item?.plan_curve_qty,
        };
      }
      return item;
    });
    setPlanningDetails(newPlanningDetails);
  }

  const planDataChangeOnLC = (value) => {
    const planningDetailsCopy = isArrayAndHasValue(planningDetails) ? [...planningDetails] : [];
    const maxValueWH = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.working_hours ? acc : current.working_hours);
    });
    const maxValueEfficiency = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.efficiency ? acc : current.efficiency);
    });
    const maxValueDayQty = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.dayQty ? acc : current.dayQty);
    });

    if(value) {
      planningDetailsCopy.forEach((item) => {
        item.plan_curve_mh = item.working_hours;
        item.plan_curve_efficiency = item.efficiency
        item.plan_curve_qty = item.dayQty
      });
      
    }
    else {
      planningDetailsCopy.forEach((item) => {
        item.plan_curve_mh = maxValueWH;
        item.plan_curve_efficiency = maxValueEfficiency;
        item.plan_curve_qty = maxValueDayQty;
      });
    }

    setPlanningDetails(planningDetailsCopy);
  }

  const regenerateOnline = async(data) => {
    // Get values from form
    const order_qty_form_value = form.getFieldValue("order_qty");
    const start_date = form.getFieldValue("start_date");

    // Calculate remaining qty
    const totalPlanCurveQty = planningDetails?.reduce((acc, current) => {
      return acc + current.plan_curve_qty;
    }, 0);
    const qty_left_to_add = order_qty_form_value - totalPlanCurveQty;

    const query = `${REGENERATE_PLAN}`;
    const payload = {
      day: data.day,
      plan_start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      date: data.date,
      plan_curve_mh: data.plan_curve_mh,
      plan_curve_efficiency: data.plan_curve_efficiency,
      plan_curve_qty: data.plan_curve_qty,
      remaining_qty: qty_left_to_add,
    };
    
    const response = await getData(query, false, payload);

    if(response && response?.status === 200) {
      const planningXtraDays = isArrayAndHasValue(response?.data?.days) ? response?.data?.days : [];
      const overlapPlans = isArrayAndHasValue(response?.data?.overlap_plans) ? response?.data?.overlap_plans : [];
      setPlanningDetails([...planningDetails, ...planningXtraDays]);
      setOverlapPlans(overlapPlans);
    }
    else {
      HandleNotification('error', 'bottomRight', 'Error regenerating plan', null)
    }
  }

  const onRegenerate = (record) => {
    const planningDetailsCopy = isArrayAndHasValue(planningDetails) ? [...planningDetails] : [];
    const foundDayIndex = planningDetailsCopy.findIndex((item) => item.day === record.day);
    const order_qty_form_value = form.getFieldValue("order_qty");

    const totalPlanCurveQty = planningDetailsCopy?.reduce((acc, current) => {
      return acc + current.plan_curve_qty;
    }, 0);

    const qty_left_to_add = order_qty_form_value - totalPlanCurveQty;

    // Show error if totalPlanCurveQty is greater than order_qty_form_value
    if(totalPlanCurveQty > order_qty_form_value) {
      HandleNotification('error', 'bottomRight', 'Total Plan Qty is greater than Order Qty. Reduce the input value.', null);
      return;
    }

    // check if the foundIndex is the last index of the array
    if(foundDayIndex === planningDetailsCopy.length - 1) {
      regenerateOnline(record);
    }
    else {
      const plan_curve_qty_value = planningDetailsCopy[foundDayIndex+1]["plan_curve_qty"] + qty_left_to_add;
      const plan_curve_mh_value = plan_curve_qty_value/planningDetailsCopy[foundDayIndex+1]["plan_curve_efficiency"];
      planningDetailsCopy[foundDayIndex+1]["plan_curve_qty"] = plan_curve_qty_value;
      planningDetailsCopy[foundDayIndex+1]["plan_curve_mh"] = plan_curve_mh_value;
    
      // Disable field and set edited to false
      planningDetailsCopy[foundDayIndex]["edited"] = false;
      planningDetailsCopy[foundDayIndex]["isEnableField"] = false;
      setPlanningDetails(planningDetailsCopy);
    }
  }

  const getPlanDetails = useCallback(async(id) => {
    const query = `${PLAN_INITIATE}/${id}/show`;
    const response = await getData(query);

    if(response && response?.data?.code === 200) {
      setSelectedPlanDetails(response?.data?.data);
    }
    else {
      HandleNotification('error', 'bottomRight', 'Error getting data', null) 
    }
  }, []);

  const onSelectBuyerInquiry = (value) => {
    const foundItem = options?.inquiryList?.find((item) => item.id === value);
    const styleId = foundItem?.style_info?.id;
    const styleObject = foundItem?.style_info;

    console.log('foundItem', foundItem);
    

    const subStyleList = isArrayAndHasValue(styleObject?.sub_style) ? styleObject?.sub_style : [];
    setOptions((prev) => ({ ...prev, subStyleList }));

    const isExistsStyle = options?.styleList?.find((item) => item?.id === styleObject?.id);
    
    if(!isExistsStyle && styleObject) {
      const styleList = isArrayAndHasValue(options?.styleList) ? [...options?.styleList, styleObject] : [{styleObject}];
      setOptions((prev) => ({ ...prev, styleList }));
    }

    if (styleId) {
      // Set style id in form
      form.setFieldsValue({
        style_id: styleId,
      });

      // Change other fields based on selected style
      onSelectStyle(styleId, value);
    } else {
      console.log('else e gese');
      
      // Reset style id field
      form.resetFields(["style_id"]);

      // Set data to form fields
      form.setFieldsValue({
        buying_office: {
          id: foundItem?.buying_office_info?.id,
          name: foundItem?.buying_office_info?.name,
        },
        buyer: {
          id: foundItem?.buyer_info?.id,
          name: foundItem?.buyer_info?.name,
        },
        description: foundItem?.description,
        subcontract_status: foundItem?.subcontract_status ? 1 : 0,
        sub_contract_factory_id:
          foundItem?.sub_contract_factory_id ||
          foundItem?.factory_info?.id ||
          null,
        factory_name: foundItem?.factory_info?.name || null,
      });
    }
  };

  const onSelectStyle = (value, inquiry_id) => {
    const foundStyle = options?.styleList?.find((style) => style.id === value);
    const subStyles = foundStyle?.sub_style;

    if(!inquiry_id_watch) {
      const subStyleList = isArrayAndHasValue(subStyles) ? subStyles : [];
      setOptions((prev) => ({ ...prev, subStyleList }));
    }

    console.log('inquiry_id', inquiry_id);
    console.log('style inquiry_id', foundStyle?.inquiry_id);

    

    form.setFieldsValue({
      inquiry_id: inquiry_id || foundStyle?.inquiry_id || null,
      buying_office: {
        id: foundStyle?.buying_office_info?.id,
        name: foundStyle?.buying_office_info?.name,
      },
      buyer: {
        id: foundStyle?.buyer_info?.id,
        name: foundStyle?.buyer_info?.name,
      },
      sales_contract: {
        id: foundStyle?.active_sales_contract_map_info
          ?.active_sales_contract_info?.id,
        sales_contract_no:
          foundStyle?.active_sales_contract_map_info?.active_sales_contract_info
            ?.reference_no,
      },
      description: foundStyle?.description,
      sub_style_id: null,
      subcontract_status: foundStyle?.subcontract_status ? 1 : 0,
      sub_contract_factory_id:
        foundStyle?.sub_contract_factory_id ||
        foundStyle?.factory_info?.id ||
        null,
      factory_name: foundStyle?.factory_info?.name || null,
    });
  };

  const onSelectSubStyle = (value) => {
    const foundSubStyle = options?.subStyleList?.find((item) => item?.id === value);
    const foundStyleId = foundSubStyle?.style_info?.id;
    const isExistsStyle = options?.styleList?.find((item) => item?.id === foundStyleId);

    if(style_id_watch) {
      return;
    }

    if(!isExistsStyle) {
      const styleList = isArrayAndHasValue(options?.styleList) ? [...options?.styleList, foundSubStyle?.style_info] : [foundSubStyle?.style_info];
      setOptions((prev) => ({ ...prev, styleList }));
    }

    form.setFieldsValue({
      style_id: foundStyleId,
      buying_office: {
        id: foundSubStyle?.style_info?.buying_office_info?.id,
        name: foundSubStyle?.style_info?.buying_office_info?.name,
      },
      buyer: {
        id: foundSubStyle?.style_info?.buyer_info?.id,
        name: foundSubStyle?.style_info?.buyer_info?.name,
      },
      sales_contract: {
        id: foundSubStyle?.style_info?.active_sales_contract_map_info
          ?.active_sales_contract_info?.id,
        sales_contract_no:
          foundSubStyle?.style_info?.active_sales_contract_map_info
            ?.active_sales_contract_info?.reference_no,
      },
      description: foundSubStyle?.style_info?.description,
      inquiry_id: foundSubStyle?.style_info?.inquiry_id,
      subcontract_status: foundSubStyle?.style_info?.subcontract_status ? 1 : 0,
      sub_contract_factory_id:
        foundSubStyle?.style_info?.sub_contract_factory_id ||
        foundSubStyle?.style_info?.factory_info?.id ||
        null,
      factory_name: foundSubStyle?.style_info?.factory_info?.name || null,
    });
  }

  return (
    <ProductionPlanningContext.Provider
      value={{
        form,
        options,
        setOptions,
        onGenerate,
        onFinish,
        planningDetails,
        setPlanningDetails,
        existingPlan,
        setExistingPlan,
        overlapPlans,
        setOverlapPlans,
        planningOverlapping,
        setPlanningOverlapping,
        onInquiryAndStyleChange,
        inquiryStyleDetails,
        onChangeInquiryStyleDetails,
        onDeletePlan,
        onEditPlan,
        onChangePlanValue,
        planDataChangeOnLC,
        onRegenerate,
        getPlanDetails,
        selectedPlanDetails,
        getInquiryList,
        getStyleList,
        getSubStyleList,
        onSelectBuyerInquiry,
        onSelectStyle,
        onSelectSubStyle,
      }}
    >
      {children}
    </ProductionPlanningContext.Provider>
  );
};

export default ProductionPlanningContextProvider;
