import {
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Affix, Button, Card, Dropdown, Menu, Space, Table } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PURCHAS_ORDER_LIST } from "../../apiServices/API_ENDPOINTS";
import { getData } from "../../apiServices/common";
import {
  PURCHASE_ORDER_CREATE_PERMISSION,
  PURCHASE_ORDER_EDIT_PERMISSION,
  PURCHASE_ORDER_VIEW_PERMISSION,
} from "../../routes/permissions";
import { hasPermission } from "../../utils/functions";
import { isMobileView } from "../Common/AppHelper";
import CustomFilter from "../Common/CustomFilter";
import Header from "../Common/Header";

const PurchaseOrder = (props) => {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const formatNumberWithCommas = (number) => {
    return Number(parseFloat(number).toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      title: "Buying Office Name",
      dataIndex: ["buying_office", "name"],
      key: "buying_office_name",
    },
    {
      title: "Buyer Name",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
    },
    {
      title: "Reference No.",
      dataIndex: ["sales_contract", "reference_no"],
      key: "buyer_name",
    },
    {
      title: "Factory Name",
      dataIndex: ["sub_contract_factory", "name"],
      key: "factory_name",
    },
    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "id",
      render: (_, record) => formatNumberWithCommas(record?.order_quantity),
    },
    {
      title: "Contract Status",
      dataIndex: "contract_status",
      key: "id",
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const getAllPermitList = useCallback(
    async (passedObject) => {
      setLoading(true);

      let filter = passedObject;
      const customQuery = `${PURCHAS_ORDER_LIST}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    getAllPermitList();
  }, [currentPage, getAllPermitList]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/update-purchase-order/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([PURCHASE_ORDER_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/add-price-purchase-order/${data.id}`}>
              <PlusOutlined /> Add Price
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([PURCHASE_ORDER_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/view-purchase-order/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "2",
          disabled: !hasPermission([PURCHASE_ORDER_VIEW_PERMISSION]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([PURCHASE_ORDER_CREATE_PERMISSION])}
      >
        <Link to="/merchandising/add-purchase-order">
          {hasPermission([PURCHASE_ORDER_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Buyer Purchase Order" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <CustomFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            tableData={permitList}
            fetchTableData={getAllPermitList}
            filterName="purchase_order"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
};

export default PurchaseOrder;
