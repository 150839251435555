import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Select,
  Typography,
} from "antd";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  BIN_ADD_ENDPOINT,
  BIN_DELETE_ENDPOINT,
  BIN_EDIT_ENDPOINT,
  BIN_LIST_ENDPOINT,
  RACK_LIST_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  BIN_CREATE_PERMISSION,
  BIN_DELETE_PERMISSION,
  BIN_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import PlanVsActualTable from "./PlanVsActualTable";
import ProductionSummaryForm from "./ProductionSummaryForm";

const resposeData = {
  code: 200,
  status: "success",
  message: "Data retrieved Successfully",
  data: {
    id: 20,
    name: "Line-1",
    no_of_workers: 1800,
    no_of_machines: 26.5,
    no_of_operations: 14,
    line_chiefs: [
      {
        id: 3848,
        name: "Md. Rafiqul Islam",
        phone: "01934-909507",
        email: "line1sc@cgl.com",
        email_verified_at: null,
        user_type: "Line Chief",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-06-11T10:57:25.000000Z",
        role_id: 29,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
      },
    ],
    supervisors: [
      {
        id: 3846,
        name: "Aklima",
        phone: "01952-078358",
        email: "01952078358@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-07-30T10:39:51.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 2783,
          user_id: 3846,
          emp_id: "50001102",
          designation_name: "Supervisor",
        },
      },
      {
        id: 3847,
        name: "Md. Faruk",
        phone: "01723-371160",
        email: "01723371160@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:25.000000Z",
        updated_at: "2024-07-30T10:39:51.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 2784,
          user_id: 3847,
          emp_id: "3505024",
          designation_name: "Supervisor",
        },
      },
      {
        id: 4892,
        name: "Shabuj Sarker",
        phone: "01639-203429",
        email: "line1q1@cgl.com",
        email_verified_at: null,
        user_type: "Supervisor",
        user_status: "Active",
        created_by: "Admin",
        updated_by: "Admin",
        created_at: "2024-06-11T10:57:29.000000Z",
        updated_at: "2024-06-11T10:57:29.000000Z",
        role_id: 25,
        line_id: 20,
        deleted_at: null,
        department_id: 0,
        profile_image:
          "http://localhost:4016/images/checklist_question_placeholder.jpg",
        additional_info: {
          id: 4329,
          user_id: 4892,
          emp_id: "Q12208",
          designation_name: "Q.ins",
        },
      },
    ],
    plan_vs_actual: [
      {
        buyer: {
          id: 11,
          name: "Unlimited Clothing Limited",
          short_name: "Unlimited Clothing",
        },
        style: {
          id: 692,
          style_no: "fishing shirt",
        },
        sam: 34.4,
        efficiency: 45.2,
        production_plan: 200,
        production_actual: "6",
        production_achive: 100,
        effeciency_plan: 45.2,
        effeciency_actual: 45.425999999999995,
        effeciency_achive: 99.50248756218907,
        rejected_production: "0",
      },
      {
        buyer: {
          id: 17,
          name: "Mango",
          short_name: "Mango",
        },
        style: {
          id: 711,
          style_no: "testinitiate1234",
        },
        sam: 36.5,
        efficiency: 68.8,
        production_plan: 200,
        production_actual: null,
        production_achive: 100,
        effeciency_plan: 68.8,
        effeciency_actual: 69.14399999999999,
        effeciency_achive: 99.50248756218907,
        rejected_production: 0,
      },
    ],
  },
};

// TODO: Remove line_id props
const ProductionSummary = ({ line_id = 14 }) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rackList, setRackList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [summaryData, setSummaryData] = useState(resposeData?.data);

  // Antd
  const [binForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;
  const [form] = Form.useForm();

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  useEffect(() => {
    if (summaryData) {
      form.setFieldsValue({
        date_time: moment().format("DD-MMM-YYYY   hh:mm A"),
        no_of_workers: summaryData?.no_of_workers,
        line_name: summaryData?.name,
        no_of_machines: summaryData?.no_of_machines,
        no_of_operation: summaryData?.no_of_operations,
        line_chief: summaryData?.line_chiefs
          ?.map((item) => item?.name)
          .join(", "),
      });
    }
  }, [summaryData, form]);

  const getAllBin = useCallback(
    async (filterValues) => {
      const { name } = filterValues || {};

      setLoading(true);

      const query = `${BIN_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(name && { name: name }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting bin data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllBin(filterData);
    getAllRack();
  }, [refresh, filterData, getAllBin]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    binForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const getAllRack = async (filterValues) => {
    setLoading(true);

    const query = `${RACK_LIST_ENDPOINT}`;
    const payload = {
      page: 1,
      per_page: 10,
      ...filterValues,
    };

    const response = await getData(query, false, payload);

    if (response?.status === 200) {
      setLoading(false);
      setRackList(response?.data?.data?.data);
    } else {
      setLoading(false);
      message.error("Error getting rack data");
    }
  };

  const deleteSelectedBin = async (id) => {
    setLoading(true);
    const response = await deleteData(`${BIN_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedBin = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      description: formValues?.description,
      status: formValues?.status,
      rack_id: Number(formValues?.rack_id),
    };

    const response = await putData(
      `${BIN_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Edited Successfully");
      refetch();
      binForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewBin = async (formValues) => {
    setLoading(true);

    const bodyData = {
      name: formValues?.name,
      description: formValues?.description,
      status: formValues?.status,
      rack_id: Number(formValues?.rack_id),
    };

    const response = await postData(BIN_ADD_ENDPOINT, bodyData);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Bin Created Successfully");
      refetch();
      binForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    setIsEdit(true);

    // Push the selected rack info to the rack array
    const rackInfo = {
      id: Number(record?.rack_info?.id),
      name: record?.rack_info?.name,
      warehouse_info: record?.rack_info?.warehouse_info,
    };

    const valueExists = rackList?.find((item) => item.id === rackInfo.id);

    if (!valueExists) {
      const rackListCopy = [...rackList];
      rackListCopy.push(rackInfo);
      setRackList(rackListCopy);
    }

    binForm.setFieldsValue({
      id: record?.id,
      name: record?.name,
      description: record?.description,
      status: record?.status === "1" ? true : false,
      rack_id: record?.rack_info?.id,
    });
    setIsModalOpen(true);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const getSummaryData = async (filterValues) => {
    const response = await postData(
      `/api/digitalization/bigscreen-dashboard/productionSummaryReport`,
      filterValues,
    );

    if (response && response?.code === 200) {
      setSummaryData(response?.data);
    }
  };

  useEffect(() => {
    getSummaryData({
      line_id: line_id,
    });
  }, [line_id]);

  const tableColumns = [
    {
      title: "Bin Name",
      dataIndex: ["name"],
      key: "bin_name",
    },
    {
      title: "Warehouse Name",
      dataIndex: ["rack_info", "warehouse_info", "name"],
      key: "warehouse_name",
    },
    {
      title: "Rack Name",
      dataIndex: ["rack_info", "name"],
      key: "rack_name",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "bin_status",
      render: (_, record) => {
        return <div>{record?.status === "1" ? "Active" : "Inactive"}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "bin_description",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedBin(record?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([BIN_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openModalForEdit(record);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([BIN_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onModalOpen}
                disabled={!hasPermission([BIN_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([BIN_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                hideFilterButton
              />
            }
            title={<Title level={5}>Production Summary</Title>}
          />
        }
        content={
          <>
            {/* <FilterComponent
              tableData={tableData}
              filterAPI={getAllBin}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            /> */}

            <ProductionSummaryForm form={form} summaryData={summaryData} />
            <PlanVsActualTable summaryData={summaryData} />

            {/* <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                  showSizeChanger: false,
                }}
              />
            </div> */}
          </>
        }
      />
    </>
  );
};

export default ProductionSummary;
