import { EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  BOLT_SEAL_LOG_DELETE_ENDPOINT,
  BOLT_SEAL_LOG_LIST_ENDPOINT,
  VEHICLE_DRIVER_INFOS_ADD_ENDPOINT,
  VEHICLE_DRIVER_INFOS_EDIT_ENDPOINT,
  VEHICLE_SIZE_ENDPOINT,
  VEHICLE_TYPE_ENDPOINT,
} from "../../../apiServices/API_ENDPOINTS";
import { deleteData, getData, postData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import {
  BOLT_SEAL_LOG_CREATE_PERMISSION,
  BOLT_SEAL_LOG_EDIT_PERMISSION,
} from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const BoltSealLogList = () => {
  // Router
  const location = useLocation();
  const navigate = useNavigate();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [isEditDispatchDateTime, setIsEditDispatchDateTime] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState({
    vehicleType: [],
    vehicleSizeId: [],
  });

  // Antd
  const [boltSealLogForm] = Form.useForm();
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // get options list
  const getOptionsList = async () => {
    await getVehicleTypeList();
    await getVehicleSizeList();
  };

  const getVehicleTypeList = async () => {
    let res = await getData(VEHICLE_TYPE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleType: res?.data?.data?.data || [],
      }));
    }
  };
  const getVehicleSizeList = async () => {
    let res = await getData(VEHICLE_SIZE_ENDPOINT);

    if (res) {
      setOptions((prevState) => ({
        ...prevState,
        vehicleSizeId: res?.data?.data?.data || [],
      }));
    }
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllVehicleAndDriverInfo = useCallback(
    async (filterValues) => {
      const { vehicle_driver_info_id } = filterValues || {};

      setLoading(true);

      const query = `${BOLT_SEAL_LOG_LIST_ENDPOINT}?page=${currentPage}`;
      const bodyData = {
        ...(vehicle_driver_info_id && { vehicle_no: vehicle_driver_info_id }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting VehicleAndDriverInfo data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllVehicleAndDriverInfo(filterData);
  }, [refresh, filterData, getAllVehicleAndDriverInfo]);

  const onAdd = () => {
    navigate("/ex-factory/add-bolt-seal-log");
  };

  const onCloseModal = () => {
    boltSealLogForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
    setIsEditDispatchDateTime(false);
  };

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedVehicleAndDriverInfo = async (id) => {
    setLoading(true);
    const response = await deleteData(`${BOLT_SEAL_LOG_DELETE_ENDPOINT}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle and driver Info Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const editSelectedVehicleAndDriverInfo = async (formValues) => {
    setLoading(true);

    const bodyData = {
      ...formValues,
      arrival_date_time: dayjs(formValues?.arrival_date_time).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
    };

    const response = await postData(
      `${VEHICLE_DRIVER_INFOS_EDIT_ENDPOINT}/${formValues?.id}`,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle and driver Info Edited Successfully");
      refetch();
      boltSealLogForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const createNewVehicleAndDriverInfo = async (formValues) => {
    setLoading(true);

    const bodyData = {
      id: formValues?.id,
      vehicle_no: formValues?.vehicle_no,
      vehicle_size_id: formValues?.vehicle_size_id,
      driver_name: formValues?.driver_name,
      driver_cell_no: formValues?.driver_cell_no,
      nid: parseInt(formValues?.nid),
      vehicle_type_id: formValues?.vehicle_type_id,
      driving_license_no: parseInt(formValues?.driving_license_no),
      arrival_date_time: formValues?.arrival_date_time,
      dispatch_date_time: null,
      dispatch_date_time_id: formValues?.dispatch_date_time_id || null,
      own_vehicle_status: formValues?.own_vehicle_status ? 1 : 0,
      status: 0,
    };

    const response = await postData(
      VEHICLE_DRIVER_INFOS_ADD_ENDPOINT,
      bodyData,
    );

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle and driver Info Created Successfully");
      refetch();
      boltSealLogForm.resetFields();
      onCloseModal();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const openModalForEdit = (record) => {
    navigate(`/ex-factory/edit-bolt-seal-log/${record?.id}`);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Vehicle No",
      dataIndex: ["vehicle_info", "vehicle_no"],
      key: "vehicle_no",
    },
    {
      title: "Driver Name",
      dataIndex: ["driver_info", "driver_name"],
      key: "driver_name",
    },
    {
      title: "Vehicle Size",
      dataIndex: ["vehicle_info", "vehicle_size_info"],
      key: "vehicle_size_id",
      render: (value) => {
        return value?.vehicle_size + " " + value?.vehicle_size_in_cbm;
      },
    },
    {
      title: "Arrival Date Time",
      dataIndex: ["arrival_date_time"],
      key: "arrival_date_time",
    },
    {
      title: "Dispatch Date Time",
      dataIndex: ["dispatch_date_time"],
      key: "dispatch_date_time",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <>
            <Button
              onClick={() => {
                openModalForEdit(record);
              }}
              disabled={!hasPermission([BOLT_SEAL_LOG_EDIT_PERMISSION])}
              size="small"
            >
              <span>
                <EditTwoTone />
              </span>
              <span type="button" style={{ marginLeft: 5 }}>
                Edit
              </span>
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                disabled={!hasPermission([BOLT_SEAL_LOG_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([BOLT_SEAL_LOG_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Bolt Seal Log</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllVehicleAndDriverInfo}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="bolt_seal_log"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
      <Modal
        open={isModalOpen}
        title={
          isEdit
            ? "Edit Vehicle And Driver Information"
            : "Create Vehicle And Driver Information"
        }
        onCancel={() => {
          onCloseModal();
        }}
        maskStyle={{ backdropFilter: "blur(4px)" }}
        footer={null}
      >
        <Form
          name="vehicle_and_driver_information_form"
          form={boltSealLogForm}
          layout="vertical"
          onFinish={
            isEdit
              ? editSelectedVehicleAndDriverInfo
              : createNewVehicleAndDriverInfo
          }
        >
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="vehicle_no"
                placeholder="Vehicle No"
                label="Vehicle No"
                rules={[
                  {
                    required: true,
                    message: "Vehicle No Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vehicle_size_id"
                placeholder="Vehicle Size"
                label="Vehicle Size"
                rules={[
                  {
                    required: true,
                    message: "Vehicle Size Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Vehicle Size"
                  showSearch
                  style={{ width: "100%" }}
                  disabled={isEditDispatchDateTime}
                >
                  {options.vehicleSizeId?.length &&
                    options.vehicleSizeId.map((sup) => (
                      <Select.Option value={sup.id} key={sup.id}>
                        {sup.vehicle_size} ({sup.vehicle_size_in_cbm})
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="driver_name"
                placeholder="Driver Name"
                label="Driver Name"
                rules={[
                  {
                    required: true,
                    message: "Driver Name Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="id" hidden />
              <Form.Item
                name="driver_cell_no"
                placeholder="Driver Cell No"
                label="Driver Cell No"
                rules={[
                  {
                    required: true,
                    message: "Driver Cell No Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item
                name="nid"
                placeholder="NID"
                label="NID"
                rules={[
                  {
                    required: true,
                    message: "NID Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="driving_license_no"
                placeholder="Driving License No"
                label="Driving License No"
                rules={[
                  {
                    required: true,
                    message: "Driving License No Required",
                  },
                ]}
              >
                <Input disabled={isEditDispatchDateTime} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vehicle_type_id"
                placeholder="Vehicle Type"
                label="Vehicle Type"
                rules={[
                  {
                    required: true,
                    message: "Vehicle Type Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Type"
                  showSearch
                  style={{ width: "100%" }}
                  disabled={isEditDispatchDateTime}
                >
                  {options.vehicleType?.length &&
                    options.vehicleType.map((sup) => (
                      <Select.Option value={sup.id} key={sup.id}>
                        {sup.vehicle_type}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="arrival_date_time"
                placeholder="Arrival Date Time"
                label="Arrival Date Time"
                rules={[
                  {
                    required: true,
                    message: "Arrival Date Time Required",
                  },
                ]}
              >
                <DatePicker
                  disabled={isEditDispatchDateTime}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Form.Item name="dispatch_date_time_id" hidden />
            <Col span={12}>
              <Form.Item
                placeholder="Dispatch Date Time"
                label="Dispatch Date Time"
                name="dispatch_date_time"
              >
                <DatePicker
                  name="dispatch_date_time"
                  showTime
                  disabled={!isEditDispatchDateTime}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label=" "
                name="own_vehicle_status"
                valuePropName={"checked"}
              >
                <Checkbox name="own_vehicle_status">Own Vehicle</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6}>
            <Col span={12}>
              <Button
                type="danger"
                style={{ width: "100%" }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                Discard
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BoltSealLogList;
