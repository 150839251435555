import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { GET_TNA_SUMMAARY_DETAILS } from "apiServices/API_ENDPOINTS";
import { useParams, Link } from "react-router-dom";
import { getData } from 'apiServices/common';
import HandleNotification from 'common/Notification';
import { Table, Affix, Card, Space, Button,  } from 'antd';
import Header from "components/Common/Header";
import { isMobileView } from "components/Common/AppHelper";


const SummaryView=({width})=> {
    const { summaryId } = useParams();
    const [permitList, setPermitList] = useState();
    const [totalData, setTotalData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [rangeData, setRangeData] = useState();


    const getAllPermitList = useCallback(
      async (filterValues) => {
        setLoading(true);
        const bodyData = {
          per_page: rangeData || 10,
          page: currentPage,
          ...filterValues,
        };
        let response = await getData(`${GET_TNA_SUMMAARY_DETAILS}/${summaryId}/details`, false);
  
        if (response && response?.data?.code === 200) {
          const masterData = response?.data?.data;
          const result = masterData?.plan_tasks?.map((tna, index)=>{
              return {
                  sl: index + 1,
                  task: tna?.title,
                  process:tna?.process,
                  department: tna?.department,
                  style: tna?.task_styles?.map((i)=> i?.style_no)?.join(', '),
                  po: tna?.task_purchase_order_maps?.map((i)=>i?.original_po)?.join(', '),
                  due_date: tna?.due_date,
                  actual_date: tna?.action_status_date,
                  variance: tna?.variance,
                  status: tna?.status,
                  action: tna?.action_status_string,
                  responsible: tna?.responsible_person_name,
                  dependancy:  tna?.task_dependencies?.map((i)=>i?.title)?.join(', '),
              }
          });
          setPermitList(result);
          setTotalData(masterData?.meta?.total);
          setRangeData(masterData?.meta?.per_page);
        }
        else {
          HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
        }
  
        setLoading(false);
      },
      [rangeData, currentPage],
    );

    useEffect(() => {
        getAllPermitList();
      }, [currentPage, getAllPermitList]);
    
    const columns = [
        {
          title: "Sl",
          dataIndex: "sl",
          key: "sl",
        },
        {
          title: "Task",
          dataIndex: "task",
          key: "task",
        },
        {
          title: "Process",
          dataIndex: "process",
          key: "process",
        },
        {
          title: "Department",
          dataIndex: "department",
          key: "department",
        },
        {
          title: "Style(s)",
          dataIndex: "style",
          key: "style",
        },
        {
          title: "PO(s)",
          dataIndex: "po",
          key: "po",
        },
        {
          title: "Due Date",
          dataIndex: "due_date",
          key: "due_date",
        },
        {
          title: "Actual Date",
          dataIndex: "actual_date",
          key: "actual_date",
        },
        {
          title: "Variance (Days)",
          dataIndex: "variance",
          key: "variance",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Responsible",
          dataIndex: "responsible",
          key: "responsible",
        },
        {
          title: "Dependancy",
          dataIndex: "dependancy",
          key: "dependancy",
        },
      ];
    
      const extra = (
        <Space>
          <Button
            type={"primary"}
            size="small"
            style={{ float: "right" }}
          >
             <Link to={`/tanda/summary`}>
             Back to List
            </Link>
          </Button>
        </Space>
      );
  
  return (
    <div className="mt-4">
       <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="T&A Summary Deatils" subTitle="" actions={extra} />
        </Card>
      </Affix>
       <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={false}
          />
    </div>
  )
};

export default SummaryView;
