import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  message,
  Table,
  Typography,
  Dropdown,
  Menu,
  Popconfirm,
  Select,
} from "antd";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  deleteData,
  postData,
  putData,
} from "../../../apiServices/common";
import { IMPORT_VEHICLE } from "../../../apiServices/API_ENDPOINTS";
import { hasPermission } from "../../../utils/functions";
import {
  GOODS_DELIVERY_CREATE_PERMISSION,
  GOODS_DELIVERY_EDIT_PERMISSION,
  GOODS_DELIVERY_DELETE_PERMISSION,
} from "../../../routes/permissions";
import CustomFilter from "../../Common/CustomFilter";
import moment from "moment";

const VehicleStatus = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Antd
  const { Option } = Select;
  const { Title } = Typography;

  // Router
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllVehicleStatus = useCallback(
    async (filterValues) => {

      setLoading(true);

      const query = `${IMPORT_VEHICLE}?page=${currentPage}`;

      const response = await getData(query, false, filterValues);

      if (response && response?.data?.code === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting vehicle status data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllVehicleStatus();
  }, [refresh, getAllVehicleStatus]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedVehicle = async (id) => {
    setLoading(true);
    const response = await deleteData(`${IMPORT_VEHICLE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("Vehicle Status Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Error deleting vehicle status");
    }
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const tableColumns = [
    {
      title: "Vehicle Number",
      dataIndex: ["vehicle_info","vehicle_number"],
      key: "vehicle_number",
    },
    {
      title: "LC Number",
      dataIndex: ["b2b_lc_info","b2b_lc_number"],
      key: "b2b_lc_number",
    },
    {
      title: "Loading Quantity",
      dataIndex: ["loading_qty"],
      key: "loading_qty",
    },
    {
      title: "Challan No",
      dataIndex: ["vehicle_info","challan_info","challan_no"],
      key: "challan_no",
    },
    {
      title: "Bolt Seal No",
      dataIndex: ["vehicle_info","security_bolt_seal_number"],
      key: "security_bolt_seal_number",
    },
    {
      title: "Loading Point",
      dataIndex: ["vehicle_info","loading_place"],
      key: "loading_place",
    },
    {
      title: "Dispatch Date",
      dataIndex: ["vehicle_info","dispatch_time"],
      key: "dispatch_time",
      render: (value, record) => {
        return <span>{value ? moment(value).format("YYYY-MM-DD") : "N/A"}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedVehicle(record?.vehicle_info?.id);
                        }}
                      >
                        <span>
                          <DeleteTwoTone />
                        </span>
                        <span
                          style={{
                            color: "red",
                            marginLeft: 5,
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([
                      GOODS_DELIVERY_DELETE_PERMISSION,
                    ]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            navigate(
                              `/import/edit-goods-delivery/${record?.vehicle_info?.id}`,
                            );
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([GOODS_DELIVERY_EDIT_PERMISSION]),
                  },
                ]}
              />
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                disabled={!hasPermission([GOODS_DELIVERY_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([GOODS_DELIVERY_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                handleAddItemOpen={() => navigate("/import/add-goods-delivery")}
              />
            }
            title={<Title level={5}>Vehicle Status</Title>}
          />
        }
        content={
          <>
            <CustomFilter
              showFilter={showFilter}
              tableData={tableData}
              fetchTableData={getAllVehicleStatus}
              filterName="import_vehicle_status"
            />

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default VehicleStatus;
