import { useEffect, useState } from "react";
import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { isArrayAndHasValue } from "../../../utils/functions";
import { GET_USERS_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import moment from "moment";

const actions = [
  { id: 1, value: "created", name: "Created" },
  { id: 2, value: "updated", name: "Updated" },
  { id: 3, value: "deleted", name: "Deleted" },
];

const movements = [
  { id: 1, value: "receive", name: "Receive" },
  { id: 2, value: "issue", name: "Issue" },
  { id: 3, value: "return", name: "Return" },
];

const models = [
  { id: 1, value: "inventory", name: "Inventory" },
  { id: 2, value: "user", name: "User" },
  { id: 3, value: "location_track", name: "Location Track" },
  { id: 4, value: "cutting_product", name: "Cutting Product" },
  { id: 5, value: "cutting_item", name: "Cutting Item" },
  { id: 6, value: "assignment", name: "Assignment" },
  { id: 7, value: "bom", name: "Bill Of Materials" },
];

const ActivityLogFilterFields = ({ initialTableData }) => {
  // States
  const [usersList, setUsersList] = useState(null);

  // Antd
  const { Option } = Select;

  const getAllUsers = async () => {
    let res = await getData(GET_USERS_ENDPOINT);

    if (res) {
      setUsersList(res?.data?.data?.data || []);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const saveToStorage = (value, name) => {
    const existingFilter =
      JSON.parse(localStorage.getItem("activity_log_filter")) || {};
    existingFilter[name] = value;
    localStorage.setItem("activity_log_filter", JSON.stringify(existingFilter));
  };

  return (
    <Row gutter={6}>
      <Col span={4}>
        <Form.Item label="Start Date" name="start">
          <DatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
            }
            size="small"
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="End Date" name="end">
          <DatePicker
            style={{ width: "100%" }}
            onChange={(value) =>
              saveToStorage(moment(value).format("YYYY-MM-DD"), "end")
            }
            size="small"
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Action" name="action">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select an action"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(value) => saveToStorage(value, "action")}
            size="small"
          >
            {isArrayAndHasValue(actions) &&
              actions.map((action) => (
                <Option value={action.value} key={action.id}>
                  {action.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Movement Type" name="movement_type">
          <Select
            showSearch
            placeholder="Select a Movement Type"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(value) => saveToStorage(value, "movement_type")}
            size="small"
          >
            {isArrayAndHasValue(movements) &&
              movements.map((action) => (
                <Option value={action.value} key={action.id}>
                  {action.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Acted By" name="acted_by">
          <Select
            showSearch
            placeholder="Select a User"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(value) => saveToStorage(value, "acted_by")}
            size="small"
          >
            {isArrayAndHasValue(usersList) &&
              usersList.map((user) => (
                <Option value={user.id} key={user.id}>
                  {user.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="UI Model" name="ui_model_name">
          <Select
            showSearch
            placeholder="Select a Model"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={(value) => saveToStorage(value, "ui_model_name")}
            size="small"
          >
            {isArrayAndHasValue(models) &&
              models.map((model) => (
                <Option value={model.value} key={model.id}>
                  {model.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ActivityLogFilterFields;
