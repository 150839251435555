import { Line } from "@ant-design/plots";
import { Card } from "antd";

export default function LineData({ data }) {
  // console.log(data);
  const config = {
    data,
    xField: "hour",
    yField: "value",
    seriesField: "type",
    colorField: "type",
    scale: { color: { range: ["#ff7f0e ", "#aaaaaa"] } },
    point: {
      size: 4,
      shape: "circle",
    },
    lineStyle: {
      lineWidth: 2,
    },
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        marginBottom: "20px",
      }}
    >
      <Line {...config} />
    </Card>
  );
}
