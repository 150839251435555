import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import HandleNotification from "common/Notification";
import moment from "moment";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_SIZES,
  PO_ADD_NEW,
  PO_UPDATE_NEW,
  PO_WISE_INFO,
  REMOVE_PO_ROW,
  REMOVE_SIZE_COLUMN,
  SALES_CONTRACT_LIST
} from "../../../apiServices/API_ENDPOINTS";
import { getData, getWithData, postData } from "../../../apiServices/common";
import { alertPop } from "../../../apiServices/common/helper";
import { isArrayAndHasValue } from "../../../utils/functions";
import StylePODetails from "./StylePODetails";

const AddPurchaseOrderForm = forwardRef((props, ref) => {
  // Props
  const { editInfo, mode, poDetailsData, setPoDetailsData } = props;

  // States
  const [purchesList, setPurchesList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [buyerId, setBuyerId] = useState("");
  const [dynamicSizeList, setDynamicSizeList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [styleWiseInfo, setStyleWiseInfo] = useState([]);

  const [contractList, setContractList] = useState([]);
  const [buyingOfficeId, setBuyingOfficeId] = useState(null);
  const [subFactoryId, setSubFactoryId] = useState(null);
  const [showFactoryInfoInputs, setShowFactoryInfoInputs] = useState(false);
  const [subContractStatus, setSubContractStatus] = useState(null);

  // Others
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const buying_office_id_watch = Form.useWatch("buying_office_id", form);
  const sales_contract_id_watch = Form.useWatch("sales_contract_name", form);
  const buyer_id_watch = Form.useWatch("buyer_id", form) || buyerId;

  useEffect(() => {
    // Only in update mode
    // Get selected po details data and assign data
    if (mode === "update" && editInfo) {
      if(editInfo?.buying_office) {
        setShowFactoryInfoInputs(false);
      } else if(editInfo?.factory) {
        setShowFactoryInfoInputs(true);
      }
      getContractIdDetails(editInfo?.sales_contract?.id);
      setSizeList(editInfo?.sales_contract_sizes);
      setStyleWiseInfo(editInfo?.style_wise_infos);
      setBuyingOfficeId(editInfo?.buying_office?.id);
      setSubFactoryId(editInfo?.factory?.id || null);
      
      form.setFieldsValue({
        first_party_id: editInfo?.factory?.name,
        address:editInfo?.factory?.address,
        buying_office_id: editInfo?.buying_office?.name,
        sales_contract_id: editInfo?.sales_contract?.id,
        sales_contract_name: editInfo?.sales_contract?.reference_no,
        buyer: editInfo?.buyers?.short_name,
        contract_status: editInfo?.contract_status,
        entry_date: moment(editInfo?.entry_date, "YYYY-MM-DD"),
        internal_po: editInfo?.po?.internal_po,
        original_po: editInfo?.po?.original_po,
        order_quantity: editInfo?.order_quantity
            ? parseInt(editInfo?.order_quantity)
            : 0,
      });
    }
  }, [mode, editInfo]);

  useImperativeHandle(ref, () => ({
    submit() {
      form
        .validateFields()
        .then(async (values) => {
          // Find and get po_map_id
          const foundPO = purchesList.find(
            (item) => item.internal_po === values?.internal_po,
          );
          const foundPoMapId = foundPO?.id;
          const poDeliveryDate = foundPO?.delivery_date;

          const payload = {
            subcontract_status: subContractStatus,
            buying_office_id: subContractStatus === 0 ? buyingOfficeId : null,
            sales_contract_id: values?.sales_contract_id,
            buyer_id:subContractStatus === 0? buyerId : null,
            sub_contract_factory_id: subContractStatus === 1 ? subFactoryId : null,
            order_quantity: values?.order_quantity,
            contract_status: values?.contract_status || "Created",
            entry_date: values?.entry_date,
            internal_po: values?.internal_po,
            original_po: values?.original_po,
            po_map_id: foundPoMapId,
          };
          const style_infos_data_modified =
            isArrayAndHasValue(styleWiseInfo) &&
            styleWiseInfo.map((item) => {
              return {
                style_id: item?.style?.id,
                color_id: item?.color?.id,
                sub_style_id: item?.sub_style?.id,
                brand_id: item?.brand?.id || null,
                extra_cutting: item?.extra_cutting || 0,
                po_status: item?.po_status || "Created",
                sizes: item?.sizes?.map((sizeItem) => {
                  return {
                    size_id: sizeItem?.id,
                    sales_order_quantity: sizeItem?.sales_order_quantity,
                    po_detail_id: sizeItem?.po_detail_id || 0,
                    delivery_date: sizeItem?.delivery_date || poDeliveryDate,
                    is_delete: 0, //for add 0
                  };
                }),
              };
            });

          payload["style_infos"] = style_infos_data_modified;

          if (mode === "update" && editInfo) {
            // For update PO API call
            let response = await postData(PO_UPDATE_NEW, payload);
            if (response && response?.code === 200) {
              alertPop("success", `Purchase Order Updated Successfully.`);
              navigate("/merchandising/purchase-order-new");
            }
          } else {
            // For create PO API call
            let response = await postData(PO_ADD_NEW, payload);
            if (response && response?.code === 200) {
              alertPop("success", "Purchase Order Created Successfully");
              navigate("/merchandising/purchase-order-new");
            }
          }
        })
        .catch((errorInfo) => {
          alertPop("error", "Error");
        });
    },
    discart() {
      form.resetFields();
    },
  }));

  const getContractList = async(filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setContractList(response?.data?.data?.data || []);
    }
  }

  const getContractIdDetails = async (value) => {
    let get_contract_details = `/api/sales_contract/${value}/view`;
    let response = await getData(get_contract_details);

    if (response && response?.data?.code === 200) {
      setPurchesList(response.data.data.purchase_orders);
      form.resetFields(["buyer", "style_no", "brand_name"]);
      form.setFieldsValue({
        buyer: response?.data?.data?.sales_contract?.buyer_info?.name,
      });
      setBuyerId(response?.data?.data?.sales_contract?.buyer_info?.id);
      setStyleList(response?.data?.data?.styleDetail);
      setSubContractStatus(response?.data?.data?.sales_contract?.subcontract_status ? 1 : 0);
      setSizeList(response?.data?.data?.sales_contract?.size_info);
    }
    else {
      HandleNotification('error', 'bottomRight', 'Error getting contract details data', null) 
    }
  };

  const getSizeList = async (po_map_id) => {
    const bodyData = {
      buying_office_id: buying_office_id_watch,
      sales_contract_id: sales_contract_id_watch,
      buyer_id: buyer_id_watch,
      po_map_id: po_map_id,
    };

    const response = await postData(PO_WISE_INFO, bodyData);

    if (response && response?.code === 200) {
      // Set style wise info
      setStyleWiseInfo(response?.data?.style_wise_infos);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting size list",
        null,
      );
    }
  };

  const OnSearchGetSizeList = async (value) => {
    let payload = {
      name: value,
    };
    let res = await getWithData(GET_SIZES, undefined, payload);

    if (res) {
      setSizeList(res?.data?.data?.data);
    }
  };

  const addSize = (value, sizeObject) => {
    const dynamicSizeListCopy = [...dynamicSizeList];
    dynamicSizeListCopy.push({
      label: sizeObject?.label,
      value: value,
      quantity: 0,
    });
    setDynamicSizeList(dynamicSizeListCopy);
  };

  const deleteSizeFromDB = async (poDetailIds) => {
    const payload = {
      po_detail_id: poDetailIds || [],
    };

    const response = await postData(REMOVE_SIZE_COLUMN, payload);

    if (response && response?.code === 200) {
      alertPop("success", response?.message?.[0] || "Successfully Removed");
    } else {
      alertPop("error", response?.message?.[0] || "Something went wrong");
    }
  };

  const deleteSize = (value, sizeObject) => {
    const dynamicSizeListCopy = [...dynamicSizeList];
    const filteredSizes = dynamicSizeListCopy?.filter(
      (sizeItem) => sizeItem?.value !== value,
    );
    setDynamicSizeList(filteredSizes);

    // Manipulate the poDetailsData and remove the size from the list
    const deleted_po_details_ids = [];
    const poDetailsDataCopy = structuredClone(poDetailsData);
    poDetailsDataCopy.forEach((obj, index) => {
      // Push delete po details id to the array
      const sizeToBeDeleted = obj?.sizes?.find(
        (sizeItem) => sizeItem?.id === value,
      );
      if (sizeToBeDeleted) {
        deleted_po_details_ids.push(sizeToBeDeleted?.po_detail_id);
      }

      // Filter the sizes from the poDetailsDataCopy list
      const filteredSizes = obj?.sizes?.filter(
        (sizeItem) => sizeItem?.id !== value,
      );
      poDetailsDataCopy[index]["sizes"] = filteredSizes;
    });

    setPoDetailsData(poDetailsDataCopy);

    // Delete the size from the database
    if (deleted_po_details_ids.length > 0) {
      deleteSizeFromDB(deleted_po_details_ids);
    }
  };

  useEffect(() => {
    getContractList();
  }, [editInfo]);

  useEffect(() => {
    // When dynamicSizeList is empty and styleWiseInfo is not empty
    if (
      isArrayAndHasValue(styleWiseInfo) &&
      !isArrayAndHasValue(dynamicSizeList)
    ) {
      const uniqueSizes = [];
      // Get all the available sizes
      styleWiseInfo?.forEach((item) => {
        item?.sizes?.forEach((size) => {
          const isExists = uniqueSizes.some(
            (element) => element?.value === size?.id,
          );
          if (!isExists) {
            uniqueSizes.push({
              label: size?.name,
              value: size?.id,
              quantity: size?.sales_order_quantity,
            });
          }
        });
      });

      setDynamicSizeList([...uniqueSizes]);
    }
  }, [styleWiseInfo, dynamicSizeList]);

  useEffect(() => {
    if (dynamicSizeList && dynamicSizeList.length > 0) {
      const uniqueSizeIds = [
        ...new Set(dynamicSizeList.map((size) => size.value)),
      ];

      form.setFieldsValue({
        size_id: uniqueSizeIds,
      });
    }
  }, [dynamicSizeList, form]);

  const showOrderTotal = (styleWiseInfo) => {
    const orderTotal = styleWiseInfo?.reduce((accumulator, currentValue) => {
      return accumulator + showTotalRowWise(currentValue);
    }, 0);
    form.setFieldsValue({
      order_quantity: orderTotal,
    });
    return orderTotal || 0;
  };

  const showTotalRowWise = (obj) => {
    const totalSizeQuantity = obj?.sizes?.reduce(
      (accumulator, currentValue) => {
        return accumulator + parseInt(currentValue?.sales_order_quantity || 0);
      },
      0,
    );

    return totalSizeQuantity || 0;
  };

  const removePoRow = async (poObject) => {
    const poDetailIds = poObject?.sizes?.map(
      (sizeItem) => sizeItem?.po_detail_id,
    );

    const payload = {
      po_detail_id: poDetailIds || [],
    };

    const response = await postData(REMOVE_PO_ROW, payload);

    if (response && response?.code === 200) {
      alertPop("success", "Successfully Removed");
    } else {
      alertPop("error", "Error removing data");
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form layout="vertical" form={form} name="control-hooks">
        <Card>
          <Row gutter={6}>
            <Col className="gutter-row" span={4}>
              {/*<Form.Item name="sales_contract_id" hidden />*/}
              <Form.Item
                label="Sales Contract"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please Select Sales Contract No!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Sales Contract No"
                  onSelect={(value) => {
                    getContractIdDetails(value);
                    // Filter the buying office options based on the selected contract
                    const filteredContracts = contractList.filter(
                      (data) => value === data.id,
                    );

                    // Set the value of the Buying Office and disable the field
                    if (filteredContracts.length > 0) {
                      setSubContractStatus(filteredContracts[0]?.subcontract_status);
                      const selectedOffice =
                        filteredContracts[0]?.buying_office_info;
                      const selectedBuyer = filteredContracts[0]?.buyer_info;
                      const selectedFactory =
                        filteredContracts[0]?.factory_info;                      
                      if (selectedOffice && selectedBuyer) {
                        setShowFactoryInfoInputs(false);
                        setBuyingOfficeId(selectedOffice?.id);
                        setBuyerId(selectedBuyer?.id);
                        form.setFieldsValue({
                          buying_office_id: selectedOffice?.name,
                          buyer: selectedBuyer?.name
                        });
                      } else if (selectedFactory) {
                        setShowFactoryInfoInputs(true);
                        setSubFactoryId(selectedFactory?.id);
                        form.setFieldsValue({
                          first_party_id: selectedFactory?.name,
                          address: selectedFactory?.address
                        });
                      }
                    }
                  }}
                  optionFilterProp="children"
                  size="small"
                  onSearch={(value) => {
                    getContractList({ reference_no: value });
                  }}
                  allowClear
                  onClear={() => {
                    form.resetFields([
                      "sales_contract_id",
                      "buying_office_id",
                      "buyer",
                      "first_party_id",
                      "address",
                      "contract_status",
                    ]);
                  }}
                >
                  {isArrayAndHasValue(contractList) &&
                    contractList.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.reference_no}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            {!showFactoryInfoInputs ? (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="po_map_id" hidden />
                  <Form.Item
                    label="Buying Office"
                    name="buying_office_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buying Office"
                      optionFilterProp="children"
                      disabled
                      size="small"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Buyer"
                    name="buyer"
                    rules={[
                      {
                        required: false,
                        message: "Please input Buyer!",
                      },
                    ]}
                    disabled={mode === "view" || mode === "update"}
                  >
                    <Input
                      className="w-100"
                      disabled={true}
                      placeholder="Please select sales contract"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="po_map_id" hidden />
                  <Form.Item
                    label="First Party"
                    name="first_party_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a First Party"
                      optionFilterProp="children"
                      disabled
                      size="small"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: "Please input Address!",
                      },
                    ]}
                    disabled={mode === "view" || mode === "update"}
                  >
                    <Input
                      className="w-100"
                      disabled={true}
                      placeholder="Address"
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Contract Status"
                name="contract_status"
                rules={[
                  { required: false, message: "Please input Contract Status!" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a Contract Status"
                  optionFilterProp="children"
                  disabled={true}
                  defaultValue={1}
                  size="small"
                >
                  <Option value={1} key={1}>
                    Active
                  </Option>
                  <Option value={2} key={2}>
                    Deactive
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Entry Date"
                name="entry_date"
                rules={[
                  {
                    required: true,
                    message: "Please input Entry Date!",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  disabled={mode === "view" || mode === "update"}
                  size="small"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={0}>
              <Form.Item
                label="Internal PO"
                name="internal_po"
                style={{ marginBottom: "unset" }}
                hidden
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  className="w-100"
                  showSearch
                  placeholder="Select a Internal PO"
                  onSelect={(value) => {
                    const foundOriginalPo = purchesList.find(
                      (item) => item.internal_po === value,
                    )["original_po"];
                    form.setFieldsValue({
                      original_po: foundOriginalPo,
                    });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  disabled={mode === "view" || mode === "update"}
                  size="small"
                >
                  {purchesList?.length &&
                    purchesList.map((sup, index) => {
                      return (
                        <Option
                          value={sup.internal_po || undefined}
                          key={`internal_po_key_${index}`}
                        >
                          {sup.internal_po}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="po_map_id" hidden />
              <Form.Item
                label="Original PO"
                name="original_po"
                rules={[
                  {
                    required: true,
                    message: "Please Select Original PO",
                  },
                ]}
                style={{ marginBottom: "unset" }}
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  className="w-100"
                  showSearch
                  placeholder="Select an Original PO"
                  onSelect={(value) => {
                    const foundPoItem = purchesList.find(
                      (item) => item.original_po === value,
                    );
                    const foundInternalPo = foundPoItem["internal_po"];
                    const foundPoMapId = foundPoItem["id"];

                    form.setFieldsValue({
                      internal_po: foundInternalPo,
                      po_map_id: foundPoMapId,
                    });

                    // Get size list array
                    if (mode === "add") {
                      getSizeList(foundPoMapId);
                    }
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option?.children != null) {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }
                  }}
                  disabled={mode === "view" || mode === "update"}
                  size="small"
                >
                  {purchesList?.length &&
                    purchesList.map((sup, index) => {
                      return (
                        <Option
                          value={sup?.original_po || undefined}
                          key={`original_po_key_${index}`}
                        >
                          {sup?.original_po}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item label="Order Quantity" name="order_quantity">
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Order Quantity"
                  size="small"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Size"
                name="size_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Size!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  onDeselect={(value, label) => {
                    deleteSize(value, label);
                  }}
                  onSelect={(value, label) => {
                    addSize(value, label);
                  }}
                  onSearch={(e) => OnSearchGetSizeList(e)}
                  showSearch
                  placeholder="Select a Size or Create"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  dropdownMatchSelectWidth={false}
                  options={
                    isArrayAndHasValue(sizeList) &&
                    sizeList.map((item) => ({
                      label: item?.sizes?.name,
                      value: item?.sizes?.id,
                    }))
                  }
                  disabled={mode === "view" || mode === "update"}
                  size="small"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      <StylePODetails
        form={form}
        dynamicSizeList={dynamicSizeList}
        styleList={styleList}
        mode={mode}
        setColorList={setColorList}
        colorList={colorList}
        showOrderTotal={showOrderTotal}
        showTotalRowWise={showTotalRowWise}
        poDetailsData={poDetailsData}
        setPoDetailsData={setPoDetailsData}
        removePoRow={removePoRow}
        styleWiseInfo={styleWiseInfo}
        setStyleWiseInfo={setStyleWiseInfo}
        setDynamicSizeList={setDynamicSizeList}
      />
    </div>
  );
});

export default AddPurchaseOrderForm;
