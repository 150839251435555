import { Card, Col, Input, Row, Form } from "antd";
import moment from "moment";
import { isArrayAndHasValue } from "utils/functions";

const ProductionSummaryForm = (props) => {
  // Props
  const { form, summaryData } = props;
  

  return (
    <Form layout="vertical" form={form}>
      <Card>
        <Row gutter={6}>
          <Col span={4}>
            <Form.Item label="Date & Time" name="date_time">
              <Input size="small" disabled value={moment().format("DD-MM-YYYY")} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="No of Workers" name="no_of_workers">
              <Input size="small" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Line" name="line_name">
              <Input size="small" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="No of Machines" name="no_of_machines">
              <Input size="small" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Line Chief" name="line_chief">
              <Input size="small" disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="No of Operation" name="no_of_operation">
              <Input size="small" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={4}>
            <Form.Item label="Supervisor" name="supervisor">
              {isArrayAndHasValue(summaryData?.supervisors) && summaryData?.supervisors?.map((item) => {
                return(
                  <Input size="small" disabled value={`${item?.id} : ${item?.name}`} />
                );
              })}
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ProductionSummaryForm;
