import { Col, Form, DatePicker } from "antd";

const HourlyProductionRecFilterField = ({ initialTableData }) => {
  // States
  return (
    <>
      <Col span={3}>
        <Form.Item label="Date" name="date">
          <DatePicker placeholder="Date" size="small" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </>
  );
};

export default HourlyProductionRecFilterField;
