import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import AppHeader from "../Header";
import "antd/dist/antd.css";
import "./index.css";
import SideBar from "../SideBar";
import LogIn from "../../components/LogIn";
import { menu } from "../../routes/menuConfig";
import { isArrayAndHasValue } from "../../utils/functions";

const AppLayOut = ({ children }) => {
  // States
  const [collapsed, setCollapsed] = useState(false);
  const [menuList, setMenuList] = useState(menu);

  // Router
  const location = useLocation();
  
  useEffect(() => {
    // Store location history in localstorage
    const maxRoutesToStore = 5;
    const currentPath = location.pathname;
    const locationItem = localStorage.getItem("locationHistory");
    const locationHistory = locationItem ? JSON.parse(locationItem) : [];
    locationHistory.unshift(currentPath);
    const uniqueRoutes = [...new Set(locationHistory)];
    const routesHistory = uniqueRoutes.slice(0, maxRoutesToStore);
    localStorage.setItem("locationHistory", JSON.stringify(routesHistory));
  }, [location]);


  useEffect(() => {
    // If collapsed reset menu to default
    if(collapsed) {
      setMenuList(menu);
    }
  }, [collapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onSearchMenu = (menuName) => {
    // Reset to default menu if cleared / no value
    if (!menuName) {
      setMenuList(menu);
      return;
    }

    // Sort menu by given menu name
    const sortedByMenuName = [];
    menuList?.forEach((item) => {
      if (item?.key?.toLowerCase()?.includes(menuName) && !item?.children) {
        sortedByMenuName.push(item);
      }
      if (item?.children) {
        const sortedBySubMenuName = item?.children?.filter((subItem) =>
          subItem?.key?.toLowerCase()?.includes(menuName),
        );

        isArrayAndHasValue(sortedBySubMenuName) &&
          sortedByMenuName.push({
            ...item,
            children: sortedBySubMenuName,
          });
      }
    });

    isArrayAndHasValue(sortedByMenuName) && setMenuList(sortedByMenuName);
  };

  return (
    <>
      {location.pathname !== "/login" ? (
        <Layout>
          <AppHeader
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
            onSearchMenu={onSearchMenu}
          />
          <Layout style={{ height: "100%" }} className="body-layout">
            <SideBar collapsed={collapsed} menuList={menuList} />
            <Layout
              style={{
                padding: "0 5px 5px",
              }}
              className="site-layout"
            >
              {children}
            </Layout>
          </Layout>
        </Layout>
      ) : (
        <LogIn />
      )}
    </>
  );
};
export default AppLayOut;
