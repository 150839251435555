import { Col, Form, Input, Select, Switch } from "antd";

const ChecklistQuestionsFilter = ({ initialTableData }) => {
  return (
    <>
      <Col span={6}>
        <Form.Item label="Name (English)" name="name">
          <Input placeholder="Name (English)" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Role" name="role_id">
          <Select placeholder="Select Role">
            {initialTableData?.appRoleMenu?.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="Status" name="status">
          <Switch />
        </Form.Item>
      </Col>
    </>
  );
};

export default ChecklistQuestionsFilter;
