import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../Common/Header";
import { Card, Button, Affix } from "antd";
import { isMobileView } from "../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import AddPurchaseOrderForm from "./AddPurchaseOrderForm";
import { getData } from "../../../apiServices/common";

export default function ViewPO(props) {
  let { styleId } = useParams();
  const { width } = props;
  const propertyFormRef = useRef(null);
  const [permitInfo, setPermitInfo] = useState();
  const [poDetailsData, setPoDetailsData] = useState(null);

  const navigate = useNavigate();
  const discartForm = () => {
    navigate("/merchandising/purchase-order");
  };
  const edit_url = `/api/purchase_orders/${styleId}/edit`;


  const getPOInfo = useCallback(async () => {
    let res = await getData(edit_url);

    if (res) {
      let masterData = res.data.data;
      setPermitInfo(masterData);
      const po_data = masterData ? masterData.purchase_order_details : null;

      const po_details = po_data.map((item) => {
        const sizes = {};

        item?.sizes.forEach((size) => {
          sizes[size?.name + "_" + size?.id] = size?.sales_order_quantity;
        });
        return {
          ...item,
          style_id: item.style.id,
          color_id: item.color.id,
          ...sizes,
        };
      });

      setPoDetailsData(po_details);
    }
  }, [edit_url]);

  useEffect(() => {
    getPOInfo();
  }, [getPOInfo]);

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="View Purchase Order"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Button size="small" key={1} danger onClick={() => discartForm()}>
                Back
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>

      <AddPurchaseOrderForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        view={true}
        poDetailsData={poDetailsData}
        setPoDetailsData={setPoDetailsData}
        mode={"view"}
      />
    </>
  );
}
