import { Col, DatePicker, Form, Select } from "antd";
import { GET_BUYER_ENDPOINT } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { useEffect, useState } from "react";
const { Option } = Select;
const { RangePicker } = DatePicker;

const TNATodoFilter = ({ initialTableData }) => {
  const [buyerList, setBuyerList] = useState([]);

  useEffect(() => {
    getBuyerListData();
  }, []);

  const getBuyerListData = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };
  return (
    <>
      <Col span={6}>
        <Form.Item label="Task Filter" name="todoTask">
          <Select
              mode="multiple"
              showSearch
              placeholder="Select for search"
              optionFilterProp="children"
              size="small"
              allowClear
              onClear={() => getBuyerListData()}
            >
                <Option value="1">Todo</Option>
                <Option value="2">Hold</Option>
            </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="As on Date" name="date_range">
          <RangePicker />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Buyer" name="buyer_id">
          <Select
            showSearch
            placeholder="Select for Search"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getBuyerListData({ name: value })}
            allowClear
            onClear={() => getBuyerListData()}
          >
            {buyerList?.length &&
              buyerList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default TNATodoFilter;
