import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BUYER_INQUIRY_ADD_ENDPOINT,
  BUYER_INQUIRY_EDIT_ENDPOINT,
  GARMENT_CATEGORY_LIST_ENDPOINT,
  GARMENT_FOR_LIST_ENDPOINT,
  GARMENT_TYPE_LIST_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  GET_INQUIRY_NUMBER,
  GET_STYLE_LIST_ENDPOINT,
  GET_USERS_ENDPOINT,
  STYLE_ADD_DOC,
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import { isArrayAndHasValue } from "../../../utils/functions";
import DocumentUpload from "../../Common/DocumentUpload";
import CMForm from "./CMForm";
import OBForm from "./OBForm";
import OrderConfirmationForm from "./OrderConfirmationForm";

const inquiryStatus = [
  {
    id: 1,
    name: "Inquiry",
    value: "0",
  },
  {
    id: 2,
    name: "Contracted",
    value: "1",
  },
  {
    id: 3,
    name: "Closed",
    value: "2",
  },
];

const BuyerInquiryForm = forwardRef((props, ref) => {
  // Props
  const {
    view,
    form,
    isEdit,
    editInfo,
    fileData,
    CMInfo,
    editMode,
    viewMode,
    users,
    setUsers,
    styles,
    setStyles,
    buyingOffice,
    setBuyingOffice,
    buyer,
    setBuyer,
    brand,
    setBrand,
    garmentCategoryList,
    setGarmentCategoryList,
    garmentTypeList,
    setGarmentTypeList,
    garmentForList,
    setGarmentForList,
  } = props;

  // States

  const [filesList, setFileList] = useState([]);

  const [selectedBuyingOffice, setSelectedBuyingOffice] = useState(null);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [subcontractStatusOption, setSubcontractStatusOption] = useState([]);

  // Others
  const navigate = useNavigate();
  const { Option } = Select;
  const { confirm } = Modal;
  const received_date_watch = Form.useWatch("received_date", form);
  const buyer_short_name = Form.useWatch("buyer_short_name", form);
  const { inquiryId } = useParams();
  const style_id_watch = Form.useWatch("style_id", form);
  const buying_office_id_watch = Form.useWatch("buying_office_id", form);
  const buyer_id_watch = Form.useWatch("buyer_id", form);
  const subcontract_status = Form.useWatch("subcontract_status", form);

  console.log("subcontract_status", subcontract_status);

  useEffect(() => {
    // When in Edit Mode, set fileList values
    if (isArrayAndHasValue(fileData)) {
      const fileInfo = fileData?.map((file) => {
        return {
          id: file?.id,
          name: file?.document_name,
          path: file?.document,
          attachment_type: file?.attachment_type,
          reference_no: file?.reference_no,
          is_delete: 0,
        };
      });
      setFileList(fileInfo);
    }
  }, [fileData]);

  const onFinish = async (values) => {
    // console.log("onFinish values", values);
    if (!isArrayAndHasValue(filesList)) {
      HandleNotification(
        "error",
        "Error!",
        "Please upload at least one document",
      );
      return;
    }

    console.log("values", values);

    const addQuery = `${BUYER_INQUIRY_ADD_ENDPOINT}`;
    const editQuery = `${BUYER_INQUIRY_EDIT_ENDPOINT}/${inquiryId}`;
    const query = isEdit ? editQuery : addQuery;

    const sub_contract = values.subcontract_status ? {} : {};

    const bodyData = {
      ...sub_contract,
      buying_office_id: values?.buying_office_id
        ? Number(values?.buying_office_id)
        : null,
      buyer_id: values?.buyer_id ? Number(values?.buyer_id) : null,
      brand_id: values?.brand_id,
      received_date: values?.received_date
        ? moment(values?.received_date).format("YYYY-MM-DD")
        : null,
      received_by: values?.received_by,
      inquiry_no: values?.inquiry_no,
      style_id: values?.style_id,
      garment_category_id: values?.garment_category_id,
      garment_type_id: values?.garment_type_id,
      garment_for_id: values?.garment_for_id,
      inquiry_status: values?.inquiry_status,
      description: values?.description,
      order_status: values?.order_status,
      season: values?.season,
      size_range: values?.size_range,
      expected_order_quantity: values?.expected_order_quantity
        ? Number(values?.expected_order_quantity)
        : null,
      expected_delivery_date: values?.expected_delivery_date
        ? moment(values?.expected_delivery_date).format("YYYY-MM-DD")
        : null,
      production_month_from: values?.production_month_from
        ? moment(values?.production_month_from).format("YYYY-MM-DD")
        : null,
      production_month_to: values?.production_month_to
        ? moment(values?.production_month_to).format("YYYY-MM-DD")
        : null,
      documents: isArrayAndHasValue(filesList)
        ? filesList?.map((file) => {
            return {
              document_id: file?.id,
              attachment_type: file?.attachment_type || null,
              reference_no: file?.reference_no || null,
              is_delete: file?.is_delete ? 1 : 0,
            };
          })
        : [],
      order_confirmations: isArrayAndHasValue(values?.order_confirmations)
        ? values?.order_confirmations?.map((item) => {
            return {
              id: item?.id || 0,
              inquiry_reason_id: item?.inquiry_reason_id || null,
              inquiry_remark_id: item?.inquiry_remark_id || null,
              is_delete: item?.is_delete || 0,
            };
          })
        : [],
      expected_margin: values?.expected_margin,
      buyer_target: values?.buyer_target,
      subcontract_status: values?.subcontract_status ? 1 : 0,
      sub_contract_factory_id: values?.sub_contract_factory_id,
    };

    const response = isEdit
      ? await postData(query, bodyData)
      : await postData(query, bodyData);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "Success!",
        response?.message ||
          `Successfully ${isEdit ? "updated" : "added"} inquiry`,
      );
      navigate("/merchandising/buyer-inquiry");
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || `Failed to ${isEdit ? "update" : "add"} inquiry`,
      );
    }
  };

  const getAllUsers = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${GET_USERS_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setUsers(response?.data?.data?.data);
    }
  };

  const getAllStyles = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${GET_STYLE_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyles(response?.data?.data?.data);
    }
  };

  const getSelectedStyleInfo = async (styleId) => {
    const query = `${GET_STYLE_LIST_ENDPOINT}/${styleId}/edit`;

    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const styleData = response?.data?.data?.[0]?.style;
      form.setFieldsValue({
        buying_office_id: styleData?.buying_office_info?.id,
        buying_office_name: styleData?.buying_office_info?.name,
        buyer_id: styleData?.buyer_info?.id,
        buyer_name: styleData?.buyer_info?.name,
        buyer_short_name: styleData?.buyer_info?.short_name,
        brand_id: styleData?.brand_info?.id,
        brand_name: styleData?.brand_info?.name,
        garment_category_id: styleData?.garment_category_info?.id,
        garment_type_id: styleData?.garment_type_info?.id,
        garment_for_id: styleData?.garment_for_info?.id,
        description: styleData?.description,
        sub_contract_factory_id: styleData?.sub_contract_factory_id,
        address: styleData?.factory_info?.address,
        // subcontract_status: styleData?.subcontract_status,
        inquiry_no: styleData?.factory_info
          ? `${styleData?.factory_info?.name}_${styleId}_${moment().format(
              "YY-MM-DD",
            )}`
              .split(" ")
              .join("_")
          : `${styleData?.buyer_info?.short_name}_${styleId}_${moment().format(
              "YY-MM-DD",
            )}`
              .split(" ")
              .join("_"),
      });
      // Ensure the selected buying office is included in the options
      if (
        !buyingOffice.some(
          (office) => office?.id === styleData?.buying_office_info?.id,
        )
      ) {
        setBuyingOffice([...buyingOffice, styleData?.buying_office_info]);
      }

      // Ensure the selected buyer is included in the options
      if (!buyer.some((b) => b?.id === styleData?.buyer_info?.id)) {
        setBuyer([...buyer, styleData?.buyer_info]);
      }

      // Ensure the selected brand is included in the options
      if (!brand.some((br) => br?.id === styleData?.brand_info?.id)) {
        setBrand([...brand, styleData?.brand_info]);
      }
    }
  };

  const getAllGarmentCategory = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${GARMENT_CATEGORY_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentCategoryList(response?.data?.data?.data);
    }
  };

  const getAllGarmentType = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${GARMENT_TYPE_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentTypeList(response?.data?.data?.data);
    }
  };

  const getAllGarmentFor = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      ...filterValues,
    };
    const query = `${GARMENT_FOR_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setGarmentForList(response?.data?.data?.data);
    }
  };

  const getBuyingOfficeList = async (filterValues) => {
    const query = `${GET_BUYING_OFFICE_ENDPOINT}`;
    const bodyData = {
      per_page: 10,
      name: filterValues?.name || "",
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyingOffice(response?.data?.data?.data || []);
    }
  };

  const getBuyersList = async (value, filterValues) => {
    if (!filterValues) {
      form.resetFields(["buyer_id", "brand_id"]);
    }

    const query = `/api/buying_office/${value}/edit`;
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyer(response?.data?.data?.buyer || []);
    }
  };

  const getBrand = async (value, filterValues) => {
    if (!filterValues) {
      form.resetFields(["brand_id"]);
    }

    // Set buyer short name field value
    const foundBuyer = buyer?.find((item) => item?.id === value);
    form.setFieldsValue({
      buyer_short_name: foundBuyer?.short_name,
    });

    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/buyers/${value}/edit`,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setBrand(response?.data?.data?.brands);
    }
  };

  const getSubcontractStatusOption = async (filterValues) => {
    const bodyData = {
      ...(filterValues?.name && { name: filterValues?.name }),
      per_page: 10,
    };
    const response = await getData(
      `/api/sub-contract-factory`,
      false,
      bodyData,
    );

    if (response && response?.data?.code === 200) {
      setSubcontractStatusOption(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      inquiry_status: "0",
    });
    // Get all data on first load
    getAllUsers();
    getAllStyles();
    getAllGarmentCategory();
    getAllGarmentType();
    getAllGarmentFor();
    getBuyingOfficeList();
    getSubcontractStatusOption();
  }, []);

  const getInquiryNumber = useCallback(async () => {
    const query = `${GET_INQUIRY_NUMBER}`;
    const payload = {
      received_date: received_date_watch
        ? moment(received_date_watch).format("YYYY-MM-DD")
        : null,
      buying_office_id: buying_office_id_watch,
      buyer_id: buyer_id_watch,
    };

    const response = await postData(query, payload);
    if (response && response?.code === 200) {
      form.setFieldsValue({
        inquiry_no: response?.data,
      });
    } else {
      HandleNotification(
        "error",
        "Error!",
        response?.message || `Failed to get inquiry number`,
      );
    }
  }, [received_date_watch, buying_office_id_watch, buyer_id_watch, form]);

  useEffect(() => {
    if (!received_date_watch || !buying_office_id_watch || !buyer_id_watch)
      return;

    getInquiryNumber();
  }, [
    received_date_watch,
    buying_office_id_watch,
    buyer_id_watch,
    getInquiryNumber,
  ]);

  useEffect(() => {
    // In edit mode set buyer, if buying office is selected
    if (isEdit && buying_office_id_watch) {
      getBuyersList(buying_office_id_watch, {});
      setSelectedBuyingOffice(buying_office_id_watch);
    }
  }, [buying_office_id_watch, isEdit]);

  useEffect(() => {
    // In edit mode set brand, if buyer is selected
    if (isEdit && buyer_id_watch) {
      getBrand(buyer_id_watch, {});
      setSelectedBuyer(buyer_id_watch);
    }
  }, [buyer_id_watch, isEdit]);

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          order_status: "Waiting For Feedback",
        }}
      >
        <Card>
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                label=" "
                name="subcontract_status"
                valuePropName="checked"
              >
                <Checkbox>Sub-Contract</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Inquiry No."
                name="inquiry_no"
                rules={[
                  {
                    required: true,
                    message: `Inquiry No. is required `,
                  },
                ]}
              >
                <Input placeholder="Inquiry No." size="small" disabled />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Received Date"
                name="received_date"
                rules={[
                  {
                    required: true,
                    message: `Received Date is required `,
                  },
                ]}
                initialValue={moment()}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                  align="right"
                  size="small"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="received_by" hidden />
              <Form.Item
                label="Merchandiser"
                name="received_by_name"
                rules={[
                  {
                    required: true,
                    message: `Merchandiser is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select user"
                  optionFilterProp="children"
                  // disabled={view}
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllUsers({ name: value })}
                  allowClear
                  onSelect={(value) => {
                    form.setFieldsValue({
                      received_by: value,
                    });
                  }}
                >
                  {isArrayAndHasValue(users) &&
                    users?.map((userItem, index) => (
                      <Option value={userItem?.id} key={userItem?.id || index}>
                        {userItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Style No." name="style_id">
                <Select
                  showSearch
                  placeholder="Select style"
                  optionFilterProp="children"
                  // disabled={view}
                  disabled={viewMode}
                  size="small"
                  onSearch={(value) => getAllStyles({ style_no: value })}
                  allowClear
                  onSelect={(value) => getSelectedStyleInfo(value)}
                  onClear={() => {
                    form.setFieldsValue({
                      buying_office_id: null,
                      buying_office_name: null,
                      buyer_id: null,
                      buyer_name: null,
                      buyer_short_name: null,
                      brand_id: null,
                      brand_name: null,
                      garment_category_id: null,
                      garment_type_id: null,
                      garment_for_id: null,
                      description: null,
                      sub_contract_factory_id: null,
                      address: null,

                      inquiry_no: null,
                    });
                  }}
                >
                  {isArrayAndHasValue(styles) &&
                    styles?.map((styleItem, index) => (
                      <Option
                        value={styleItem?.id}
                        key={styleItem?.id || index}
                      >
                        {styleItem?.style_no || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {subcontract_status ? (
              <>
                <Col span={4}>
                  <Form.Item
                    label="Factory Name"
                    name="sub_contract_factory_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Factory Name!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Factory Name"
                      disabled={viewMode}
                      allowClear
                      size="small"
                      onChange={(value) => {
                        const selectedFactory = subcontractStatusOption.find(
                          (item) => item.id === value,
                        );
                        form.setFieldsValue({
                          address: selectedFactory?.address,
                          inquiry_no: `${
                            selectedFactory?.name
                          }_${moment().format("YY-MM-DD")}`
                            .split(" ")
                            .join("_"),
                        });
                      }}
                    >
                      {isArrayAndHasValue(subcontractStatusOption) &&
                        subcontractStatusOption.map((sup) => (
                          <Option value={sup?.id} key={sup?.id}>
                            {sup.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Address" name="address">
                    <Input placeholder="Address" size="small" disabled />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={4}>
                  <Form.Item
                    label="Buying office"
                    name="buying_office_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Buying office!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buying office"
                      onChange={(value) => {
                        if (value) {
                          getBuyersList(value);
                          setSelectedBuyingOffice(value);
                        } else {
                          form.resetFields(["buyer_id", "brand_id"]);
                          setBuyer([]);
                          setBrand([]);
                          setSelectedBuyingOffice(null);
                        }
                      }}
                      optionFilterProp="children"
                      disabled={viewMode || style_id_watch}
                      size="small"
                      onSearch={(value) => {
                        getBuyingOfficeList({ name: value });
                      }}
                      allowClear
                      onClear={() => getBuyingOfficeList()}
                    >
                      {isArrayAndHasValue(buyingOffice) &&
                        buyingOffice.map((sup) => (
                          <Option value={sup?.id} key={sup?.id}>
                            {sup?.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="buyer_short_name" hidden />
                  <Form.Item
                    label="Buyer"
                    name="buyer_id"
                    rules={[
                      { required: true, message: "Please Select Buyer!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Buyer"
                      onChange={(value) => {
                        if (value) {
                          getBrand(value);
                          setSelectedBuyer(value);
                        } else {
                          form.resetFields(["brand_id"]);
                          setBrand([]);
                          setSelectedBuyer(null);
                        }
                      }}
                      optionFilterProp="children"
                      disabled={viewMode || style_id_watch}
                      size="small"
                      onSearch={(value) =>
                        getBuyersList(selectedBuyingOffice, { name: value })
                      }
                      allowClear
                      onClear={() => getBuyersList(selectedBuyingOffice)}
                    >
                      {isArrayAndHasValue(buyer) &&
                        buyer.map((buy) => (
                          <Option value={buy?.id} key={buy?.id}>
                            {buy?.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Brand"
                    name="brand_id"
                    rules={[
                      { required: false, message: "Please input Barnd!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Brand"
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   option.children.toLowerCase().includes(input.toLowerCase())
                      // }
                      onSearch={(value) =>
                        getBrand(selectedBuyer, { name: value })
                      }
                      disabled={viewMode || style_id_watch}
                      size="small"
                      allowClear
                      onClear={() => setBrand([])}
                    >
                      {brand?.length &&
                        brand?.map((pi) => (
                          <Option value={pi?.id} key={pi?.id}>
                            {pi?.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={4}>
              <Form.Item
                label="Garments Category"
                name="garment_category_id"
                rules={[
                  {
                    required: true,
                    message: `Garments Category is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a category"
                  optionFilterProp="children"
                  size="small"
                  disabled={viewMode || style_id_watch}
                  onSearch={(value) => getAllGarmentCategory({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(garmentCategoryList) &&
                    garmentCategoryList?.map((categoryItem, index) => (
                      <Option
                        value={categoryItem?.id}
                        key={categoryItem?.id || index}
                      >
                        {categoryItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Garments Type"
                name="garment_type_id"
                rules={[
                  {
                    required: true,
                    message: `Garments Type is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a type"
                  optionFilterProp="children"
                  size="small"
                  disabled={viewMode || style_id_watch}
                  onSearch={(value) => getAllGarmentType({ name: value })}
                  allowClear
                >
                  {isArrayAndHasValue(garmentTypeList) &&
                    garmentTypeList?.map((typeItem, index) => (
                      <Option value={typeItem?.id} key={typeItem?.id || index}>
                        {typeItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Department"
                name="garment_for_id"
                rules={[
                  {
                    required: true,
                    message: `Department is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select for"
                  optionFilterProp="children"
                  size="small"
                  onSearch={(value) => getAllGarmentFor({ name: value })}
                  allowClear
                  disabled={viewMode || style_id_watch}
                >
                  {isArrayAndHasValue(garmentForList) &&
                    garmentForList?.map((typeItem, index) => (
                      <Option value={typeItem?.id} key={typeItem?.id || index}>
                        {typeItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Inquiry Status"
                name="inquiry_status"
                rules={[
                  {
                    required: true,
                    message: `Inquiry Status is required `,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select status"
                  optionFilterProp="children"
                  disabled={viewMode}
                  size="small"
                  allowClear
                >
                  {isArrayAndHasValue(inquiryStatus) &&
                    inquiryStatus?.map((statusItem, index) => (
                      <Option
                        value={statusItem?.value}
                        key={statusItem?.id || index}
                      >
                        {statusItem?.name || ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Exp. Order Qty."
                name="expected_order_quantity"
                rules={[
                  {
                    required: true,
                    message: `Exp. Order Qty. is required `,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="small"
                  placeholder="Exp. Order Qty."
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="Production Month From"
                name="production_month_from"
                // initialValue={moment()}
                rules={[
                  {
                    required: false,
                    message: `From Date is required `,
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                  align="right"
                  size="small"
                  // disabled={view}
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Production Month To"
                name="production_month_to"
                // initialValue={moment()}
                rules={[
                  {
                    required: false,
                    message: `To Date is required `,
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                  align="right"
                  size="small"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="Exp. Delivery Date"
                name="expected_delivery_date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: `Exp. Delivery Date is required `,
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{
                    width: "100%",
                  }}
                  align="right"
                  size="small"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: `Description is required `,
                  },
                ]}
              >
                <TextArea
                  size="small"
                  placeholder="Description"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="Season"
                name="season"
                rules={[
                  {
                    required: false,
                    message: `Season is required `,
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  size="small"
                  placeholder="Season"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Size Range"
                name="size_range"
                rules={[
                  {
                    required: false,
                    message: `Size Range is required `,
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  size="small"
                  placeholder="Size Range"
                  disabled={viewMode}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <DocumentUpload
          DOC_ADD={STYLE_ADD_DOC}
          setFileList={setFileList}
          filesList={filesList}
          view={viewMode}
          header="Attachment *"
          preview={true}
          showType={true}
          showReference={true}
        />
        {isEdit && (
          <>
            <OBForm editInfo={editInfo} />
            <CMForm CMInfo={CMInfo} inquiryId={inquiryId} viewMode={viewMode} />
            <OrderConfirmationForm form={form} viewMode={viewMode} />
          </>
        )}
      </Form>
    </div>
  );
});

export default BuyerInquiryForm;
