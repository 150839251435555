import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_VIEW,
  STYLE_WISE_COST_EDIT,
  REST_SALES_CONTRACT,
} from "../../../../apiServices/API_ENDPOINTS";

import {
  WH_EXCEPTIONS,
  WEEKEND_HOLIDAY_LIST
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import HandleNotification from "../../../../common/Notification";
const { Option } = Select;

const ExceptionForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
 // const [code, setCode] = useState("");
 const [weekendHolidayExceptionList, setWeekendHolidayExceptionList] = useState([]);
 const [selectedHoiday, setSelectedHoliday] = useState(null);

  // Router
  const navigate = useNavigate();
  const { id } = useParams();

  const getWeekendHolidayExceptionList = useCallback(async () => {
    let response = await getData(WEEKEND_HOLIDAY_LIST);
     console.log('response', response);
    if (response && response?.status === 200) {
      setWeekendHolidayExceptionList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  const onSelectWeekendHoliday = (value) => {
    const foundItem = weekendHolidayExceptionList.find((item) => item.serial === value);
    setSelectedHoliday(foundItem);
    //console.log('foundItem', foundItem);
      
  };

  const getSelectedExceptionInfo = useCallback(
    async (id) => {
      const query = `${WH_EXCEPTIONS}/${id}/show`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {  
        
        form.setFieldsValue({
          weekend_holiday_id: response?.data?.data?.serial,
          reason: response?.data?.data?.reason,
        });
        
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching weekend Info",
          null,
        );
      }
    },
    [form],
  );

  // const handleNameChange = (e) => {
  //   const name = e.target.value;
  //   // Replace spaces and special characters with dashes
  //   const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
  //   setCode(codeValue);
  //   form.setFieldsValue({ code: codeValue });
  // };

  // Effects
  useEffect(() => {
    getWeekendHolidayExceptionList();
  }, [getWeekendHolidayExceptionList]);

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getSelectedExceptionInfo(id);
    }
  }, [editMode, id, getSelectedExceptionInfo]);

  const onFinishException = async (values) => {
    //  console.log("values", values);  
     // console.log("selectedHoiday", selectedHoiday);
    const query = editMode
    ? `${WH_EXCEPTIONS}/${id}`
    : `${WH_EXCEPTIONS}`;

    const bodyData = {
      serial: selectedHoiday?.serial,
      weekend_holiday_id: selectedHoiday?.id,
      type_name: selectedHoiday?.type,      
      reason: values?.reason,
    };
    
    const response = editMode ? await putData(query, bodyData) : await postData(query, bodyData);
    
    //console.log("response", response);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Weekend Holiday Exception ${editMode ? "updated" : "added"} successfully!`,
        null,
      );
      form.resetFields();
      navigate("/production-planning/exceptions");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} exception!`,
        null,
      );
    }
  };

  

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishException}
      >        

        <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Weekend/Holiday" name="weekend_holiday_id">
            <Select              
              style={{
                width: "100%",
              }}
              placeholder="Date : Title"
              optionFilterProp="children"
              autocomplete="off"
              onSelect={(value) => {
                    onSelectWeekendHoliday(value);
                  }} 
            >
              {weekendHolidayExceptionList.map((option) => {
                return (
                  <option
                    key={option.serial}
                    value={option.serial}
                  >
                    {option?.date}: {option?.name}
                  </option>
                );
              })}
             
            </Select>
          </Form.Item>
        </Col>
          <Col className="gutter-row" span={12}>
          <Form.Item
              label="Reason"
              name="reason"
              
            >
              <Input placeholder="Reason"   />
            </Form.Item>
          </Col>
          
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Entry date"
              name="entry_date"
              initialValue={moment().format("YYYY-MM-DD")}
            >
              <Input placeholder="Entry Date" disabled size="small"   />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Entry User"
              name="entry_user"
              initialValue={localStorage.getItem("name") || "User"}
            >
              <Input placeholder="Entry Date" disabled size="small"   />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ExceptionForm;
