import { Alert, Empty, Typography } from "antd";
import { postData } from "apiServices/common";
import { useEffect, useState } from "react";
import LineData from "./LineData";

const DefectTracker = ({ line_id = 20 }) => {
  const [defectTrackerData, setDefectTrackerData] = useState({});

  const initialGetData = async () => {
    const response = await postData(
      "/api/digitalization/bigscreen-dashboard/defect-tracker",
      {
        line_id: line_id,
        date: "2024-09-17",
      },
    );

    if (response && response?.code === 200) {
      const data = response?.data;

      setDefectTrackerData(data);
    }
  };

  useEffect(() => {
    initialGetData();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Typography.Title
        level={3}
        style={{
          textAlign: "center",
        }}
      >
        Defect Tracker
      </Typography.Title>
      {defectTrackerData && defectTrackerData?.length > 0 ? (
        defectTrackerData?.map((p) => (
          <LineData key={p?.id} data={p?.chart_data} />
        ))
      ) : (
        <Empty
          style={{ width: "33%", margin: "auto", marginTop: "10%" }}
          description={
            <Alert
              message="No Data Found with selected line-id in Production Tracker"
              type="warning"
            />
          }
        />
      )}
    </div>
  );
};

export default DefectTracker;
