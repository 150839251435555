import {
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Affix, Button, Card, Dropdown, Menu, Space, Table } from "antd";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SUB_CONTRACT_AGREEMENT } from "../../apiServices/API_ENDPOINTS";
import { getData } from "../../apiServices/common";
import {
  SALES_CONTRACT_CREATE_PERMISSION,
  SALES_CONTRACT_EDIT_PERMISSION,
  SALES_CONTRACT_VIEW_PERMISSION,
} from "../../routes/permissions";
import { formatNumberWithCommas, hasPermission } from "../../utils/functions";
import { isMobileView } from "../Common/AppHelper";
import Header from "../Common/Header";

export default function SubSalesContract(props) {
  // Props
  const { width } = props;

  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState();
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState(10);

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  const columns = [
    {
      title: "Reference No",
      dataIndex: "reference_no",
      key: "reference_no",
    },
    {
      title: "Factory Name",
      dataIndex: ["factory_info", "name"],
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "contract_date",
      key: "id",
    },
    {
      title: "Value",
      dataIndex: "contract_value",
      key: "id",
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "id",
    },
    {
      title: "",
      key: "action",
      align: "right",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllPermitList = useCallback(
    async (passedObject) => {
      const filter = passedObject;
      const customQuery = `${SUB_CONTRACT_AGREEMENT}?page=${currentPage}&per_page=${rangeData}`;
      const res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage, rangeData],
  );

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, getAllPermitList, filterData]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link to={`/merchandising/update-sub-sales-contact/${data.id}`}>
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([SALES_CONTRACT_EDIT_PERMISSION]),
        },
        {
          label: (
            <Link to={`/merchandising/view-sub-sales-contact/${data.id}`}>
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([SALES_CONTRACT_VIEW_PERMISSION]),
        },
        // {
        //   label: (
        //     <Link to={`/merchandising/amend-sales-contact/${data.id}`}>
        //       <PlusOutlined /> Amend
        //     </Link>
        //   ),
        //   key: "2",
        //   disabled: !hasPermission([SALES_CONTRACT_EDIT_PERMISSION]),
        // },
        {
          label: (
            <Link to={`/merchandising/sub-sales-contact-size-specs/${data.id}`}>
              <PlusOutlined /> Size Spec
            </Link>
          ),
          key: "3",
          disabled: !hasPermission([SALES_CONTRACT_EDIT_PERMISSION]),
        },
      ]}
    />
  );
  const paginate = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const extra = (
    <Space>
      <Button
        type={showFilter ? "danger" : "primary"}
        size="small"
        onClick={() => setShowFilter((prev) => !prev)}
        style={{ float: "right" }}
      >
        {`${showFilter ? "Close" : "Open"} Filter Option`}
      </Button>
      <Button
        key="3"
        size="small"
        type="primary"
        disabled={!hasPermission([SALES_CONTRACT_CREATE_PERMISSION])}
      >
        <Link to="/merchandising/add-sub-sales-contact">
          {hasPermission([SALES_CONTRACT_CREATE_PERMISSION])
            ? "Add New"
            : "No Permission"}
        </Link>
      </Button>
    </Space>
  );

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header title="Sub Contract" subTitle="" actions={extra} />
        </Card>
      </Affix>

      <div className="containt-body">
        <Card>
          <FilterComponent
            tableData={permitList}
            filterAPI={getAllPermitList}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterName="sub_sales_contract"
          />
          <Table
            loading={loading}
            dataSource={permitList}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              current: currentPage,
              total: totalData,
              pageSize: rangeData,
              onChange: (page) => paginate(page),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Card>
      </div>
    </Fragment>
  );
}
