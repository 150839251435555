import { Fragment, useEffect } from "react";
import { Select, InputNumber } from "antd";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import { CopyOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const AddForm = (props) => {
  // Props
  const {
    warehouseList,
    getAllWarehouse,
    onWarehouseSelect,
    onRackSelect,
    onBinSelect,
    unitList,
    getAllUnit,
    onSelectUnit,
    onChangeQuantity,
    checkedStyleItems,
    movementType,
    onCopyRow,
    unitConvert,
    copyToNextRows,
    assignType,
    getTrackItemsForAdd,
  } = props;

  // Antd Constants
  const { Option } = Select;

  useEffect(() => {
    if(assignType === 'add') {
      getTrackItemsForAdd();
    }
  }, [assignType]);

  return (
    <div style={{ display: "flex", marginBottom: 30 }}>
      <div className="custome-table">
        <table>
          {isArrayAndHasValue(checkedStyleItems) && (
            <thead>
              <tr>
                <th className="t-header">Warehouse</th>
                <th className="t-header">Rack</th>
                <th className="t-header">Bin</th>
                <th className="t-header">Item Type</th>
                <th className="t-header">Item</th>
                <th className="t-header">Unit</th>
                <th className="t-header">Balance</th>
                <th className="t-header">{`${
                  movementType === "incoming" ? "Receive" : "Issue"
                } Qty`}</th>
                <th className="t-header">Actions</th>
              </tr>
            </thead>
          )}

          <tbody>
            {isArrayAndHasValue(checkedStyleItems) ? (
              <>
                {checkedStyleItems.map((product, index) => {
                  return (
                    <Fragment key={product?.unique_id}>
                      <tr
                        className={product?.is_add ? "is-added-item" : ""}
                      >
                        {/* Warehouse */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              onSearch={(value) =>
                                getAllWarehouse({ name: value })
                              }
                              onSelect={(value) =>
                                onWarehouseSelect(value, product?.unique_id)
                              }
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              size="small"
                              allowClear
                              onClear={() => {
                                getAllWarehouse();
                              }}
                              value={product?.warehouse_id}
                            >
                              {isArrayAndHasValue(warehouseList) &&
                                warehouseList?.map((item, index) => (
                                  <Option
                                    key={item?.id}
                                    value={item?.id}
                                    label={item?.name}
                                  >
                                    {item?.name || ""}
                                  </Option>
                                ))}
                            </Select>
                            <span
                              onClick={() => {
                                copyToNextRows(product?.unique_id);
                              }}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* Rack */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              onSelect={(value) =>
                                onRackSelect(value, product?.unique_id)
                              }
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              size="small"
                              value={product?.rack_id}
                            >
                              {isArrayAndHasValue(product?.racksList) &&
                                product?.racksList?.map((item, index) => (
                                  <Option
                                    key={item?.id}
                                    value={item?.id}
                                    label={`${item?.description} - ${item?.name}`}
                                  >
                                    {`${item?.description} - ${item?.name}` ||
                                      ""}
                                  </Option>
                                ))}
                            </Select>
                            <span
                              onClick={() => {
                                copyToNextRows(product?.unique_id);
                              }}
                              hidden={!product?.warehouse_id}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* Bin */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              onSelect={(value) =>
                                onBinSelect(value, product?.unique_id)
                              }
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              size="small"
                              value={product?.bin_id}
                            >
                              {isArrayAndHasValue(product?.binList) &&
                                product?.binList?.map((item, index) => (
                                  <Option
                                    key={item?.id}
                                    value={item?.id}
                                    label={`${item?.description} - ${item?.name}`}
                                  >
                                    {`${item?.description} - ${item?.name}` ||
                                      ""}
                                  </Option>
                                ))}
                            </Select>
                            <span
                              onClick={() => {
                                copyToNextRows(product?.unique_id);
                              }}
                              hidden={!product?.rack_id}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* Item Type */}
                        <td className="t-body">
                          {product?.item_category_info?.name || ""}
                        </td>
                        {/* Item */}
                        <td className="t-body">
                          {product?.item_info?.name || ""}
                        </td>
                        {/* Unit */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <Select
                              // onSearch={(value) => getAllUnit({ name: value })}
                              onSelect={(value) => {
                                onSelectUnit(value, product?.unique_id);
                                unitConvert(
                                  value,
                                  product?.shipment_qty,
                                  product?.unit_id,
                                  product?.unique_id,
                                );
                              }}
                              showSearch
                              style={{ width: "100px" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              size="small"
                              allowClear
                              onClear={() => {
                                getAllUnit();
                              }}
                              value={product?.convertion_unit_id}
                            >
                              {isArrayAndHasValue(unitList) &&
                                unitList?.map((item, index) => (
                                  <Option
                                    key={item?.id}
                                    value={item?.id}
                                    label={item?.name}
                                  >
                                    {item?.name || ""}
                                  </Option>
                                ))}
                            </Select>
                            <span
                              onClick={() => {
                                copyToNextRows(product?.unique_id);
                              }}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* Balance */}
                        <td className="t-body">
                          {product?.balance_quantity
                            ? commaSeparateNumber(product?.balance_quantity/product?.convertion_rate)
                            : 0}
                        </td>
                        {/* Receive / Issue Quantity */}
                        <td className="t-body">
                          <div
                            className="centered-item-parent"
                            style={{ gap: "10px" }}
                          >
                            <InputNumber
                              style={{ width: "100px" }}
                              placeholder="Enter Qty"
                              formatter={(value) => commaSeparateNumber(value)}
                              size="small"
                              onChange={debounce((value) => {
                                onChangeQuantity(value, product?.unique_id);
                                unitConvert(
                                  product?.convertion_unit_id,
                                  value,
                                  product?.unit_id,
                                  product?.unique_id,
                                );
                              }, 500)}
                              value={product?.shipment_qty || 0}
                            />
                            <span
                              onClick={() => {
                                copyToNextRows(product?.unique_id);
                              }}
                            >
                              <CopyOutlined />
                            </span>
                          </div>
                        </td>
                        {/* Actions */}
                        <td className="t-body">
                          <span onClick={() => onCopyRow(product, index)}>
                            <CopyOutlined />
                            <span className="ml-2">Copy</span>
                          </span>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddForm;
