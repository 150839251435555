import { Col, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { SALES_CONTRACT_LIST } from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
import { isArrayAndHasValue } from "../../../utils/functions";

const ProductionDetailsFilterFileds = ({ initialTableData }) => {
  // States
  const [salesContractList, setSalesContractList] = useState([]);

  // Antd
  const { Option } = Select;

  useEffect(() => {
    getSalesContractList();
  }, []);

  const getSalesContractList = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      reference_no: filterValues?.reference_no || "",
    };
    const response = await getData(SALES_CONTRACT_LIST, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSalesContractList(response?.data?.data?.data || []);
    }
  };

  return (
    <>
      <Col span={4}>
        <Form.Item
          label="Sales Contract No"
          name="sales_contract_id"
          required
          rules={[
            {
              required: true,
              message: "Sales Contract is required",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a Sales Contract No"
            optionFilterProp="children"
            size="small"
            onSearch={(value) => getSalesContractList({ reference_no: value })}
          >
            {isArrayAndHasValue(salesContractList) &&
              salesContractList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.reference_no}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default ProductionDetailsFilterFileds;
