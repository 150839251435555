import React from "react";
import {
  Col,
  Input,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
  Switch,
  Layout,
  Table,
  Checkbox,
  InputNumber,
} from "antd";
import { useDispatch } from "react-redux";
import { onGetStyleList } from "../../../redux/actions";
import { commaSeparateNumber } from "../../../utils/functions";
import moment from "moment";
const { Option } = Select;

const ShipmentForm = (props) => {
  const dispatch = useDispatch();

  const {
    selectedShipping,
    setChecked,
    onChangeInternalPO,
    styleList,
    styleDetails,
    onShippingDateChange,
    onChangeStyle,
    onChangeOriginalPO,
    selectedPoStatus,
  } = props;

  const dateFormat = "YYYY-MM-DD";

  const onChange = (checked) => {
    if (checked) {
      setChecked("yes");
    } else {
      setChecked("no");
    }
  };

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Style No"
            name="style_id"
            rules={[
              {
                required: true,
                message: `Style No is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeStyle}
              onSearch={(value) => dispatch(onGetStyleList(1, 10, value))}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleList?.map((style, idx) => (
                <Option key={idx} value={style.id} label={style.style_no}>
                  {" "}
                  {style.style_no + " : " + style?.description || "--"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Buying Office"
            rules={[
              {
                required: true,
                message: `Buying Office is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={styleDetails?.style?.buying_office_info?.name}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Sales Contract No."
            rules={[
              {
                required: true,
                message: `This field is required `,
              },
            ]}
          >
            <Input
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.active_sales_contract_map_info
                  ?.active_sales_contract_info?.reference_no
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Order Quantity"
            rules={[
              {
                required: true,
                message: `Order Quantity is required `,
              },
            ]}
          >
            <InputNumber
              onChange={onChangeStyle}
              disabled={true}
              value={
                styleDetails?.style?.sales_contract_map_info?.order_quantity
              }
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Internal PO"
            name="internal_po"
            rules={[
              {
                required: true,
                message: `Internal PO is required `,
              },
            ]}
          >
            <Select
              onChange={onChangeInternalPO}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.internal_po}
                    label={po?.internal_po}
                    po_status={po?.po_status}
                  >
                    {po?.internal_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Original PO" name="original_po">
            <Select
              onChange={onChangeOriginalPO}
              showSearch
              style={{
                width: "100%",
              }}
              rules={[
                {
                  required: true,
                  message: `Original PO is required `,
                },
              ]}
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.po_info?.map(
                (po, idx) => (
                  <Option
                    key={idx}
                    internal_po={po?.internal_po}
                    original_po={po?.original_po}
                    sales_contract_id={po?.sales_contract_id}
                    value={po?.original_po}
                    label={po?.original_po}
                    po_status={po?.po_status}
                  >
                    {po?.original_po}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title style={{ fontSize: 14 }}>
        Shipment Summary
      </Typography.Title>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item label="Total Packed (pc.)">
            <InputNumber
              disabled={true}
              value={selectedShipping?.total_packing}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total Shipped (pc)">
            <Input
              disabled={true}
              value={selectedShipping?.total_shipping}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Transfer To Inventory (pc)">
            <Input
              disabled={true}
              value={selectedShipping?.total_transfered}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Garments Remains (pc.)">
            <InputNumber
              disabled={true}
              value={selectedShipping?.total_remain_shipping}
              style={{ width: "100%" }}
              formatter={(value) => commaSeparateNumber(value)}
              precision={2}
            />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title style={{ fontSize: 14 }}>
        Shipment Details
      </Typography.Title>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Shipment Date"
            name="shipping_date"
            rules={[
              {
                required: true,
                message: `Shipment Date is required `,
              },
            ]}
            initialValue={moment()}
          >
            <DatePicker
              onChange={onShippingDateChange}
              format={dateFormat}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Mark As Full Shipment" name="mark_close">
            <Switch
              checkedChildren="Yes"
              onChange={onChange}
              unCheckedChildren="No"
              defaultChecked={selectedPoStatus === "Closed" ? true : false}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ShipmentForm;
