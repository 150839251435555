import React, { Fragment, useState } from "react";
import { Card, Button, Modal, message, Select, Input, Row, Col, Image } from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import './custom_input.css';
import { isArrayAndHasValue } from "../../../utils/functions";

const attachmentTypes = [
  {
    id: 1,
    name: "Sample",
  },
  {
    id: 2,
    name: "Tech Pack",
  },
  {
    id: 3,
    name: "Sketch",
  },
  {
    id: 4,
    name: "Other",
  },
];


export default function DocumentUpload(props) {
  // Props
  const { DOC_ADD, filesList, setFileList, view, header, preview, showType, showReference, singleFile } = props;


  // States
  const [loading, setLoading] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  // Constants
  const api_base_url = process.env.REACT_APP_BASE_URL;

  // Antd
  const { confirm } = Modal;
  const { Option } = Select;

  const addToFilesList = (newFileObject) => {
    let filesListCopy = [...filesList];
    const newData = {
      id: newFileObject.id,
      name: newFileObject.document_name,
      path: newFileObject.document,
      is_delete: 0,
    };

    if(singleFile) {
      // If supports only single file
      filesListCopy = [newData];
    }
    else {
      // If supports multiple files
      filesListCopy.push(newData);
    }
    
    setFileList(filesListCopy);
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const URL = process.env.REACT_APP_BASE_URL + DOC_ADD;
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    fetch(URL, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        addToFilesList(result.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message || "Error uploading file!");
      });
  };

  const removeFile = (id) => {
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const filesListCopy = [...filesList];
        const foundFile = filesListCopy.find((file) => file.id === id);
        foundFile["is_delete"] = 1;
        setFileList([...filesListCopy]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onChangeAttachmentType = (value, id, context) => {
    const filesListCopy = [...filesList];
    const foundItem = filesListCopy.find((item) => item.id === id);
    const foundType = attachmentTypes.find((type) => type.id === value);
    foundItem[context] = foundType?.name;
    setFileList([...filesList]);
  };

  const onChangeFileData = (value, id, context) => {
    const filesListCopy = [...filesList];
    const foundItem = filesListCopy.find((item) => item.id === id);
    foundItem[context] = value;
    setFileList([...filesList]);
  }

  const showFileName = (name) => {
    if(typeof name === "string") {
      // split the string to show first 20 characters
      const first20Chars = name.slice(0, 20);
      const result = name.length > 20 ? `${first20Chars}...` : first20Chars;
      return result;
    }
    else {
      return "";
    }
  }

  const detectFileType = (file) => {
    // Detect file type from extension at end of the file name
    const fileName = file.path || file.document;
    const extension = fileName.split(".").pop();
    const fileType = extension.toLowerCase();
    return fileType || null;
  };

  const isImage = (fileType) => {
    if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Card
      className="custome-table"
      title={
        <span style={{ fontSize: "14px" }}>
          {!view && `${header ? header : "Documents"}`}
        </span>
      }
    >
      <Row gutter={6}>
        <Col span={12}>
          <div className="custome-table">
            <table>
              <thead>
                <tr>
                  <th className="t-header bc-java">Sl</th>
                  {showType && (
                    <th className="t-header bc-java">Attachment Type</th>
                  )}
                  <th className="t-header bc-java">File Name</th>
                  {showReference && (
                    <th className="t-header bc-java">Reference No.</th>
                  )}
                  <th className="t-header bc-java" colSpan={3}>
                    File
                  </th>
                </tr>
              </thead>
              <tbody>
                {isArrayAndHasValue(filesList) ? (
                  <>
                    {filesList
                      ?.filter((item) => !item?.is_delete)
                      ?.map((file, index) => (
                        <Fragment key={file?.id}>
                          <tr>
                            <td className="t-body">{index + 1}</td>
                            {showType && (
                              <td className="t-body">
                                <Select
                                  showSearch
                                  placeholder="Select type"
                                  optionFilterProp="children"
                                  disabled={view}
                                  size="small"
                                  allowClear
                                  onSelect={(value) =>
                                    onChangeAttachmentType(
                                      value,
                                      file?.id,
                                      "attachment_type",
                                    )
                                  }
                                  value={file?.attachment_type || null}
                                  style={{ width: "150px" }}
                                >
                                  {isArrayAndHasValue(attachmentTypes) &&
                                    attachmentTypes?.map((typeItem, index) => (
                                      <Option
                                        value={typeItem?.id}
                                        key={typeItem?.id || index}
                                      >
                                        {typeItem?.name || ""}
                                      </Option>
                                    ))}
                                </Select>
                              </td>
                            )}
                            <td className="t-body">
                              {showFileName(file.name || file.document_name)}
                            </td>
                            {showReference && (
                              <td className="t-body">
                                <Input
                                  placeholder="Reference No."
                                  size="small"
                                  disabled={view}
                                  onChange={(e) =>
                                    onChangeFileData(
                                      e.target.value,
                                      file?.id,
                                      "reference_no",
                                    )
                                  }
                                  value={file?.reference_no || null}
                                  style={{ width: "150px" }}
                                />
                              </td>
                            )}
                            <td className="t-body">
                              <span
                                onClick={() =>
                                  window.open(
                                    api_base_url +
                                      "/" +
                                      (file.path || file.document),
                                    "_blank",
                                  )
                                }
                              >
                                <FilePdfOutlined style={{ fontSize: "16px" }} />
                              </span>
                            </td>
                            {!view && (
                              <td className="t-body">
                                <DeleteOutlined
                                  style={{ fontSize: "16px", color: "red" }}
                                  className="cursore-pointer"
                                  onClick={() => removeFile(file?.id)}
                                />
                              </td>
                            )}
                            {preview && isImage(detectFileType(file)) && (
                              <td className="t-body">
                                <span
                                  onClick={() =>
                                    setSelectedImageUrl(
                                      api_base_url +
                                        "/" +
                                        (file.path || file.document),
                                    )
                                  }
                                >
                                  <EyeOutlined
                                    style={{
                                      fontSize: "16px",
                                      color: "#2980B9",
                                    }}
                                  />
                                </span>
                              </td>
                            )}
                          </tr>
                        </Fragment>
                      ))}
                  </>
                ) : (
                  ""
                )}

                <tr>
                  <td className="t-body"></td>
                  <td className="t-body"></td>
                  <td className="t-body"></td>
                  <td className="t-body" colSpan={4}>
                    {!view && (
                      <>
                        <input
                          type="file"
                          id="file-input"
                          hidden
                          onChange={(e) => {
                            console.log("files", e.target.files);
                            uploadFile(e.target.files[0])
                          }}
                          style={{ fontSize: "16px" }}
                          multiple
                        />
                        <label for="file-input" className="file-input-label">
                          {loading ? (
                            <LoadingOutlined />
                          ) : (
                            <UploadOutlined style={{ fontSize: "14px" }} />
                          )}
                          <span style={{ fontSize: "12px" }}>{` Upload`}</span>
                        </label>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col span={12}>
          {selectedImageUrl && (
            <Image width={"100%"} height={"70%"} src={selectedImageUrl} />
          )}
        </Col>
      </Row>
    </Card>
  );
}
