import { Form, Input, Modal, Typography, message } from "antd";
import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { TNA_TODO_ENDPOINT } from "../../../apiServices/API_ENDPOINTS";
import { getData, putData } from "../../../apiServices/common";
import AppContent from "../../../common/AppContent";
import AppPageHeader from "../../../common/PageHeader";
import { NO_PERMISSION_REQUIRED } from "../../../routes/permissions";
import { hasPermission } from "../../../utils/functions";
import ExtraContent from "../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../Configuration/CommonComponents/FilterComponent";

const TNATemplate = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;
  const navigate = useNavigate();

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskId, setTaskId] = useState(0);

  // Antd
  const [addCommentForm] = Form.useForm();
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllTNATemplates = useCallback(
    async (filterValues) => {
      const { todoTask, date_range, buyer_id } = filterValues || {};

      setLoading(true);

      const query = `${TNA_TODO_ENDPOINT}`;
      const bodyData = {
        per_page: 10000,
        ...(buyer_id && { buyer_id: buyer_id }),
        ...(date_range && {
          date_range: [
            moment(date_range[0]).format("YYYY-MM-DD"),
            moment(date_range[1]).format("YYYY-MM-DD"),
          ],
          action_statuses: todoTask,
        }),
      };

      const response = await getData(query, false, bodyData);
      if (response && response?.status === 200) {
        setLoading(false);

        setTableData(response?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting bin data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllTNATemplates(filterData);
  }, [refresh, filterData, getAllTNATemplates]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const closeModel = () => {
    setIsModalOpen(false);
    setTaskId(0);
    addCommentForm.resetFields();
  };

  const onAction = (taskId, status) => {
    try {
      const api = `${TNA_TODO_ENDPOINT}/${taskId}/action-status`;
      putData(api, {
        action_status: status,
      });
      refetch();
      message.success("Action Performed Successfully!");
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const onComment = (taskId, comment) => {
    try {
      const api = `${TNA_TODO_ENDPOINT}/${taskId}/comment`;
      putData(api, {
        comment: comment,
      });
      closeModel();
      refetch();
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                hideAddButton={true}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Todo List</Title>}
          />
        }
        content={
          <>
            <FilterComponent
              tableData={tableData}
              filterAPI={getAllTNATemplates}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterName="tna_todo"
            />

            <div
              className="todo-table"
              style={{
                padding: "20px",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="t-header bc-java">Due Date</th>
                    <th className="t-header bc-java">Buyer</th>
                    <th className="t-header bc-java">Buying Office </th>
                    <th className="t-header bc-java">Sales Contract No </th>
                    <th className="t-header bc-java">SL</th>
                    <th className="t-header bc-java">Task</th>
                    <th className="t-header bc-java">Style(s)</th>
                    <th className="t-header bc-java">PO(s)</th>
                    <th className="t-header bc-java">Status</th>
                    <th className="t-header bc-java" colSpan={2}>
                      Action
                    </th>
                    <th className="t-header bc-java">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tableData) && tableData.length ? (
                    tableData.map((rowData, rowIndex) => (
                      <Fragment key={rowIndex}>
                        {rowData.data.map((nestedData, nestedIndex) => (
                          <tr key={`${rowIndex}-${nestedIndex}`}>
                            {nestedIndex === 0 && (
                              <td
                                className="t-body"
                                rowSpan={rowData.data.length}
                              >
                                {rowData.date}
                              </td>
                            )}
                            <td className="t-body">
                              {nestedData?.buyer?.name}
                            </td>
                            <td className="t-body">
                              {nestedData?.buying_office?.name}
                            </td>
                            <td className="t-body">
                              {nestedData?.reference_no}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div key={taskIndex}>
                                    <span>{taskIndex + 1}</span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div key={taskIndex}>
                                    <span>{task?.title}</span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div key={taskIndex}>
                                    <span>
                                      {task?.task_styles?.length
                                        ? task?.task_styles
                                          ?.map((val) => val?.style_no)
                                          .join(", ")
                                        : "N/A"}
                                    </span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div key={taskIndex}>
                                    <span>
                                      {task?.task_purchase_order_maps?.length
                                        ? task?.task_purchase_order_maps
                                          ?.map((val) => val?.original_po)
                                          .join(", ")
                                        : "N/A"}
                                    </span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div
                                    key={taskIndex}
                                    style={{
                                      backgroundColor:
                                        task?.status === "Regular"
                                          ? "#93cddd"
                                          : task?.status === "Overdue"
                                            ? "#f86fe7"
                                            : "#ffc000",
                                      color: task?.status === "Regular"
                                        ? "#045b73"
                                        : task?.status === "Overdue"
                                          ? "#7a116d"
                                          : "#9f7803",
                                      border: "2px solid white",
                                    }}
                                  >
                                    <span>{task?.status}</span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div
                                    key={taskIndex}
                                    style={{
                                      backgroundColor: "#007635",
                                      color: "white",
                                      padding: "5px",
                                      border: "2px solid white",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onAction(task.id, 3);
                                    }}
                                  >
                                    <span>Done</span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div
                                    key={taskIndex}
                                    style={{
                                      backgroundColor: "#c00000",
                                      color: "white",
                                      padding: "5px",
                                      border: "2px solid white",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onAction(task.id, 2);
                                    }}
                                  >
                                    <span>
                                      {task?.action_status_string === "To Do"
                                        ? "Held"
                                        : "Held"}
                                    </span>
                                  </div>
                                ),
                              )}
                            </td>
                            <td className="t-body">
                              {nestedData.tna_plan_task.map(
                                (task, taskIndex) => (
                                  <div
                                    key={taskIndex}
                                    style={{
                                      color: "blue",
                                      textAlign: "right",
                                    }}
                                    onClick={() => {
                                      setTaskId(task?.id);
                                      setIsModalOpen(true);
                                      addCommentForm.setFieldsValue({
                                        comment: task?.comment || "",
                                      });
                                    }}
                                  >
                                    <span>Add</span>
                                  </div>
                                ),
                              )}
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Modal
                open={isModalOpen}
                onOk={() => addCommentForm.submit()}
                onCancel={closeModel}
                onClose={closeModel}
                title="Add Comment"
              >
                <Form
                  form={addCommentForm}
                  onFinish={(values) => onComment(taskId, values?.comment)}
                  layout="vertical"
                >
                  <Form.Item
                    name={"comment"}
                    label="Comment:"
                    rules={[
                      {
                        required: true,
                        message: "Please input your comment!",
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Add Comment" rows={5} />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </>
        }
      />
    </>
  );
};

export default TNATemplate;
