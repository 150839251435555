import { Col, Form, DatePicker, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  STYLE_MERCHANDISING_LIST,
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";

const AssignmentFilterFields = ({ initialTableData }) => {
  const [buyerList, setBuyerList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  
    // Others
    const { Option } = Select;
  
    useEffect(() => {
      getBuyerList();
      getStyleList();
    }, []);
  
  const saveToStorage = (value, name) => {
    const existingFilter =
      JSON.parse(localStorage.getItem("assignment_filter")) || {};
    existingFilter[name] = value;
    localStorage.setItem("assignment_filter", JSON.stringify(existingFilter));
  };

  const getBuyerList = async (filterValues) => {
    const query = `${GET_BUYER_ENDPOINT}`;
    const bodyData = {
      "per_page": 10,
      "name": filterValues?.name || "",
    };
    let res = await getData(query, false, bodyData);

    if (res) {
      setBuyerList(res?.data?.data?.data || []);
    }
  };

  const getStyleList = async (style_no) => {
    const bodyData = {
      "style_no": style_no,
    }

    let res = await getData(STYLE_MERCHANDISING_LIST, false, bodyData);

    if (res) {
      setStyleList(res?.data?.data?.data || []);
    }
  };

  return (
    <>
    <Col span={4}>
      <Form.Item label="Entry Date" name="assignment_date">
        <DatePicker
          style={{ width: "100%" }}
          onChange={(value) =>
            saveToStorage(moment(value).format("YYYY-MM-DD"), "start")
          }
          size="small"
        />
      </Form.Item>
    </Col>
    <Col span={4}>
    <Form.Item label="Buyer" name="buyer_id">
      <Select
        showSearch
        placeholder="Select a Buyer"
        optionFilterProp="children"
        size="small"
        onSearch={(value) => getBuyerList({ name: value })}
        allowClear
        onClear={() => getBuyerList()}
      >
        {buyerList?.length &&
          buyerList.map((sup) => (
            <Option value={sup.id} key={sup.id}>
              {sup.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  </Col>
  <Col span={4}>
    <Form.Item label="Style No." name="style_id">
      <Select
        showSearch
        placeholder="Select a Style No."
        optionFilterProp="children"
        onSearch={(value) => {
          getStyleList(value);
        }}
        size="small"
        allowClear
        onClear={() => getStyleList()}
      >
        {styleList &&
          styleList.map((sup) => (
            <Option value={sup.id} key={sup.id}>
              {sup.style_no}
            </Option>
          ))}
      </Select>
    </Form.Item>
  </Col>
  </>
  );
};

export default AssignmentFilterFields;
