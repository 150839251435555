import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import {
  GET_SELECTED_SEWING_DATA,
  GET_SEWING_BY_DATE,
  GET_SEWING_LINE_ENDPOINT,
  SWING_ADD,
  UPDATE_SEWING,
} from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";
import HandleNotification from "common/Notification";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";
import SewingTable from "../SewingTable";

const SewingForm = (props) => {
  // Props
  const { isEdit, form, setIsSubmitting } = props;

  // States
  const [sewingLineList, setSewingLineList] = useState(null);
  const [sewingDetailsData, setSewingDetailsData] = useState(null);
  const [sewingTableData, setSewingTableData] = useState(null);

  // Antd
  const { Option } = Select;
  const sewing_date_watch = Form.useWatch("sewing_date", form);
  const line_id_watch = Form.useWatch("line_id", form);
  const [searchForm] = Form.useForm();

  // Others
  const navigate = useNavigate();
  const { lineId, sewingDate, productionHour } = useParams();
  const location = useLocation();

  const createNewSewing = async (values) => {
    setIsSubmitting(true);
    const query = `${SWING_ADD}`;
    const bodyData = {
      line_id: values?.line_id,
      sewing_date: values?.sewing_date
        ? moment(values?.sewing_date).format("YYYY-MM-DD")
        : null,
      production_hour: values?.sewing_hour,
      line_target: values?.line_target,
      sewing_form_data:
        isArrayAndHasValue(sewingTableData) &&
        sewingTableData?.map((item) => {
          return {
            id: item?.id,
            style_id: item?.style_id,
            size_id: item?.size_id,
            color_id: item?.color_id,
            sales_contract_id: item?.sales_contract_id,
            line_id: item?.line_id,
            internal_po: item?.internal_po,
            original_po: item?.original_po,
            assigned: item?.assigned,
            assigned_date: item?.assigned_date
              ? moment(item?.assigned_date).format("YYYY-MM-DD")
              : null,
            bundle_no: item?.bundle_no,
            created_at: item?.created_at
              ? moment(item?.created_at).format("YYYY-MM-DD")
              : null,
            updated_at: item?.updated_at
              ? moment(item?.updated_at).format("YYYY-MM-DD")
              : null,
            cutting_id: item?.cutting_id,
            cutting_date: item?.cutting_date
              ? moment(item?.cutting_date).format("YYYY-MM-DD")
              : null,
            sub_style_id: item?.sub_style_id,
            deleted_at: item?.deleted_at
              ? moment(item?.deleted_at).format("YYYY-MM-DD")
              : null,
            style_no: item?.style_no,
            size_name: item?.size_name,
            color_name: item?.color_name,
            line_name: item?.line_name,
            sub_style_no: item?.sub_style_no,
            reference_no: item?.reference_no,
            buying_office_name: item?.buying_office_name,
            buying_office_id: item?.buying_office_id,
            buyer_name: item?.buyer_name,
            buyer_id: item?.buyer_id,
            total_sewing_piece: item?.total_sewing_piece,
            total_rejected_piece: item?.total_rejected_piece,
            remain_piece: item?.remain_piece,
            sewing_product_id: item?.sewing_product_id,
            sewing_day: item?.sewing_day,
            difference: item?.difference,
            rejected_percent: item?.rejected_percent,
            wip: item?.wip,
            order_quantity: item?.order_quantity,
            po_map_id: item?.po_map_id,
            sewing_piece: item?.sewing_piece || 0,
            rejected_piece: item?.rejected_piece || 0,
            sub_contract_factory_id: item?.sub_contract_factory_id,
          };
        }),
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      // setIsSubmitting(false);
      HandleNotification(
        "success",
        "bottomRight",
        "Sewing created successfully!",
        null,
      );
    } else {
      setIsSubmitting(false);
      HandleNotification(
        "error",
        "bottomRight",
        "Error creating sewing!",
        null,
      );
    }
  };

  const editSelectedSewing = async (values) => {
    setIsSubmitting(true);
    const query = `${UPDATE_SEWING}`;
    const bodyData = {
      line_id: values?.line_id,
      sewing_date: values?.sewing_date
        ? moment(values?.sewing_date).format("YYYY-MM-DD")
        : null,
      production_hour: values?.sewing_hour,
      line_target: values?.line_target,
      sewing_form_data:
        isArrayAndHasValue(sewingTableData) &&
        sewingTableData?.map((item) => {
          return {
            id: item?.id,
            style_id: item?.style_id,
            size_id: item?.size_id,
            color_id: item?.color_id,
            sales_contract_id: item?.sales_contract_id,
            line_id: item?.line_id,
            internal_po: item?.internal_po,
            original_po: item?.original_po,
            assigned: item?.assigned,
            assigned_date: item?.assigned_date
              ? moment(item?.assigned_date).format("YYYY-MM-DD")
              : null,
            bundle_no: item?.bundle_no,
            created_at: item?.created_at
              ? moment(item?.created_at).format("YYYY-MM-DD")
              : null,
            updated_at: item?.updated_at
              ? moment(item?.updated_at).format("YYYY-MM-DD")
              : null,
            cutting_id: item?.cutting_id,
            cutting_date: item?.cutting_date
              ? moment(item?.cutting_date).format("YYYY-MM-DD")
              : null,
            sub_style_id: item?.sub_style_id,
            deleted_at: item?.deleted_at
              ? moment(item?.deleted_at).format("YYYY-MM-DD")
              : null,
            style_no: item?.style_no,
            size_name: item?.size_name,
            color_name: item?.color_name,
            line_name: item?.line_name,
            sub_style_no: item?.sub_style_no,
            reference_no: item?.reference_no,
            buying_office_name: item?.buying_office_name,
            buying_office_id: item?.buying_office_id,
            buyer_name: item?.buyer_name,
            buyer_id: item?.buyer_id,
            total_sewing_piece: item?.total_sewing_piece,
            total_rejected_piece: item?.total_rejected_piece,
            remain_piece: item?.remain_piece,
            sewing_product_id: item?.sewing_product_id,
            sewing_day: item?.sewing_day,
            difference: item?.difference,
            rejected_percent: item?.rejected_percent,
            wip: item?.wip,
            order_quantity: item?.order_quantity,
            po_map_id: item?.po_map_id,
            sewing_piece: item?.sewing_piece || null,
            rejected_piece: item?.rejected_piece || null,
            previous_sewing_piece: item?.previous_sewing_piece || null,
            previous_rejected_piece: item?.previous_rejected_piece || null,
            previous_remain_piece: item?.previous_remain_piece || null,
            history_id: item?.history_id || null,
            sub_contract_factory_id: item?.sub_contract_factory_id,
          };
        }),
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      // setIsSubmitting(false);
      HandleNotification(
        "success",
        "bottomRight",
        "Sewing updated successfully!",
        null,
      );
    } else {
      setIsSubmitting(false);
      HandleNotification(
        "error",
        "bottomRight",
        "Error updating sewing!",
        null,
      );
    }
  };

  const getSewingLines = async (filterValues) => {
    const query = `${GET_SEWING_LINE_ENDPOINT}`;
    const bodyData = {
      ...filterValues,
      per_page: 100,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSewingLineList(response?.data?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Something Went Wrong!", null);
    }
  };

  const getSewingByDate = useCallback(
    async (filterValues) => {
      const query = `${GET_SEWING_BY_DATE}`;
      const bodyData = {
        line_id: line_id_watch ? Number(line_id_watch) : null,
        sewing_date: sewing_date_watch
          ? moment(sewing_date_watch).format("YYYY-MM-DD")
          : null,
      };
      const response = await postData(query, bodyData);

      if (response && response?.code === 200) {
        const sewingHour = response?.data?.production_hour;
        const lineTarget = response?.data?.line_target;
        form.setFieldsValue({
          sewing_hour: sewingHour || sewingHour === 0 ? Number(sewingHour) : 0,
          line_target: lineTarget || lineTarget === 0 ? Number(lineTarget) : 0,
        });

        setSewingDetailsData(response?.data);
        setSewingTableData(response?.data?.sewing_form_data);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something Went Wrong!",
          null,
        );
      }
    },
    [line_id_watch, sewing_date_watch, form],
  );

  const getSelectedSewingData = useCallback(async () => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Extracting query parameters
    const styleParam = searchParams.get("styles").split(",");
    const customStyles = styleParam.map((item) => Number(item));

    const queryParamsObj = {
      line_id: searchParams.get("line_id")
        ? Number(searchParams.get("line_id"))
        : null,
      sewing_date: searchParams.get("sewing_date"),
      production_hour: searchParams.get("production_hour")
        ? Number(searchParams.get("production_hour"))
        : null,
      styles: customStyles,
    };

    // Get selected sewing data when in edit mode
    const query = `${GET_SELECTED_SEWING_DATA}`;
    const bodyData = {
      ...queryParamsObj,
    };

    const response = await postData(query, bodyData);

    if (response && response?.code === 200) {
      const sewingDate = response?.data?.sewing_date;
      const lineId = response?.data?.line_id;

      form.setFieldsValue({
        sewing_date: sewingDate ? moment(sewingDate) : null,
        line_id: lineId ? Number(lineId) : null,
        sewing_hour: response?.data?.production_hour,
        line_target: response?.data?.line_target,
      });

      setSewingTableData(response?.data?.sewing_form_data);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error fetching selected sewing data!",
        null,
      );
    }
  }, [lineId, sewingDate, productionHour, form]);

  useEffect(() => {
    // Get all sewing lines on first load
    getSewingLines();
  }, []);

  useEffect(() => {
    // When sewing date and line id both selected and in add mode
    if (isEdit) return;

    if (sewing_date_watch && line_id_watch) {
      getSewingByDate();
    }
  }, [sewing_date_watch, line_id_watch, getSewingByDate, isEdit]);

  useEffect(() => {
    // In edit mode, get selected sewing data from API
    if (isEdit) {
      getSelectedSewingData();
    }
  }, [getSelectedSewingData, isEdit]);

  const onTableSearch = (values) => {
    const styleValue = values?.style ? values?.style?.toLowerCase() : null;
    const colorSubstyleValue = values?.color_substyle
      ? values?.color_substyle?.toLowerCase()
      : null;
    const sewingTableDataCopy = structuredClone(sewingTableData);

    const filteredData =
      isArrayAndHasValue(sewingTableDataCopy) &&
      sewingTableDataCopy?.filter((item) => {
        // Values convert
        const itemStyle = item?.style_no ? item?.style_no?.toLowerCase() : null;
        const itemColor = item?.color_name
          ? item?.color_name?.toLowerCase()
          : null;
        const itemSubstyle = item?.sub_style_no
          ? item?.sub_style_no?.toLowerCase()
          : null;

        // Check if the style matches
        const isStyleMatch = itemStyle?.includes(styleValue);

        // Check if color_substyle matches either color_name or sub_style_no
        const isColorSubstyleMatch =
          itemColor?.includes(colorSubstyleValue) ||
          itemSubstyle?.includes(colorSubstyleValue);

        // Return true if any of style or color_substyle match
        return isStyleMatch || isColorSubstyleMatch;
      });

    isArrayAndHasValue(filteredData) && setSewingTableData(filteredData);
  };

  const onChangeSewingItem = (value, id, context) => {
    const sewingTableDataCopy = structuredClone(sewingTableData);
    const foundSewingItem = sewingTableDataCopy?.find((item) => item.id === id);
    if (foundSewingItem) {
      foundSewingItem[context] = value;
    }

    // Update wip
    foundSewingItem["wip"] =
      foundSewingItem?.total_wip -
      ((foundSewingItem?.sewing_piece || 0) +
        (foundSewingItem?.rejected_piece || 0));

    setSewingTableData(sewingTableDataCopy);
  };

  const onClearSearch = () => {
    searchForm.resetFields();
    setSewingTableData(sewingDetailsData?.sewing_form_data);
  };

  return (
    <>
      <Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={isEdit ? editSelectedSewing : createNewSewing}
        >
          <Row gutter={6}>
            <Col span={4}>
              <Form.Item
                name="sewing_date"
                label="Sewing Date"
                rules={[
                  {
                    required: true,
                    message: "Sewing Date Required",
                  },
                ]}
                initialValue={moment()}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  size="small"
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Sewing Line"
                name="line_id"
                rules={[{ required: true, message: "Sewing Line Required" }]}
              >
                <Select
                  showSearch
                  onSearch={(value) => {
                    getSewingLines({ name: value });
                  }}
                  onSelect={() => setIsSubmitting(false)}
                  placeholder="Select a Sewing Line"
                  optionFilterProp="children"
                  size="small"
                  disabled={isEdit}
                >
                  {isArrayAndHasValue(sewingLineList) &&
                    sewingLineList.map((substyle) => (
                      <Option value={substyle.id} key={substyle?.id}>
                        {`${substyle?.name}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="sewing_hour" label="Sewing Hour">
                <Input style={{ textAlign: "right" }} size="small" disabled />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="line_target" label="Line Target">
                <Input style={{ textAlign: "right" }} size="small" disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      {/* Sewing Table */}
      {isArrayAndHasValue(sewingTableData) && (
        <>
          {/* Sewing Table Search Form */}
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header={<b>Table Filter</b>} key="1">
              <Form
                form={searchForm}
                layout="vertical"
                onFinish={onTableSearch}
              >
                <Row gutter={6}>
                  <Col span={4}>
                    <Form.Item name="style" label="Style">
                      <Input size="small" placeholder="Style" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="color_substyle" label="Color & Sub-style">
                      <Input size="small" placeholder="Color & Sub-style" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label=" ">
                      <Space>
                        <Button size="small" type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button onClick={onClearSearch} size="small">
                          Clear
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>

          {/* Sewing Details Table */}
          <SewingTable
            sewingTableData={sewingTableData}
            onChangeSewingItem={onChangeSewingItem}
          />
        </>
      )}
    </>
  );
};

export default SewingForm;
