import {
  Card,
  DatePicker,
  InputNumber,
  Divider,
  Space,
  Button,
  PageHeader,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Select, Checkbox, Radio, Table } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import React, { forwardRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  SHIPMENT_HANDOVER_LIST,
  SHIPMENT_HANDOVER_ADD,
  SHIPMENT_HANDOVER_EDIT,
  SHIPMENT_HANDOVER_BUYERS,
  SALES_CONTRACT_LIST,
  ALL_SALES_CONTRACT_LIST
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, getWithData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import { useNavigate } from "react-router-dom";
import { commaSeparateNumber } from "../../../../utils/functions";

const ShipmentOverForm = forwardRef((props, ref) => {
  // Props
  const { form, editMode, shipmentOverId } = props;

  // States

  const [salesContractList, setSalesContractList] = useState([]);

  // Router
  const navigate = useNavigate();

  // Antd
  const sales_contract_id_watch = Form.useWatch("sales_contract_id", form);

  const getSalesContractList = useCallback(async () => {
    let response = await getData(ALL_SALES_CONTRACT_LIST);

    if (response && response?.status === 200) {
      setSalesContractList(response?.data?.data || []);
    } else {
      alertPop(
        "error",
        response?.messages ? response?.messages : "Something Went Wrong!",
      );
    }
  }, []);

  useEffect(() => {
    getSalesContractList();
  }, [getSalesContractList]);

  const onFinishSecurity = async (values) => {
    const bodyData = {
      sales_contract_id: values?.sales_contract_id,
      utilization_decleration_number: values?.utilization_decleration_number,
      utilization_decleration_date: values.utilization_decleration_date
      ? moment(values?.utilization_decleration_date).format("YYYY-MM-DD")
      : null,
      shipment_rcv_date: values.shipment_rcv_date
      ? moment(values?.shipment_rcv_date).format("YYYY-MM-DD")
      : null,
      export_invoice_number: values?.export_invoice_number,
      export_invoice_date: values.export_invoice_date
      ? moment(values?.export_invoice_date).format("YYYY-MM-DD")
      : null,
      export_invoice_value: values?.export_invoice_value,
      carton_qty: values?.carton_qty,
      shipment_qty: values?.shipment_qty,
      vehicle_number: values?.vehicle_number,
      carton_quantity: values?.carton_quantity,
      examination_report: values?.examination_report,
      challan_no: values?.challan_no,
      depot_name: values?.depot_name,
      custom_assesment_date: values.custom_assesment_date
      ? moment(values?.custom_assesment_date).format("YYYY-MM-DD")
      : null,
      custom_examination_date: values.custom_examination_date
      ? moment(values?.custom_examination_date).format("YYYY-MM-DD")
      : null,
      forwarder_name: values?.forwarder_name,
      handover_date: values.handover_date
        ? moment(values?.handover_date).format("YYYY-MM-DD")
        : null,
      export_bill_of_entry_number: values?.export_bill_of_entry_number,
      bill_of_entry_date: values.bill_of_entry_date
        ? moment(values?.bill_of_entry_date).format("YYYY-MM-DD")
        : null
    };

    if (editMode) {
      const response = await postData(
        `${SHIPMENT_HANDOVER_LIST}/${shipmentOverId}/edit`,
        bodyData,
      );

      if (response) {
        alertPop("success", "Shipment Handover Updated Successfully");
        //navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Security Bolt Seal Log Update Failed!");
      }
    } else {
      const response = await postData(SHIPMENT_HANDOVER_ADD, bodyData);

      if (response) {
        alertPop("success", "Shipment Handover Added Successfully");
        //navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Shipment Handover Add Failed!");
      }
    }
  };

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSecurity}
      >
        <Card>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Sales Contract - Master LC"
                name="sales_contract_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Sales Contract - Master LC!",
                  },
                ]}
              >
                <Select
                  placeholder="Sales Contract - Master LC"
                  style={{ width: "100%" }}
                >
                  {salesContractList.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.reference_no}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Utilization Declaration Number"
                name="utilization_decleration_number"
              >
                <Input style={{ width: "100%", color: "#000 !important" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Utilization Declaration Date"
                name="utilization_decleration_date"
              >
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Shipment Receiving Date"
                name="shipment_rcv_date"
              >
                <DatePicker
                  className="w-100"
                  placeholder="Shipment Receiving Date"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Export Invoice Number"
                name="export_invoice_number"
              >
                <Input style={{ width: "100%", color: "#000 !important" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Export Invoice Date" name="export_invoice_date">
                <DatePicker
                  className="w-100"
                  placeholder="Export Invoice Date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Export Invoice value"
                name="export_invoice_value"
              >
                <InputNumber
                  placeholder="Export Invoice value"
                  style={{ width: "100%", color: "#000 !important" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Carton Qty" name="carton_qty">
                <InputNumber
                  style={{ width: "100%", color: "#000 !important" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Shipment Qty" name="shipment_qty">
                <InputNumber
                  style={{ width: "100%", color: "#000 !important" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Vehicle Number" name="vehicle_number">
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Challan Number" name="challan_no">
                <Input className="w-100" placeholder="Challan Number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Depot Name" name="depot_name">
                <Select placeholder="Depot Name" style={{ width: "100%" }}>
                  <option value="OCL">OCL</option>
                  <option value="SAPL">SAPL</option>
                  <option value="INCONTRADE">INCONTRADE</option>
                  <option value="BIAM">BIAM</option>
                  <option value="KNT">KNT</option>
                  <option value="ESACK">ESACK</option>
                  <option value="QNS">QNS</option>
                  <option value="VERTEX">VERTEX</option>
                  <option value="SHAFI MOTORS">SHAFI MOTORS</option>
                  <option value="KDS">KDS</option>
                  <option value="GOLDEN">GOLDEN</option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Customs Assessment Date"
                name="custom_assesment_date"
              >
                <DatePicker
                  className="w-100"
                  placeholder="Customs Assessment Date"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Customs Examination Date"
                name="custom_examination_date"
              >
                <DatePicker
                  className="w-100"
                  placeholder="Customs Examination Date"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Forwarder Name" name="forwarder_name">
                <Input className="w-100" placeholder="Forwarder Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Handover Date" name="handover_date">
                <DatePicker className="w-100" placeholder="Handover Date" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Vehicle Arrival Date"
                name="vehicle_arrival_date"
              >
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Export Bill of Entry Number"
                name="export_bill_of_entry_number"
              >
                <Input
                  className="w-100"
                  placeholder="Export Bill of Entry Number"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={8}>
              <Form.Item label="Bill of Entry Date" name="bill_of_entry_date">
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
});

export default ShipmentOverForm;
