import { Line } from "@ant-design/plots";
import { Card } from "antd";

export default function LineData({ data }) {
  const config = {
    data,
    xField: "hour",
    yField: "defect_qty",
    point: {
      size: 4,
      shape: "circle",
      style: {
        fill: "blue",
      },
    },
    lineStyle: {
      lineWidth: 2,
    },
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        marginBottom: "20px",
      }}
    >
      <Line {...config} />
    </Card>
  );
}
