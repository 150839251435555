import { Col, Form, Row, Select, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import {
  GET_BUYER_ENDPOINT,
  GET_BUYING_OFFICE_ENDPOINT,
  SALES_CONTRACT_LIST,
  STYLE_MERCHANDISING_LIST,
  GET_SUPPLIER_ENDPOINT,
  ALL_SALES_CONTRACT_LIST,
  IMPORT_DETAIL_ALL_SUPPLIERS
} from "../../../apiServices/API_ENDPOINTS";
import { getData } from "../../../apiServices/common";
const ImportAcceptanceDetailFilterFields = ({ initialTableData }) => {
  const { Option } = Select;
  const [b2bLcList, setB2bLcList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    getB2BLcList();
    getSupplierList();
  }, []);

  const getB2BLcList = async () => {
    let get_b2blc_list = `/api/accounting/b2blcs-for-noting`;
    let res = await getData(get_b2blc_list);

    if (res) {
      setB2bLcList(res?.data?.data || []);
    }
  };

  const getSupplierList = async () => {
    let res = await getData(IMPORT_DETAIL_ALL_SUPPLIERS);

    if (res) {
      setSupplierList(res?.data?.data || []);
    }
  };

  return (
    <>  
      <Col span={4}>
        <Form.Item label="B2BLC No" name="import_lc_number">
          <Select
            showSearch
            placeholder="Select B2BLC No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {b2bLcList?.length &&
              b2bLcList.map((sup) => (
                <Option value={sup.id} key={sup.id}>
                  {sup.b2b_lc_number}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="Suppliers" name="supplier_id">
          <Select
            showSearch
            placeholder="Select Supplier"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            size="small"
          >
            {supplierList?.length &&
              supplierList.map((option) => (
                <Option value={option.id} key={option.id}>
                  {option.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="Date Type" name="type">
          <Select
          showSearch
            placeholder="Select Type"
            size="small"
          >
            <option value="acceptance_release_date">Acceptance Release Date</option>
            <option value="maturity_date">Maturity Date</option>
            <option value="payment_release_date">Payment Release Date</option>
          </Select>
        </Form.Item>
      </Col>

      <Col span={4}>
        <Form.Item label="From Date" name="from_date">
          <DatePicker 
            placeholder="From Date" 
            style={{ width: "100%" }} 
            size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="To Date" name="to_date">
          <DatePicker 
            placeholder="To Date" 
            style={{ width: "100%" }} 
            size="small" />
        </Form.Item>
      </Col>
      
    </>
  );
};

export default ImportAcceptanceDetailFilterFields;
