import React, { useRef } from "react";
import Header from "../../Common/Header";
import { Card, Button, Breadcrumb, Affix, Typography, Space } from "antd";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useNavigate } from "react-router-dom";
import AddSalesContactForm from "./AddSalesContactForm";

export default function AddSalesContact(props) {
  // Prosp
  const { width } = props;

  // Antd
  const { Title } = Typography;

  // Others
  const propertyFormRef = useRef(null);
  const navigate = useNavigate();

  const discartForm = () => {
    navigate("/merchandising/sales-contact");
  };

  const addSalesContact = () => {
    propertyFormRef.current.submit();
  };

  const extra = (
    <Space>
      <Button size="small" key={1} danger onClick={() => discartForm()}>
        Discard
      </Button>
      <Button
        size="small"
        key={2}
        type="primary"
        onClick={() => addSalesContact()}
      >
        Add Sales Contact
      </Button>
    </Space>
  );

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={<Title level={5}>Add Sales Contact</Title>}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={extra}
          />
        </Card>
      </Affix>

      <AddSalesContactForm ref={propertyFormRef} />
    </>
  );
}
