import { useState, useEffect, useCallback } from "react";
import {
  Card,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  InputNumber,
  Table,
  Typography,
  Divider,
  Space,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  SALES_CONTRACT_LIST,
  STYLE_WISE_COST_ADD,
  STYLE_WISE_COST_VIEW,
  STYLE_WISE_COST_EDIT,
  REST_SALES_CONTRACT,
} from "../../../../apiServices/API_ENDPOINTS";

import {
  HOLIDAYS
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, putData } from "../../../../apiServices/common";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../../utils/functions";
import HandleNotification from "../../../../common/Notification";


const HolidayForm = (props) => {
  // Props
  const { form, editMode, viewMode } = props;
 // const [code, setCode] = useState("");


  // Router
  const navigate = useNavigate();
  const { id } = useParams();


  const getSelectedHolidayInfo = useCallback(
    async (id) => {
      const query = `${HOLIDAYS}/${id}/show`;
      const response = await getData(query);

      if (response && response?.data?.code === 200) {        
        form.setFieldsValue({
          title: response?.data?.data?.title,
          from_date: moment(response?.data?.data?.from_date),
          to_date: moment(response?.data?.data?.to_date),
        });
        
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error in fetching weekend Info",
          null,
        );
      }
    },
    [form],
  );

  // const handleNameChange = (e) => {
  //   const name = e.target.value;
  //   // Replace spaces and special characters with dashes
  //   const codeValue = name.replace(/[^a-zA-Z0-9]/g, "-");
  //   setCode(codeValue);
  //   form.setFieldsValue({ code: codeValue });
  // };

  useEffect(() => {
    // get selected cost info on edit mode
    if (id) {
      getSelectedHolidayInfo(id);
    }
  }, [editMode, id, getSelectedHolidayInfo]);

  const onFinishHoliday = async (values) => {
    // console.log("values", values);  
    // console.log("checkedDays", checkedDays);

    const query = editMode
    ? `${HOLIDAYS}/${id}`
    : `${HOLIDAYS}`;
    const bodyData = {
      title: values?.title,
      from_date: moment(values.from_date).format("YYYY-MM-DD"),
      to_date: moment(values.to_date).format("YYYY-MM-DD"),
      //entry_date: moment(response?.data?.data?.entry_date).format("YYYY-MM-DD"),
    };
    
    const response = editMode ? await putData(query, bodyData) : await postData(query, bodyData);
    
    console.log("response", response);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        `Holiday ${editMode ? "updated" : "added"} successfully!`,
        null,
      );
      form.resetFields();
      navigate("/production-planning/holidays");
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        `Error ${editMode ? "updating" : "adding"} holiday!`,
        null,
      );
    }
  };

  

  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishHoliday}
      >        

        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input placeholder="Title" size="small"  />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="From date"
              name="from_date"
              rules={[
                {
                  required: true,
                  message: "From date is required",
                },
              ]}
            >
              <DatePicker format="YYYY-MM-DD"  style={{ width: "100%" }}/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="To date"
              name="to_date"
              
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Entry date"
              name="entry_date"
              initialValue={moment().format("YYYY-MM-DD")}
            >
              <Input placeholder="Entry Date" disabled size="small"   />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Entry User"
              name="entry_user"
              initialValue={localStorage.getItem("name") || "User"}
            >
              <Input placeholder="Entry Date" disabled size="small"   />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default HolidayForm;
