import { CopyOutlined, DeleteOutlined, DeleteTwoTone, DiffOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select, Space, Table, Typography } from "antd";

export default function Section({
  onAdd,
  options,
  section_index,
  onChangeSupervisorId,
  operationBulletineDetail,
  onChangeDailyLinePlanData,
  onChangeOperationId,
  onCopy,
  onDelete,
}) {
  const columns = [
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (text, record, index) => (
        <Select
          placeholder="Select Operation"
          onChange={(value) => {
            const findOperation = options?.section?.[section_index]?.find(
              (operation) => operation.value === value,
            );

            onChangeDailyLinePlanData(
              section_index,
              record?.id,
              "operation_id",
              findOperation,
            );
          }}
          style={{
            width: "100%",
          }}
          value={record?.operation_id}
        >
          {options?.section?.[section_index]?.map((operation) => (
            <Select.Option key={operation.value} value={operation.value}>
              {operation.label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "M/C",
      dataIndex: ["sam_data_bank", "ob_machine_type_info", "name"],
      key: "ob_machine_type_info_name",
      align: "right",
    },
    {
      title: "Operator ID ",
      dataIndex: "operator_user_id",
      key: "operator_user_id",
      width: 190,
      render: (text, record, index) => (
        <Input
          placeholder="Employee ID ..."
          style={{
            width: "100%",
          }}
          onChange={(e) => {
            onChangeOperationId(
              section_index,
              record?.id,
              "operator",
              e?.target?.value,
            );
          }}
          value={record?.operator?.id}
        />
      ),
    },
    {
      title: "Operator Employee Name",
      dataIndex: ["operator", "operator_user_name"],
      key: "operator_user_name",
      width: 220,
    },
    {
      title: "Hourly Target",
      dataIndex: "hourly_target",
      key: "hourly_target",
      width: 150,
      render: (text, record, index) => (
        <Input
          placeholder="Hourly Target"
          style={{
            width: "100%",
          }}
          onChange={(e) => {
            onChangeDailyLinePlanData(
              section_index,
              record?.id,
              "hourly_target",
              e?.target?.value,
            );
          }}
          value={record?.hourly_target}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: 'center',
      width: 96,
      render: (text, record) => (
        <Space direction="y">
          <Button type="" icon={<DiffOutlined title="Copy"
          />} onClick={() => onCopy(section_index, record?.id)} />
          <Button danger icon={<DeleteOutlined title="Delete" />} onClick={() => onDelete(section_index, record?.id)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <Col span={3}>
        <Typography.Title level={5}>
          {operationBulletineDetail?.section_name}
        </Typography.Title>
      </Col>
      <Col span={12}>
        <Space.Compact
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <Typography.Text style={{ paddingRight: 10, width: "20%" }}>
            Supervisor:{" "}
          </Typography.Text>
          <Input
            style={{ width: "30%" }}
            placeholder="Employee ID"
            onChange={(e) => {
              onChangeSupervisorId(
                section_index,
                null,
                "supervisor",
                e?.target?.value,
              );
            }}
            value={operationBulletineDetail?.supervisor?.id}
          />
          <Input
            style={{ width: "50%" }}
            placeholder="Employee Name"
            disabled
            value={operationBulletineDetail?.supervisor?.supervisor_user_name}
          />
        </Space.Compact>
      </Col>
      <Col span={24} style={{ marginTop: 10, marginBottom: 30 }}>
        <Table
          dataSource={operationBulletineDetail?.details}
          columns={columns}
          bordered
          pagination={false}
        />
        <Button
          className="mt-2 mb-4"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => onAdd(section_index)}
        >
          Add
        </Button>
      </Col>
    </>
  );
}
