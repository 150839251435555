import {
  Card,
  DatePicker,
  InputNumber,
  Divider,
  Space,
  Button,
  PageHeader,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Select, Checkbox, Radio, Table } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import React, { forwardRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  SECURITY_BOLT_SEAL_LIST,
  SECURITY_BOLT_SEAL_ADD,
  SECURITY_BOLT_SEAL_EDIT,
  SECURITY_BOLT_SEAL_BUYERS,
} from "../../../../apiServices/API_ENDPOINTS";
import { postData, getData, getWithData } from "../../../../apiServices/common";
import { alertPop } from "../../../../apiServices/common/helper";
import { useNavigate } from "react-router-dom";
import { commaSeparateNumber } from "../../../../utils/functions";

const SecurityBoltForm = forwardRef((props, ref) => {
  // Props
  const { form, editMode, securityBoltId } = props;

  // States

  const [buyers, setBuyers] = useState([]);

  // Router
  const navigate = useNavigate();

  // Antd
  const buyer_id_watch = Form.useWatch("buyer_id", form);

  const getBuyers = async () => {
    let res = await getData(SECURITY_BOLT_SEAL_BUYERS);
    //console.log("res",res);
    if (res) {
      setBuyers(res?.data?.data || []);
    }
  };

  useEffect(() => {
    getBuyers();
  }, []);

  const onFinishSecurity = async (values) => {

    const bodyData = {
      security_bolt_seal_number: values?.security_bolt_seal_number,
      using_date:
        values.using_date != null
          ? moment(values?.using_date, "YYYY-MM-DD")
          : null,
      vehicle_number: values?.vehicle_number,
      vehicle_size: values?.vehicle_size,
      buyer_id: values?.buyer_id,
      style_number: values?.style_number,
      po_number: values?.po_number,
      order_quantity: values?.order_quantity,
      shipped_quantity: values?.shipped_quantity,
      carton_quantity: values?.carton_quantity,
      examination_report: values?.examination_report,
      challan_no: values?.challan_no,
      volume_in_cbm: values?.volume_in_cbm,
      driver_name: values?.driver_name,
      driver_mobile_number: values?.driver_mobile_number,
      driver_license_or_nid_number: values?.driver_license_or_nid_number,
      vehicle_arrival_date: values.vehicle_arrival_date
        ? moment(values?.vehicle_arrival_date).format("YYYY-MM-DD")
        : null,
      vehicle_arrival_time: values?.vehicle_arrival_time,
      dispatch_date: values.dispatch_date
        ? moment(values?.dispatch_date).format("YYYY-MM-DD")
        : null,
      dispatch_time: values?.dispatch_time,
      delivery_place: values?.delivery_place,
    };

    if (editMode) {

      const response = await postData(
        `${SECURITY_BOLT_SEAL_LIST}/${securityBoltId}/edit`,
        bodyData,
      );

      if (response) {
        alertPop(
          "success",
          "Security Bolt Seal Log Updated Successfully",
        );
        //navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Security Bolt Seal Log Update Failed!");
      }
    } else {
      const response = await postData(SECURITY_BOLT_SEAL_ADD, bodyData);

      if (response) {
        alertPop(
          "success",
          "Security Bolt Seal Log Added Successfully",
        );
        //navigate("/import-customs-clearance/noting-assessment-examination");
      } else {
        alertPop("error", "Security Bolt Seal Log Add Failed!");
      }
    }
  };


  return (
    <div className="containt-body pt-3">
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinishSecurity}
      >
        <Card>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Security Bolt seal number"
                name="security_bolt_seal_number"
                rules={[
                  {
                    required: true,
                    message: "Please input Security Bolt seal number!",
                  },
                ]}
              >
                <Input
                  className="w-100"
                  placeholder="Security Bolt seal number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Using Date" name="using_date">
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Vehicle Number" name="vehicle_number">
                <Input className="w-100" placeholder="Vehicle Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Vehicle Size" name="vehicle_size">
                <Input className="w-100" placeholder="Vehicle Size" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Buyer Name" name="buyer_id">
                <Select placeholder="Buyer Name" style={{ width: "100%" }}>
                  {buyers.map((option) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Style Number" name="style_number">
                <Input style={{ width: "100%", color: "#000 !important" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Purchase Order Number" name="po_number">
                <Input className="w-100" placeholder="Purchase Order Number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Order Quantity" name="order_quantity">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Shipped Quantity" name="shipped_quantity">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Carton Quantity" name="carton_quantity">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => commaSeparateNumber(value)}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Empty Vehicle examination report"
                name="examination_report"
              >
                <Select
                  placeholder="Empty Vehicle examination report"
                  style={{ width: "100%" }}
                >
                  <option value="Acceptable">Acceptable</option>
                  <option value="Not Acceptable">Not Acceptable</option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Challan Number" name="challan_no">
                <Input className="w-100" placeholder="Challan Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Volume in CBM" name="volume_in_cbm">
                <Input className="w-100" placeholder="Volume in CBM" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Driver Name" name="driver_name">
                <Input className="w-100" placeholder="Driver Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Driver Cell Phone Number"
                name="driver_mobile_number"
              >
                <Input
                  className="w-100"
                  placeholder="Driver Cell Phone Number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="NID / Driving License Number"
                name="driver_license_or_nid_number"
              >
                <Input
                  className="w-100"
                  placeholder="NID / Driving License Number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Vehicle Arrival Date"
                name="vehicle_arrival_date"
              >
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Veichile Arrival Time"
                name="vehicle_arrival_time"
              >
                <Input className="w-100" placeholder="Veichile Arrival Time" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={8}>
              <Form.Item label="Dispatch Date" name="dispatch_date">
                <DatePicker
                  style={{ width: "100%", color: "#000 !important" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Dispatch Time" name="dispatch_time">
                <Input className="w-100" placeholder="Dispatch Time" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Place of Delivery" name="delivery_place">
                <Select
                  placeholder="Place of Delivery"
                  style={{ width: "100%" }}
                >
                  <option value="CTG">CTG</option>
                  <option value="AIRPORT">AIRPORT</option>
                  <option value="BPL">BPL</option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
});

export default SecurityBoltForm;
