import React, { useRef } from "react";
import Header from "../../Common/Header";
import { Card, Button, Breadcrumb, Affix } from "antd";
import { isMobileView } from "../../Common/AppHelper";
import { Link, useNavigate } from "react-router-dom";
import AddStyleForm from "./AddStyleForm";

export default function AddStyle(props) {
  const { width } = props;
  const propertyFormRef = useRef(null);

  const navigate = useNavigate();
  const discartForm = () => {
    // propertyFormRef.current.discart();
    navigate("/merchandising/style");
  };

  const addStyle = () => {
    propertyFormRef.current.submit();
  };

  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title="Add Style"
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={1}
                  danger
                  onClick={() => discartForm()}
                >
                  Discard
                </Button>
              </Affix>,
              <Affix offsetTop={20}>
                <Button
                  size="small"
                  key={2}
                  type="primary"
                  onClick={() => addStyle()}
                >
                  Add Style
                </Button>
              </Affix>,
            ]}
          ></Header>
        </Card>
      </Affix>
      <AddStyleForm ref={propertyFormRef}></AddStyleForm>
    </>
  );
}
