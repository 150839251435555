import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../../Common/Header";
import { Card, Button, Affix, Form } from "antd";
import { isMobileView } from "../../../Common/AppHelper";
import { useNavigate, useParams } from "react-router-dom";
import { postData, getData } from "../../../../apiServices/common";
import AddDetailsForm from "./AddDetailsForm";
import { debounce } from "lodash";

export default function EditExportSalesContract(props) {
  // Props
  const { width, view } = props;

  // Router
  let { importId } = useParams();
  const propertyFormRef = useRef(null);
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [permitInfo, setPermitInfo] = useState();

  // Antd
  const [form] = Form.useForm();
  
  const addProduct = () => {
    form.submit();
  };

  const edit_url = `/api/accounting/import-detail/view/${importId}`;
  const getPIInfo = async () => {
    let res = await getData(edit_url);
    if (res) {
      let masterData = res?.data?.data[0];
      setPermitInfo(masterData);
    }
  };
  const debouncedAddIou = useCallback(debounce(() => {
    addProduct();
}, 1000), []); 

  useEffect(() => {
    getPIInfo();
  }, []);


  return (
    <>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={`${view ? "View" : "Edit"} Import Shipment Status`}
            subTitle=""
            addLink="/"
            addText="Add New"
            actions={[
              // <Button size="small" key={1} danger onClick={() => discartForm()}>
              //   Discard
              // </Button>,
              <Button
                size="small"
                key={2}
                type="primary"
                              loading={loading}
                              onClick={debouncedAddIou}
                hidden={view}
              >
                Update
              </Button>,
            ]}
          ></Header>
        </Card>
      </Affix>
      
      <AddDetailsForm
        ref={propertyFormRef}
        permitInfo={permitInfo}
        form={form}
        view={view}
        setLoading={setLoading}
      />
    </>
  );
}
