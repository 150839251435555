import React, { useState } from "react";
import { Col, DatePicker, Form, Input, Row, Switch } from "antd";
import moment from "moment";

const BuyingOfficeForm = (props) => {
  // Props
  const {
    dateFormat,
    businessRelationDuration,
    selectedBuyingOffice,
    setBusinessRelationDuration,
  } = props;

  const onDateChange = (date, dateString) => {
    const yearDifference = moment().diff(moment(dateString), "days");
    const businessRelation = Math.round(yearDifference / 365);
    setBusinessRelationDuration(businessRelation);
  };

  return (
    <>
      <Row gutter={6}>
        <Col span={4}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Buying Office name!",
              },
            ]}
          >
            <Input placeholder={"Ex: Buying Office Name"} size="small" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Short Name"
            name="short_name"
            rules={[
              {
                required: true,
                message: "Please input short name!",
              },
            ]}
          >
            <Input placeholder={"Ex: Buying Office Short Name"} size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: "Please input address",
              },
            ]}
          >
            <Input placeholder="Address" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Contact Person"
            name="contract_person"
            rules={[
              {
                required: true,
                message: "Please input contract person",
              },
            ]}
          >
            <Input placeholder="Contact person" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Contact Number"
            name="contract_number"
            rules={[
              {
                required: true,
                message: "Please input contract number",
              },
            ]}
          >
            <Input placeholder="Contact number" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input email",
              },
            ]}
          >
            <Input placeholder="Email" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Website" name="website">
            <Input placeholder="Website" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="business_relation_since"
            label="Business Relation Since"
            rules={[
              {
                required: true,
                message: "Please input business relation since",
              },
            ]}
          >
            <DatePicker
              onChange={onDateChange}
              format={dateFormat}
              disabledDate={(current) =>
                current.isAfter(moment().subtract(1, "day"))
              }
              style={{
                width: "100%",
              }}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Business Relation In Year">
            <Input
              disabled={true}
              value={
                selectedBuyingOffice
                  ? selectedBuyingOffice?.business_relation_year
                  : businessRelationDuration
              }
              onChange={onDateChange}
              size="small"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="status"
            label="Active Status"
            className="collection-create-form_last-form-item"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" size="small" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="liaison_status"
            label="Is Liaison Office"
            className="collection-create-form_last-form-item"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" size="small" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BuyingOfficeForm;
